<template>
  <div id="tips">
    <div class="titles">
      <h3 class="title-first merriweather-reg-26">{{ $t("Tips.title") }}</h3>
      <h3 class="title-second merriweather-reg-40">{{ $t("Tips.subtitle") }}</h3>
    </div>
    <div class="list-tips">
      <div class="row row-tips">
        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1">1</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
            <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.first") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.first") }}</p>
          </div>
        </div>

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 ">2</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
            <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.second") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.second") }}</p>
          </div>
        </div>

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num3">3</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
           <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.third") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.third") }}</p>
          </div>
        </div>

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num4">4</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
           <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.fourth") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.fourth") }}</p>
          </div>
        </div>

      </div>

      <div class="row row-tips2">

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num5">5</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
           <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.fifth") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.fifth") }}.</p>
          </div>
        </div>

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num6">6</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
           <h3 class="raleway-bold-h3 mt-2 mb-4">COMPENSER L’EMPREINTE<br>CARBONE DU SÉJOUR ​</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.sixth") }}</p>
          </div>
        </div>

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num7">7</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
           <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.seventh") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.seventh") }}</p>
          </div>
        </div>

        <div class="col-3 tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 ">8</h1>
          </div>
          <div class="tip-content" data-aos="fade-up" data-aos-duration="1000">
           <h3 class="raleway-bold-h3 mt-2 mb-4">{{ $t("Tips.advice.eighth") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.eighth") }}</p>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import AOS from 'aos';

  export default {
    name: 'tips',
    data() {
      return {
        cssProps: {
          backgroundImage: `url(${require('../images/icotype.svg')})`
        }
      }
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/tips'>

</style>

