<template>
  <div id="map-test" class="mt-5">
    <mapbox
        access-token="pk.eyJ1IjoiaG9ydGVuc2VncmVlbiIsImEiOiJja3N1Nm9iNGEwNjUyMm9xY2RlMDlwYWsxIn0.AMGi9L_80RYWk4sB5JYU5w"
        :map-options="{
        style: 'mapbox://styles/mapbox/streets-v10',
        zoom: 5,
        center: [this.markers[0][0], this.markers[0][1]],
      }"
        @map-init="displayMarkers"
    />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
export default{
  name: 'yoga-map',
  props: ['markers'],
  components: { Mapbox },
  data(){
    return{

    }
  },
  methods: {
    displayOneMarker(map, mk) {
      const marker = new mapboxgl.Marker()
          .setLngLat(mk)
          .addTo(map);
    },
    displayMarkers(map) {
      this.fitMapToMarkers(map, this.markers);
      this.markers.forEach((marker, index) => {
        let el = document.createElement('div');
        el.id = marker[2];
        el.addEventListener('click', (e) => {
          this.$emit('markerSelected', el.id);
          document.querySelectorAll('.marker').forEach(mk => {
            if (mk.id != el.id) {
              mk.style.display === 'none' ? mk.style.display = 'block' : mk.style.display = 'none';
            }
            else
            {
              console.log('selected marker');
              console.log(marker);
              map.flyTo({
                center: [marker[0], marker[1]],
                zoom: 5
              })
            }
          })
        })
        el.className = 'marker';
        new mapboxgl.Marker(el)
            .setLngLat([marker[0], marker[1]])
            .addTo(map);
      })
      map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
          })
      );
    },
    fitMapToMarkers(map, markers){
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach(marker => bounds.extend([ marker[0], marker[1] ]));
      map.fitBounds(bounds, { padding: 70, maxZoom: 10, duration: 0 });
    },
  },
}
</script>
<style lang="scss">
#map-test{
  display: flex;
  justify-content: flex-end;
  //margin-bottom: 3rem;
  #map {
    margin-right: 1rem;
    width: 910px;
    height: 480px;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }
  button.close-btn-modal{
    background-image: url('../images/Croix fermer.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 25px;
    height: 25px;
    border: 0;
    background-color: transparent;
  }
}
#map-find{
  display: flex;
  justify-content: flex-end;
  #map {
    margin-right: 1rem;
    width: 668px;
    height: 280px;
  }

  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 57vh;
    background-color: #FFF;
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 721px;
    height: fit-content;
    margin: 20px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-content-form{
    padding: 3rem 7rem;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }
  button.close-btn-modal{
    background-image: url('../images/Croix fermer.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 25px;
    height: 25px;
    border: 0;
    background-color: transparent;
  }
}


.mapboxgl-marker {
  background-image: url('../images/1 Pin.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.marker {
  background-image: url('../images/1 Pin.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#card-map{
  display: inline-flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-left: 46px;
  border: 1px solid black;
  padding: 1rem;

  .cross{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    img {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-map{
    display: flex;

    .sticker-hub{
      position: absolute;
      background-color: #fff;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      align-items: center;

      img{
        width: 19px;
        height: 28px;
      }
    }

    .sticker-info{
      flex-direction: column;
    }
    .hub-img{
      padding-right: 2rem;
    }

    .hub-header{
      margin-top: 1rem;
      justify-content: space-between;
    }

    .standing{
      img{
        width: 15px;
      }
    }

    .location-hub{
      margin-top: 1rem;
      img{
        width: 10px;
        height: 12px;
      }
    }

    .reviews-btn{
      display: flex;
      justify-content: space-between;
      .xs-btn-primary{
        width: 135px;
        height: 49px;
      }
    }

    .hub-reviews{
      img{
        width: 23px;
        height: 27px;
      }
      .review-info{
        flex-direction: column;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #card-map{
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: .5rem;

    .cross {
      padding-right: .5rem;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .card-map {
      flex-direction: column;

      .card-hub-info {
        padding: 0 1rem;
      }
    }
  }

  .modal-container {
    width: 82vw !important;
  }


}

@media only screen and (min-device-width: 481px) and (max-device-width: 789px) {
  .modal-container {
    width: 100vw !important;
  }

  #map {
    width: 100vw !important;
  }
}
</style>
