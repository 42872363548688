// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito:400,700|Work+Sans:400,700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,300&family=Raleway:wght@400;500;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);"]);
// Module
exports.push([module.id, "@font-face{font-family:\"Abuget\";src:font-url(\"/fonts/Abuget.ttf\")}#messages-list-user[data-v-5cfb0736]{display:flex;flex-direction:column;align-items:center}", "",{"version":3,"sources":["/tmp/build_3bb99faf/app/assets/stylesheets/config/_fonts.scss","/tmp/build_3bb99faf/app/javascript/pages/inbox/MessagesListUser.vue"],"names":[],"mappings":"AAsBA,WACG,oBAAA,CACA,iCAAA,CCKH,qCACE,YAAA,CACA,qBAAA,CACA,kBAAA","file":"MessagesListUser.vue","sourcesContent":["// Import Google fonts\n@import url('https://fonts.googleapis.com/css?family=Nunito:400,700|Work+Sans:400,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,300&family=Raleway:wght@400;500;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');\n\n// Define fonts for body and headers\n$body-font: \"Work Sans\", \"Helvetica\", \"sans-serif\";\n$headers-font: \"Nunito\", \"Helvetica\", \"sans-serif\";\n$Merriweather: \"Merriweather\";\n$Raleway: 'Raleway';\n$Roboto: 'Roboto', 'sans-serif';\n\n// To use a font file (.woff) uncomment following lines\n// @font-face {\n//   font-family: \"Font Name\";\n//   src: font-url('FontFile.eot');\n//   src: font-url('FontFile.eot?#iefix') format('embedded-opentype'),\n//        font-url('FontFile.woff') format('woff'),\n//        font-url('FontFile.ttf') format('truetype')\n// }\n// $my-font: \"Font Name\";\n\n@font-face {\n   font-family: \"Abuget\";\n   src: font-url('/fonts/Abuget.ttf')\n}\n\n$Abuget: \"Abuget\";","@import \"app/assets/stylesheets/config/index.scss\";\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n#messages-list-user{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"]}]);
// Exports
module.exports = exports;
