<template>
  <div id="labelHome">
    <div class="container-label">
      <div class="row row-label mb-5 pb-5" data-aos="fade-up" data-aos-duration="1000">
        <div class="col-6 label-host">
          <img src="../images/Label.svg" alt="Label">
        </div>
        <div class="col-6">
          <div class="label-header">
            <h3 class="merriweather-reg-26"> {{ $t("Label.title") }} </h3>
            <p class="raleway-reg-p16">{{ $t("Label.content") }}</p>
          </div>
          <a class="hortense-btn-primary btn-label raleway-bold-p mt-5" :href="'/label-ecoresponsable-green-host/'">{{ $t("Label.viewbtn") }}</a>
        </div>
      </div>

      <div class="row" data-aos="fade-up" data-aos-duration="1000">
        <div class="col-6 col-green">
          <div class="green-header">
            <h3 class="merriweather-reg-26"> {{ $t("Green.title") }} </h3>
            <p class="raleway-reg-p16">{{ $t("Green.content") }}</p>
          </div>
          <a class="hortense-btn-primary btn-label raleway-bold-p mt-5" :href="'/green-note-engagement-environnemental/'">{{ $t("Label.viewbtn") }}</a>
        </div>
        <div class="col-6 green-full">
          <img src="../images/greenFull.svg" alt="green note">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import AOS from 'aos';
  export default {
    name: 'labelHome',
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/label'>

</style>
