<template>
  <div id="footer">
    <div class="footer-container">
    <Modalloginpartner v-if="showModalloginpartner" @close="setUser"></Modalloginpartner>
    <Subscribe v-if="showModalSubscribe" @closeSubscribe="closeSubscribe" @close="showModalSubscribe = false"></Subscribe>
      <!--<div v-if="WindowWidth" class="footer-rect">
        <ul class="footer-list">
          <li class="list-item-footer">
            <a :href="'/qui-est-hortense-voyage-ecoresponsable/'">{{ $t("Hortense.whois") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/offres-exclusives'">{{ $t("Hortense.exclusive") }}</a>
          </li>
          <li class="list-item-footer">
            <a href="https://www.blog.hortense.green/">{{ $t("newspaper") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/programme-fidélité-hortensia/'">{{ $t("Hortense.loyalty") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/faq-hortense/'">{{ $t("Hortense.faq") }}</a>
          </li>
          <li class="list-item-footer">
            <a href="/hortense-for-business">{{ $t("Hortense.business") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/pages/ethic/'">{{ $t("Hortense.ethic") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/carte-cadeau'">{{ $t("Hortense.gift_cards") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/voyage-sur-mesure'">{{ $t("Hortense.made_trip") }}</a>
          </li>
        </ul>
      </div>
       <div v-else class="footer-rect">-->
       <div v-else class="footer-rect">
        <ul class="footer-list">
          <li class="list-item-footer minWidth150">
            <a :href="'/qui-est-hortense-voyage-ecoresponsable/'">{{ $t("Hortense.whois") }}</a>
          </li>
          <li class="list-item-footer minWidth150">
            <a href="https://www.blog.hortense.green/">{{ $t("newspaper") }}</a>
          </li>
          <li class="list-item-footer minWidth200">
            <a :href="'/programme-fidélité-hortensia/'">{{ $t("Hortense.loyalty") }}</a>
          </li>
          <li class="list-item-footer minWidth50">
            <a :href="'/faq-hortense/'">{{ $t("Hortense.faq") }}</a>
          </li>
          <li class="list-item-footer minWidth200">
            <a href="/hortense-for-business">{{ $t("Hortense.business") }}</a>
          </li>
          <li class="list-item-footer minWidth200">
            <a :href="'/pages/ethic/'">{{ $t("Hortense.ethic") }}</a>
          </li>
        </ul>
        <ul class="footer-list">
          <li class="list-item-footer mr-3">
            <a :href="'/workation'">Workation</a>
          </li>
          <li class="list-item-footer mr-3">
<!--            <a :href="'/offres-exclusives'">{{ $t("Hortense.exclusive") }}</a>-->
          </li>
          <li class="list-item-footer mr-3">
            <a :href="'/carte-cadeau'">{{ $t("Hortense.gift_cards") }}</a>
          </li>
          <li class="list-item-footer mr-3">
            <a :href="'/hortense-for-wedding'">{{ $t("Hortense.for_wedding") }}</a>
          </li>
          <li class="list-item-footer mr-3">
            <a :href="'/voyage-sur-mesure'">{{ $t("Hortense.made_trip") }}</a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-3 col-footer">
          <h3 class="grid-title mb-5">{{ $t("contact") }}</h3>
           <h3> {{ $t("message") }} </h3>
          <a :href="'/pages/contact/'"> {{ $t("write") }} </a>
        </div>
        <div class="col-5 col-footer">
          <h3 class="grid-title mb-5">{{ $t("join") }}</h3>
          <p class="grid-content mt-3">{{ $t("Hortense.privacy") }}</p>
        </div>
        <div class="col-4 col-footer">
          <h3 class="grid-title join-title mb-5">Suivez-nous</h3>
          <ul class="grid-list mt-3">
            <li class="grid-list-item pinterest">
              <a target="_blank" href="https://www.pinterest.fr/Hortense_green/">
              <i class="fa-brands fa-pinterest-p fa-2xl"></i>
              </a>
            </li>
            <li class="grid-list-item linkedin">
              <a target="_blank" href="https://www.linkedin.com/company/hortense-green/">
                <i class="fa-brands fa-linkedin fa-2xl"></i>
              </a>
            </li>
            <li class="grid-list-item instagram">
              <a target="_blank" href="https://www.instagram.com/hortense.green_/">
                <i class="fa-brands fa-instagram-square fa-2xl"></i>
              </a>
            </li>
            <li class="grid-list-item facebook">
              <a target="_blank" href="https://www.facebook.com/maketravelgreen/">
                <i class="fa-brands fa-facebook-square fa-2xl"></i>
              </a>
            </li>
            <li class="grid-list-item youtube">
              <a target="_blank" href="https://www.youtube.com/channel/UCRbKxuYZQowvCngbGeCVvMg">
                <i class="fa-brands fa-youtube fa-2xl"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-3 col-footer">

        </div>
        <div class="col-5 col-footer">
          <button @click="showModalSubscribe = true">{{ $t("suscribe") }}</button>
        </div>
        <div class="col-4 col-footer">
          <button  @click="openHostUrl">{{ $t("host") }}</button>
        </div>
      </div>
      <div class="link-footer">
        <ul class="list-link">
          <li class="link-item link-pages">
            <a :href="'/conditions-generales-ventes/'">CGV</a>
          </li>
          <li class="link-item link-pages">
            <a :href="'/conditions-generales-utilisation/'">CGU</a>
          </li>
          <li class="link-item link-pages">
            <a :href="'/mentions-legales/'">{{ $t("legals") }}</a>
          </li>
          <li class="link-item link-pages">
            <a :href="'/politique-confidentialité/'">{{ $t("policy") }}</a>
          </li>
          <li class="link-item link-pages">
            <a>{{ $t("payment") }}</a>
          </li>
          <li class="link-item picto">
            <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/3DSecure.png" alt="3DSecure">
          </li>
          <li class="link-item picto">
            <img src="../../images/Visa.svg" alt="Visa">
          </li>
          <li class="link-item picto">
            <img src="../../images/Mastercard-1.svg" alt="MasterCard">
          </li>
          <li class="link-item amex">
            <img src="../../images/American express.png" alt="AmericaExpress">
          </li>
          <li class="link-item paypal">
            <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/PayPal+.png" alt="Paypal">
          </li>
        </ul>
      </div>


    </div>
    <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
  </div>
</template>

<script>
  import Modalloginpartner from 'components/modals/Modalloginpartner.vue';
  import Subscribe from 'components/modals/Subscribe.vue';
  import Flash from 'alert/Flash.vue';

  export default {
    name: "v-footer",
    components: { Modalloginpartner, Subscribe, Flash },
    data() {
      return {
        isActiveHortense: false,
        isActiveCurrencies: false,
        selectedCurrency: "FR/EUR",
        currencies: [{"currency": "FR/EUR", "lang": "fr"}, {"currency": "UK/GBP", "lang": "en"}, {'currency': "US/USD", "lang": "en"}],
        locale: 'fr',
        origin: window.location.origin,
        showModalloginpartner: false,
        showModalBecome: false,
        showModalSubscribe: false,
        userInfo: {
          email: '',
          password: ''
        },
        text: '',
        status: '',
      }
    },
    watch: {
      locale(val) {
        this._i18n.locale = val
      }
    },
    computed: {
      WindowWidth(){
        if (window.innerWidth > 1488)
          return true;
        else
          return false;
      }
    },
    methods: {
      async UserBasicInfo() {
        try {
          const response = await fetcher.post('/users/sign_in', {
                                                            user: {
                                                              email: this.userInfo.email,
                                                              password: this.userInfo.password,
                                                            }
                                                          })
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'login',
            method: 'email_password',
          });
          this.$emit('close', response.data);
          location.reload();
        } catch(e) {
          console.log(e);
        }
      },
      openHostUrl(){
        window.open("/devenir-hote", "_blank");
      },
      closeSubscribe() {
        this.showModalSubscribe = false;
      },
      setUser(user){
        if (user) {
          this.user = user;
          this.showModalloginpartner = false;
          this.$emit('close', user);
        } else {
          this.showModalloginpartner = false;
        }
      },
      showHortense() {
        this.isActiveHortense = !this.isActiveHortense;
      },
      showCurrencies(e) {
        const el = e.currentTarget.lastElementChild;
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
        this.isActiveCurrencies = !this.isActiveCurrencies;
      },
      currenciesLeft() {
        return this.currencies.filter(x => {return x.currency !== this.selectedCurrency })
      },
      selectCurrency(e) {
        this._i18n.locale = e.currentTarget.value;
        this.selectedCurrency = e.currentTarget.innerText;
        this.isActiveCurrencies = false;
        const el = document.querySelector('.arrow');
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/footer'>
</style>
<style lang="scss" scoped>
.minWidth150{
  min-width: 150px;
}
.minWidth200{
  min-width: 200px;
}
.minWidth50{
  min-width: 50px;
}
</style>
<style>
  .container-index {
    margin-top: 3vh !important;
  }
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .container-index {
    margin-top: 22vh !important;
  }
}
</style>