<template>
  <div id="lodging-show">
    <Modalsignup v-if="showSignupModal" @close="showSignupModal = false" />
    <template v-if="isChoosing">
      <div v-if="!isPhone" class="nav-lodging" style="height: 0px !important;">
        <div class="lodging-header">
          <div class="header">
            <h1 class="lodging-show-raleway-reg-p26-b text-center">{{ this.lodging.name }}</h1>
            <div class="standing justify-content-center">
              <img
                src="../../images/Etoiles.svg"
                alt="Etoile remplie"
                v-for="i in Math.max(0, parseInt(this.lodging.standing) || 0)"
                :key="i"
              />
            </div>
          </div>
          <div class="interaction justify-content-center" style="margin-top: 0.5rem;">
            <div class="faves">
              <div>
                <img
                    src="../../images/wishlistOn.svg"
                    alt="Coeur plein"
                    v-if="isIncludeInWishlist()"
                    @click="toggleLike()"
                />
                <img
                    src="../../images/wishlist.svg"
                    alt="Coeur vide"
                    v-else
                    @click="toggleLike()"
                />
              </div>
            </div>

            <Modalshare
                v-if="showModal"
                @close="showModal = false"
                :for="this.lodging"
            ></Modalshare>
            <div class="share d-flex" style="margin-right: 1rem;" @click="showModal = true">
              <img src="../../images/Share.svg" alt="Partager" />
              <p class="raleway-reg-p13 mb-0">Partager</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Banner -->
      <template v-if="!isPhone || !this.lodging.photos_keys">
        <div
          id="banner-lodging"
          :style="getBackgroundStyle()"
        >
          <ModalGallery
            :lodging="this.lodging"
            :photoKeys="this.lodging.photos_keys"
            v-if="modalGallery"
            @close="modalGallery = false"
          ></ModalGallery>
          <div class="btn-galerie mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
            </div>
          </div>
          <!-- <div class="banner-show-container" v-if="this.lodging.lodging_description.catch_sentence">
            <div class="col-9 offset-3 catch-hub-show">
              <p class="merriweather-italic30">
                {{ this.lodging.lodging_description.catch_sentence }}
              </p>
            </div>
          </div> -->
          <div class="banner-show-container" v-else>
            <div class="col-lg-9 offset-lg-3 catch-hub-show">
              <p class="merriweather-italic30">Un havre aussi <br />éco-responsable que luxueux</p>
            </div>
          </div>
          <div class="btn-galerie-phone mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
            </div>
          </div>
        </div>
        <div id="banner-lodging" v-else>
          <div class="btn-galerie mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0">Galerie</p>
            </div>
          </div>
          <div class="banner-show-container" v-if="this.lodging.lodging_description.catch_sentence">
            <div class="col-9 offset-3 catch-hub-show">
              <p class="merriweather-italic30">
                {{ this.lodging.lodging_description.catch_sentence }}
              </p>
            </div>
          </div>
          <div class="banner-show-container" v-else>
            <div class="col-9 offset-3 catch-hub-show">
              <p class="merriweather-italic30">Un havre aussi <br />éco-responsable que luxueux</p>
            </div>
          </div>
          <div class="btn-galerie-phone mb-3">
            <div class="galerie-icon df-jsb-aic">
              <img src="../../images/Galerie icone.svg" alt="galerie" />
              <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isPhone && this.lodging.photos_keys && this.lodging.photos_keys.length > 0">
        <div class="slider-container">
          <a href="javascript:history.go(-1)" class="back-arrow">
            <img src="../../images/fleche gauche.svg" alt="Back Arrow" class="back-arrow-image" />
          </a>

          <div
            class="slider"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
          >
            <img
              :src="getPhotoUrl(currentIndex)"
              alt="slider image"
              class="slider-image"
            />
            <div class="image-counter">{{ currentIndex + 1 }}/{{ totalPhotos }}</div>
          </div>
        </div>
      </template>
      <div v-if="isPhone" class="nav-lodging" style="height: 5% !important;">
        <div class="lodging-header">
          <div class="header">
            <h1 class="lodging-show-raleway-reg-p26-b text-center">{{ this.lodging.name }}</h1>
            <div class="standing justify-content-center">
              <img
                src="../../images/Etoiles.svg"
                alt="Etoile remplie"
                v-for="i in Math.max(0, parseInt(this.lodging.standing) || 0)"
                :key="i"
              />
            </div>
          </div>
          <div class="interaction justify-content-center" style="margin-top: 0.5rem;">
            <div class="faves">
              <div>
                <img
                    src="../../images/wishlistOn.svg"
                    alt="Coeur plein"
                    v-if="isIncludeInWishlist()"
                    @click="toggleLike()"
                />
                <img
                    src="../../images/wishlist.svg"
                    alt="Coeur vide"
                    v-else
                    @click="toggleLike()"
                />
              </div>
            </div>

            <Modalshare
                v-if="showModal"
                @close="showModal = false"
                :for="this.lodging"
            ></Modalshare>
            <div class="share d-flex" style="margin-right: 1rem;" @click="showModal = true">
              <img src="../../images/Share.svg" alt="Partager" />
              <p class="raleway-reg-p13 mb-0">Partager</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Nav lodgin -->
      <div class="nav-lodging">
        <div class="lodging-header">
          <div class="header">
            <p  v-if="this.lodging.lodging_description.catch_sentence" style="color: black;" class="merriweather-italic23 text-center">{{ this.lodging.lodging_description.catch_sentence }}
            </p>
            <!-- TODO standing -->
          </div>
        </div>
        <div class="menu-lodging">
          <ul class="list-menu">
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('lodging-discover')">Description</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('essential')">Les essentiels</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('mobile-main')"
                >programme de fidélité</a
              >
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('greennote-lodging')">La Green Note</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('rooms-classy')">Les chambres</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('experiences-lodging')">Les expériences locales</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a @click="scrollToElement('services-lodging')">Les équipements</a>
            </li>
            <li class="menu-item raleway-reg-p13">
              <a :href="'/reservation-hotel/' + this.lodging.slug + '/avis'">Avis</a>
            </li>
            <li>
              <a v-else
                :href="reservationUrl"
                class="hortense-btn-primary df-jsb-aic"
                v-if="!this.lodging.is_entire"
                >Voir les disponibilités</a
              >
              <a
                @click="scrollToElement('row-resa-entire')"
                class="hortense-btn-primary df-jsb-aic"
                v-else
                >Voir les disponibilités</a
              >
            </li>
          </ul>
        </div>
        <a
          :href="reservationUrl"
          class="hortense-btn-primary phone-btn df-jsb-aic"
          v-if="!this.lodging.is_entire"
          >Voir les disponibilités</a
        >
      </div>
      <div id="lodging-info" class="lodging-info" v-if="!this.lodging.is_entire">
        <h2 class="raleway-reg-p24 mb-2">{{ formatStyle(this.country.name) }}, {{ this.city.name }}</h2>
        <div class="location-hub d-flex mb-3">
          <img src="../../images/Localisation.svg" alt="Localisation" />
          <!-- <p class="raleway-reg-p13 mb-0 pl-1 pr-2">3,4km du centre</p> -->
          <a class="link-hortense main-link" @click="displayMapfind">Voir sur la carte</a>
        </div>
        <p class="raleway-reg-p13">
          {{ this.lodging.address }}, {{ this.lodging.zip_code }},
          {{ this.city.name }}
        </p>
        <p class="raleway-reg-p13">
          Cet établissement propose {{ this.lodging.number_of_room }} {{ this.lodging.number_of_room == 1 ? 'chambre' : 'chambres' }}
        </p>
        <!--  <div class="room-left d-flex">
        <img src="../../images/Alerte.svg" alt="Alerte">
        <p class="raleway-reg-p13 mb-0 ml-2">Chambres restantes : {{this.lodging.number_of_room}}</p>
      </div> -->
        <Maplodgingshow
          v-if="showMapfind"
          :markers="this.markers"
          @markerSelected="selectCard"
          @close="showMapfind = false"
        ></Maplodgingshow>
        <div class="styles">
          <div class="style" v-for="style in styles" :key="style.id">
            <p class="raleway-reg-p15">{{ formatStyle(style.name) }}</p>
          </div>
        </div>
      </div>
      <div class="lodging-info" v-else>
        <p class="raleway-reg-p24 mb-2">{{ formatStyle(this.country.name) }}, {{ this.city.name }}</p>
        <div class="location-hub d-flex mb-3">
          <img src="../../images/Localisation.svg" alt="Localisation" />
          <a class="link-hortense main-link" @click="displayMapfind">Voir sur la carte</a>
        </div>
        <!-- <p class="raleway-reg-p13 mb-0">Logement entier</p> -->
        <p class="raleway-reg-p13">
          {{ this.lodging.address }}, {{ this.lodging.zip_code }},
          {{ this.city.name }}
        </p>
        <p class="raleway-reg-p13">
          Cet établissement propose {{ this.lodging.number_of_room }} {{ this.lodging.number_of_room == 1 ? 'chambre' : 'chambres' }}
        </p>
        <br>
       <div class="d-flex" v-if="this.lodging.is_entire">
        <img src="../../images/accommodation_capacity.png" style="width: 75%; height:74%;" alt="Accomodation" v-if="this.lodging.accommodation_capacity">
        <p class="raleway-reg-p24 mb-0" style="padding-top: 10px; font-size: 18px;" v-if="this.lodging.accommodation_capacity"> {{ this.lodging.accommodation_capacity }}</p>
         <img src="../../images/bedroom.png" alt="Rooms" style="width: 78%; height:78%; padding-left: 20px;" v-if="this.lodging.number_of_room">
         <p class="raleway-reg-p24 mb-0" style="padding-top: 8px; padding-left: 4px; font-size: 18px;" v-if="this.lodging.number_of_room"> {{ this.lodging.number_of_room }}</p>
         <img src="../../images/bath.png" alt="Bathroom" style="width: 70%; height:68%; padding-left: 20px;" v-if="this.lodging.number_of_bathroom" >
         <p class="raleway-reg-p24 mb-0" style="padding-top: 10px; padding-left: 5px; font-size: 18px;" v-if="this.lodging.number_of_bathroom"> {{ this.lodging.number_of_bathroom }}</p>
      </div>
        <Maplodgingshow
          v-if="showMapfind"
          :markers="this.markers"
          @markerSelected="selectCard"
          @close="showMapfind = false"
        ></Maplodgingshow>
        <div class="styles">
          <div class="style" v-for="style in styles" :key="style.id">
            <p class="raleway-reg-p15">{{ formatStyle(style.name) }}</p>
          </div>
        </div>
      </div>
      <div class="container-profil phone">
        <div id="container-profil">
        <div class="row-basicinfo">
          <div class="first d-flex" data-aos="fade-left" data-aos-duration="900">
            <img
              :src="greenNotePicto(this.lodging.score.toFixed())"
              :alt="this.lodging.score.toFixed()"
              v-if="this.lodging.score"
            />
            <div class="content" v-if="this.lodging.score">
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0">{{ this.lodging.score.toFixed() }}/</p>
                <p class="raleway-reg-p15 mb-0 mr-2">10</p>
                <p class="raleway-bold-p16 mb-0">
                  {{ this.lodging.green_note_mention }}
                </p>
              </div>
              <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
                >Comprendre la Green Note</a
              >
            </div>
            <div class="content" v-else>
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0">_/</p>
                <p class="raleway-reg-p15 mb-0 mr-2">10</p>
                <p class="raleway-bold-p16 mb-0"></p>
              </div>
              <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
                >Comprendre la Green Note</a
              >
            </div>
          </div>
          <div class="snd d-flex" data-aos="fade-left" data-aos-duration="900">
            <img src="../../images/Note.svg" alt="" />
            <div class="content">
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0">{{ this.lodging.lodging_description.rating_value || this.average }}/</p>
                <p class="raleway-reg-p15 mb-0 mr-2">5</p>
              </div>
              <p class="raleway-reg-p13 mb-0">Note des voyageurs</p>
              <a
                :href="'/reservation-hotel/' + this.lodging.slug + '/avis'"
                class="link-hortense"
                v-if="(this.ratedLodgings && this.ratedLodgings.length) || this.lodging.lodging_description.review_count"
                >{{ this.lodging.lodging_description.review_count || this.ratedLodgings.length }} avis</a
              >
              <a
                :href="'/reservation-hotel/' + this.lodging.slug + '/avis'"
                class="link-hortense"
                v-else
                >{{ 0 }} avis</a
              >
            </div>
          </div>
          <div class="separation"></div>
          <div class="third d-flex" data-aos="fade-right" data-aos-duration="900">
            <img src="../../images/CO2simple.svg" alt="" />
            <div class="content">
              <div class="note d-flex">
                <p class="raleway-reg-p24 mb-0 mr-2">6,9kg</p>
                <p class="raleway-reg-p13 mb-0 mr-2">de CO²</p>
              </div>
            </div>
          </div>
          <div class="fourth d-flex" data-aos="fade-right" data-aos-duration="900">
            <img src="../../images/Localisation.svg" alt="" />
            <div class="content">
              <p class="raleway-reg-p24 mb-0" v-if="this.distanceBetween">
                {{ this.distanceBetween }} km
              </p>
              <p class="raleway-reg-p13 mb-0">Distance depuis chez <br />vous</p>
            </div>
          </div>
<!--          <div v-if="this.lodging.lodging_description.is_club" class="separation"></div>-->
<!--          <div v-if="this.lodging.lodging_description.is_club" class="fourth d-flex" data-aos="fade-right" data-aos-duration="900">-->
<!--            <img style="height: 70px; width: 70px" src="../../images/club_logo.png" alt="" />-->
<!--            <div class="content">-->
<!--              <p class="raleway-reg-p24 mb-0">-->
<!--                Découvrez ce club-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="col-lg-9 col-md-9" id="row-basicinfo">
        <div id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1">
          <div
            class="accordion-header"
            @click.prevent="isExpand = !isExpand"
            :class="this.isExpand ? 'active' : ''"
          >
            <h2 class="raleway-reg-p24">A la découverte de l’hébergement</h2>
          </div>
          <div v-if="!isPhone" class="accordion-body">
            <p class="raleway-reg-p15">
              Nous vous dévoilons ici les secrets qui font le charme de cet établissement.<br />
              <br />{{ this.lodging.lodging_description.lodging_discover }}
            </p>
          </div>
          <div v-if="isPhone" class="accordion-body" :class="isExpand ? 'active' : ''">
            <p class="raleway-reg-p15">
              <!-- Display either truncated or full text based on showMore -->
              <span v-if="!showMore">
                {{ truncatedText(550, this.lodging.lodging_description.lodging_discover) }}
              </span>
              <span v-else>
                {{ this.lodging.lodging_description.lodging_discover }}
              </span>
            </p>

            <!-- Show More / Show Less toggle -->
            <button v-if="isPhone" @click="toggleText" class="show-more-btn">
              {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
            </button>
          </div>
        </div>
        <div class="row admin-discover">
          <div
            class="col-lg-5 col-xs-6"
            v-if="this.lodging"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            <div
              class="accordion-header"
              @click.prevent="isExpandAdminDiscover = !isExpandAdminDiscover"
              :class="this.isExpandAdminDiscover ? 'active' : ''"
            >
              <h2 class="raleway-reg-p24">À la rencontre de vos hôtes</h2>
            </div>
            <div v-if="!isPhone" class="accordion-body" :class="this.isExpandAdminDiscover ? 'active' : ''">
              <p class="raleway-reg-p15">
                {{ this.lodging.lodging_description.manager_meet }}
              </p>
            </div>
            <div v-if="isPhone" class="accordion-body" :class="this.isExpandAdminDiscover ? 'active' : ''">
              <p class="raleway-reg-p15">
              <!-- Display either truncated or full text based on showMore -->
              <span v-if="!showMore">
                {{ truncatedText(250, this.lodging.lodging_description.manager_meet) }}
              </span>
              <span v-else>
                {{ this.lodging.lodging_description.manager_meet }}
              </span>
            </p>
            <button v-if="isPhone" @click="toggleText" class="show-more-btn">
              {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
            </button>
            </div>
          </div>
          <div class="col-5 portrait" data-aos="fade-up" data-aos-duration="900">
            <img
              v-if="this.lodging.photo_manager"
              :src="this.lodging.photo_manager"
              alt="manager photo"
            />
            <img
              v-else
              src="https://media.gettyimages.com/photos/portrait-of-confident-businesswoman-picture-id900243402?k=6&m=900243402&s=612x612&w=0&h=g3QokXD8374mJOA7Q4OzwriFeNmpQofn0WEwimx_i2E="
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
      <div id="ending-bar"></div>
        <div class="essential" data-aos="fade-up" data-aos-duration="900">
          <h2 class="raleway-reg-p24">Les essentiels</h2>
          <div class="row row-essentials">
            <div
              class="col-lg-4 col-xs-9"
              v-if="service_essentials && service_essentials.length > 0"
            >
              <div
                class="accordion-header"
                @click.prevent="isExpandEssentials = !isExpandEssentials"
                :class="this.isExpandEssentials ? 'active' : ''"
              >
                <h3 class="raleway-bold-p16 mb-5">Équipements & services</h3>
              </div>
              <div class="accordion-body" :class="this.isExpandEssentials ? 'active' : ''">
                <div class="list-services" v-for="service in service_essentials" :key="service.id">
                  <div class="service-item">
                    <img :src="displayPicto(service)" :alt="service.name" />
                    <p class="raleway-reg-p15 mb-0">{{ service.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-xs-8">
              <div
                class="accordion-header"
                @click.prevent="isExpandInfo = !isExpandInfo"
                :class="this.isExpandInfo ? 'active' : ''"
              >
                <h3 class="raleway-bold-p16 mb-5">Informations importantes</h3>
              </div>
              <div class="accordion-body" :class="this.isExpandInfo ? 'active' : ''">
                <div class="list-info">
                  <p class="raleway-reg-p15 mb-4 arrival">
                    Heure d’arrivée :
                    {{ this.lodging.lodging_profile.checkin_hour }}
                  </p>
                  <p class="raleway-reg-p15 mb-4 departure">
                    Heure de départ :
                    {{ this.lodging.lodging_profile.checkout_hour }}
                  </p>
                  <p
                    class="raleway-reg-p15 mb-4 adult-only"
                    v-if="this.lodging.lodging_profile.adult_only"
                  >
                    Réservé aux adultes
                  </p>
                  <p
                    class="raleway-reg-p15 mb-4 pmr"
                    v-if="this.lodging.lodging_profile.pmr_access"
                  >
                    Accessible aux PMR
                  </p>
                  <p class="raleway-reg-p15 mb-4 pmr" v-if="!this.lodging.lodging_profile.smoking">
                    Chambres non-fumeurs
                  </p>
                  <p class="raleway-reg-p15 mb-4 pmr" v-if="this.lodging.lodging_profile.smoking">
                    Chambres fumeurs
                  </p>
                  <p
                    class="raleway-reg-p15 mb-4 pet-accepted"
                    v-if="this.lodging.lodging_profile.animal"
                  >
                    Animaux de compagnie admis
                  </p>
                  <p class="raleway-reg-p15 mb-4 pet-accepted" v-else>
                    Animaux de compagnie non-admis
                  </p>
                  <p class="raleway-reg-p15 mb-0 payement">Moyens de paiement acceptés :</p>
                  <ul class="languages-list">
                    <template v-for="payment in this.lodging.payments">
                      <li class="raleway-reg-p15 mb-0 payement" :key="payment.id">
                        {{ formatEl(payment.name, "payments") }}
                      </li>
                    </template>
                  </ul>
                  <p
                    class="raleway-reg-p15 mb-4 mt-4 apoint-bed"
                    v-if="this.lodging.lodging_profile.extra_bed"
                  >
                    Lit d’appoint disponible
                  </p>
                  <p class="raleway-reg-p15 mt-4 mb-0 languages">Langues parlées :</p>
                  <ul class="languages-list">
                    <template v-for="language in this.lodging.languages">
                      <li class="raleway-reg-p15 mb-0 payement" :key="language.id">
                        {{ formatEl(language.name, "languages") }}
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-xs-9 col-conditions">
              <div class="cancel-conditions">
                <div class="head d-flex">
                  <img src="../../images/info-blue.svg" alt="info" />
                  <h3 class="raleway-bold-p16 ml-4 mb-0">Conditions</h3>
                </div>
                <p class="raleway-reg-p15 content">
                  Les conditions d’annulation et de prépaiement varient en fonction du type de
                  chambre (tarif). Veuillez consulter les conditions de la chambre avant d’effectuer
                  votre choix.
                </p>
              </div>
              <div v-if="!isPhone" class="lodging-made-trip">
                  <img
                    src="../../images/bag.svg"
                    alt="bag"
                  />
                  <div class="content">
                    <h3 class="raleway-bold-p mb-0">Envie d'organiser un voyage sur mesure ?</h3>
                    <p class="raleway-reg-p13 mb-0">Prenez contact avec nous <a class="link-hortense" :href="'/voyage-sur-mesure/'">ici</a></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div id="hortensia-lodging" data-aos="fade-up" data-aos-duration="900">
          <div class="pt-3">
            <div>
              <h2 class="raleway-reg-p24">L'expérience Hortense</h2>
              <div class="col-12">
                <div class="row">
                  <!-- First Column -->
                  <div class="pl-0 col-lg-8 col-md-12 mb-3">
                    <div class="col-12 __border_item">
                      <div class="row align-items-center">
                        <!-- Left section with Text and Button -->
                        <div class="col-12 col-md-7">
                          <div class="d-flex align-items-start check p-3">
                            <img src="../../images/O-Hortense.png" alt="circle" class="img-fluid" style="max-width: 28px; margin-right: 10px;">
                            <div>
                              <h4 class="_sub_title p-1">GREEN EXPÉRIENCE</h4>
                              <p class="raleway-reg-p15">Pour tout séjour de plus de 3 nuits, Hortense vous offre une expérience unique qui vous transportera au cœur de l’atmosphère unique de ce lieu d’exception.</p>
                              <button v-if="this.lodging.lodging_description.green_experience" class="__btn">{{ this.lodging.lodging_description.green_experience }}</button>
                            </div>
                          </div>
                        </div>
                        <!-- Right section with Image -->
                        <div class="col-12 col-md-5 p-0 d-flex justify-content-end mobile-img">
                          <img src="../../images/drink.jpg" alt="drink" class="img-fluid mobile-drink" style="max-width: 100%; height: auto;">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Second Column -->
                  <div class="col-lg-4 __border_item col-md-12 mb-3 mobile-ujd">
                    <div class="py-4 px-3 d-flex h-100 align-items-center mobile-bullett">
                      <div class="d-flex align-items-start check">
                        <img src="../../images/O-Hortense.png" alt="circle" class="img-fluid mobile-bul" style="max-width: 28px; margin-right: 10px;">
                        <div>
                          <h4 class="_sub_title_right p-1">-10% SUR VOTRE 1ER SÉJOUR</h4>
                          <p class="raleway-reg-p15">Cette offre est valable quelle que soit la durée de votre séjour. Un compte client est nécessaire pour en profiter.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!--    section 2nd-->
              <div class="p-3 mobile-main">
                <div class="row">
                  <div class="col-12 __bg">
                    <div class="row p-3">
                      <div class="col-12 col-md-4 __card__data mb-3 pl-0">
                        <div class="d-flex align-items-start check p-3">
                          <div class="form-item mt-1 raleway-reg-p13">
                            <img src="../../images/O-Hortense.png" alt="circle" class="img-fluid mobile-bul" style="max-width: 28px; margin-right: 10px;">
                          </div>
                          <div>
                            <h4 class="raleway-reg-p24-two mt-2 mb-4">LE PROGRAMME DE FIDÉLITÉ HORTENSIA</h4>
                            <p class="raleway-reg-p15 mb-5">
                              Tous les 500 points, vous atteignez un nouveau
                              statut de fidélité qui vous permet d'accéder à de
                              nouveaux priviléges, avantages et cadeaux.
                            </p>
                            <div>
                              <div v-if="user">
                                <button class="__btn___secondary bg-white __btn_text mb-2" disabled>
                                  MES POINTS : {{ user.loyalty_points }}
                                </button>
                              </div>
                              <div v-else>
                                <button class="hortense-btn-primary mb-2" id="show-modal" @click="showSignupModal = true">
                                  JE M’INSCRIS
                                </button>
                              </div>
                            </div>
                            <a href="/programme-fidélité-hortensia/" target="_blank" class="____link link-hortense">En savoir plus sur Hortensia</a>
                          </div>
                        </div>
                      </div>

                      <div v-if="!isPhone" class="col-12 col-md-8 __card__data mb-3">
                        <div class="row">
                          <div class="col-12">
                            <div class="row justify-content-center">
                                <div class="col-6 col-lg-3 px-0 mb-3">
                                  <div :class="['__card_flower', 'card_border', 'px-4', {'bg-white': isCardActive(0)}]">
                                    <div :class="['__leave', {'bg-white': isCardActive(0)}]">
                                      <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(0)}]" src="../../images/badge-guest.png" alt="leave">
                                    </div>
                                    <div class="text-center my-5">
                                      <h3 class="title-textt">Green Trotter</h3>
                                      <p class="_raleway-reg-p13 pt-4">500 points = 10 nuits réservées</p>
                                      <div class="d-flex flex-column w-100" v-if="this.lodging.green_trotters && this.lodging.green_trotters.length > 0">
                                        <template v-for="gift in this.lodging.green_trotters[0].gifts">
                                          <button class="__btn py-1 mb-3">{{ gift.name }}</button>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-6 col-lg-3 px-0 mb-3">
                                  <div :class="['__card_flower', 'card_border', 'px-4', {'bg-white': isCardActive(1)}]">
                                    <div :class="['__leave', {'bg-white': isCardActive(1)}]">
                                      <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(1)}]" src="../../images/badge-member.png" alt="leave">
                                    </div>
                                    <div class="text-center my-5">
                                      <h3 class="title-textt">GREEN TROTTER <br> PLUS</h3>
                                      <p class="_raleway-reg-p13">1000 points = 20 nuits réservées</p>
                                      <div class="d-flex flex-column w-100" v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                        <template v-for="gift in this.lodging.green_trotters[1].gifts">
                                          <button class="__btn py-1 mb-3">{{ gift.name }}</button>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-6 col-lg-3 px-0 mb-3">
                                  <div :class="['__card_flower', 'card_border', 'px-4', {'bg-white': isCardActive(2)}]">
                                    <div :class="['__leave', {'bg-white': isCardActive(2)}]">
                                      <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(2)}]" src="../../images/badge-nomad@2x.png" alt="leave">
                                    </div>
                                    <div class="text-center my-5">
                                      <h3 class="title-textt">GREEN TROTTER PREMIUM</h3>
                                      <p class="_raleway-reg-p13">1500 points = 30 nuits réservées</p>
                                      <div class=" d-flex flex-column w-100" v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                        <template v-for="gift in this.lodging.green_trotters[2].gifts">
                                          <button class="__btn py-1 mb-3">{{ gift.name }}</button>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-6 col-lg-3 px-0 mb-3">
                                  <div :class="['__card_flower', 'px-4', {'bg-white': isCardActive(3)}]">
                                    <div :class="['__leave', {'bg-white': isCardActive(3)}]">
                                      <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(3)}]" src="../../images/badge-trotter.png" alt="leave">
                                    </div>
                                    <div class="text-center my-5">
                                      <h3 class="title-textt">GREEN TROTTER INFINITE</h3>
                                      <p class="_raleway-reg-p13">2500 points = 50 nuits réservées</p>
                                      <div class="d-flex flex-column w-100" v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                        <template v-for="gift in this.lodging.green_trotters[3].gifts">
                                          <button class="__btn py-1 mb-3">{{ gift.name }}</button>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              <p class="text-center w-100 _raleway-reg-text">
                                Les services exclusifs sont cumulatifs. En d’autres termes, vous avez droit à 4 services exclusifs au cours de votre séjour, quel que soit le nombre de nuits, à partir du moment où vous avez atteint le statut Green Trotter Infinite. Veuillez noter que, sauf indication contraire, les services sont inclus pour tous les clients participant au séjour.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div v-if="isPhone" class="col-12 col-md-8 __card__data mb-3">
                        <div class="row">
                          <div class="col-12">
                            <div class="row justify-content-center">
                              <div class="card-scroll-container">
                              <div class="card-item">
                                <div :class="['__card_flower', 'card_border', 'px-4', {'bg-white': isCardActive(0)}]">
                                  <div :class="['__leave', {'bg-white': isCardActive(0)}]">
                                    <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(0)}]" src="../../images/badge-guest.png" alt="leave">
                                  </div>
                                  <div class="text-center my-5">
                                    <h2 class="title-textt">Green Trotter</h2>
                                    <p class="_raleway-reg-p13 mt-4">500 points = 10 nuits <br> réservées</p>
                                    <div class="d-flex flex-column w-100"  v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                      <template v-for="gift in this.lodging.green_trotters[0].gifts">
                                        <button style="white-space: normal;word-wrap: break-word;text-align: center;" class="__btn py-1 mb-3">{{ gift.name }}</button>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card-item">
                                <div :class="['__card_flower', 'card_border', 'px-4', {'bg-white': isCardActive(1)}]">
                                  <div :class="['__leave', {'bg-white': isCardActive(1)}]">
                                    <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(1)}]" src="../../images/badge-member.png" alt="leave">
                                  </div>
                                  <div class="text-center my-5">
                                    <h2 class="title-textt">GREEN TROTTER <br> PLUS</h2>
                                    <p class="_raleway-reg-p13">1000 points = 20 nuits <br> réservées</p>
                                    <div class="d-flex flex-column w-100"  v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                      <template v-for="gift in this.lodging.green_trotters[1].gifts">
                                        <button style="white-space: normal;word-wrap: break-word;text-align: center;" class="__btn py-1 mb-3">{{ gift.name }}</button>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card-item">
                                <div :class="['__card_flower', 'card_border', 'px-4', {'bg-white': isCardActive(2)}]">
                                  <div :class="['__leave', {'bg-white': isCardActive(2)}]">
                                    <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(2)}]" src="../../images/badge-nomad@2x.png" alt="leave">
                                  </div>
                                  <div class="text-center my-5">
                                    <h2 class="title-textt">GREEN TROTTER <br> PREMIUM</h2>
                                    <p class="_raleway-reg-p13">1500 points = 30 nuits <br> réservées</p>
                                    <div class="d-flex flex-column w-100"  v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                      <template v-for="gift in this.lodging.green_trotters[2].gifts">
                                        <button style="white-space: normal;word-wrap: break-word;text-align: center;" class="__btn py-1 mb-3">{{ gift.name }}</button>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card-item">
                                <div :class="['__card_flower', 'px-4', {'bg-white': isCardActive(3)}]">
                                  <div :class="['__leave', {'bg-white': isCardActive(3)}]">
                                    <img :class="['__leave_image', 'img-fluid', {'bg-white': isCardActive(3)}]" src="../../images/badge-trotter.png" alt="leave">
                                  </div>
                                  <div class="text-center my-5">
                                    <h2 class="title-textt">GREEN TROTTER <br> INFINITE</h2>
                                    <p class="_raleway-reg-p13">2500 points = 50 nuits<br> réservées</p>
                                    <div class="d-flex flex-column w-100"  v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
                                      <template v-for="gift in this.lodging.green_trotters[3].gifts">
                                        <button style="white-space: normal;word-wrap: break-word;text-align: center;" class="__btn py-1 mb-3">{{ gift.name }}</button>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                </div>
                              <p class="text-center w-100 _raleway-reg-text" style="white-space: normal;word-wrap: break-word;text-align: center;">
                                Les services exclusifs sont cumulatifs. <br> En d’autres termes, vous avez droit à 4 <br>services exclusifs au cours de votre séjour,<br> quel que soit le nombre de nuits, à partir du <br> moment où vous avez atteint le statut Green Trotter Infinite. <br> Veuillez noter que, sauf indication contraire,<br> les services sont inclus pour tous les clients participant au séjour.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- CARD RESA ENTIRE -->
      <div id="resa-entire" class="rooms-classy" v-if="this.lodging.is_entire">
        <ModalRoomGalery
          :item="lodging"
          v-if="showRoomGalery"
          @close="showRoomGalery = false"
        ></ModalRoomGalery>
        <div class="row row-resa-entire center rooms-classy" :style="{ backgroundImage: 'url(' + this.lodging.photo_second_banner + ')' }">
          <div class="col-lg-6 col-xs-9 col-room">
            <div style="color:white" class="header-room">
              <h2 class="raleway-reg-p24">Les chambres</h2>
              <div class="galerie">
                <img src="../../images/Galerie icone.svg" alt="galerie" />
                <button style="color:white" class="link-hortense" @click="showModalRoomGalery">Voir la Galerie</button>
              </div>
            </div>
            <!--<div v-if="!isPhone" class="row">-->
              <div v-if="!isPhone">
                <slither-slider
                ref="slither"
                :options="{ numberOfSlides: 1, dots: false, controls: true, loop: true, autoplay: true }"
              >
                <div v-for="(chunk, chunkIndex) in chunkedRooms" :key="chunkIndex" class="slide">
                  <div class="row">
                    <div class="col-6" v-for="(room, index) in chunk.slice(0, 2)" :key="index">
                      <div class="card-room mb-4 ml-4">
                        <div class="capacity">
                          <p class="raleway-reg-p24 mb-0 mr-1">{{ room.capacity }}</p>
                          <img src="../../images/profil.svg" alt="" />
                        </div>
                        <img src="../../images/lit.svg" alt="lit" />
                        <div class="room-details">
                          <p class="raleway-semi-p15 mb-0">{{ room.name }}</p>
                          <p class="raleway-reg-p15">{{ room.bed_type }}</p>
                        </div>
                        <div v-if="room.service_rooms">
                          <p v-show="sliceValueByIndex(index)" class="raleway-semi-p15 mb-2">Équipements</p>
                          <p v-show="sliceValueByIndex(index)"
                            v-for="service in getUniqueServiceRooms(room.service_rooms)"
                            :key="service.id" class="raleway-reg-p13">
                            {{ service.name }}
                          </p>
                          <div v-show="sliceValueByIndex(index)" :style="{ 'float': isPhone ? 'right' : 'left' }">
                            <a @click="toggleService(index)" class="link-hortense" :style="{ 'text-decoration': isPhone ? 'none' : 'underline' }">
                              voir moins <img src="../../images/Fleche haut.svg" :style="{ 'width': isPhone ? '3vw' : '1vw' }" alt="fleche haut" />
                            </a>
                          </div>
                          <div v-show="!sliceValueByIndex(index)" :style="{ 'float': isPhone ? 'right' : 'left' }">
                            <a @click="toggleService(index)" class="link-hortense" :style="{ 'text-decoration': isPhone ? 'none' : 'underline' }">
                              voir plus
                              <img src="../../images/flechebas-simple.svg" :style="{ 'width': isPhone ? '3vw' : '1vw' }" alt="fleche bas" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6" v-for="(room, index) in chunk.slice(2, 4)" :key="index">
                      <div class="card-room mb-4 ml-4">
                        <div class="capacity">
                          <p class="raleway-reg-p24 mb-0 mr-1">{{ room.capacity }}</p>
                          <img src="../../images/profil.svg" alt="" />
                        </div>
                        <img src="../../images/lit.svg" alt="lit" />
                        <div class="room-details">
                          <p class="raleway-semi-p15 mb-0">{{ room.name }}</p>
                          <p class="raleway-reg-p15">{{ room.bed_type }}</p>
                        </div>
                        <div v-if="room.service_rooms">
                          <p v-show="sliceValueByIndex(index)" class="raleway-semi-p15 mb-2">Équipements</p>
                          <p v-show="sliceValueByIndex(index)"
                            v-for="service in getUniqueServiceRooms(room.service_rooms)"
                            :key="service.id" class="raleway-reg-p13">
                            {{ service.name }}
                          </p>
                          <div v-show="sliceValueByIndex(index)" :style="{ 'float': isPhone ? 'right' : 'left' }">
                            <a @click="toggleService(index)" class="link-hortense" :style="{ 'text-decoration': isPhone ? 'none' : 'underline' }">
                              voir moins <img src="../../images/Fleche haut.svg" :style="{ 'width': isPhone ? '3vw' : '1vw' }" alt="fleche haut" />
                            </a>
                          </div>
                          <div v-show="!sliceValueByIndex(index)" :style="{ 'float': isPhone ? 'right' : 'left' }">
                            <a @click="toggleService(index)" class="link-hortense" :style="{ 'text-decoration': isPhone ? 'none' : 'underline' }">
                              voir plus
                              <img src="../../images/flechebas-simple.svg" :style="{ 'width': isPhone ? '3vw' : '1vw' }" alt="fleche bas" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template slot="previous">
                  <div class="slider-arrow previousss">
                    <img src="../../images/fleche gauche.svg" alt="Previous" />
                  </div>
                </template>
                <template slot="next">
                  <div class="slider-arrow nexttt">
                    <img src="../../images/fleche droite.svg" alt="Next" />
                  </div>
                </template>
              </slither-slider>
          </div>
            <div v-if="isPhone">
              <slither-slider
                  ref="slither"
                  :options="{ numberOfSlides: 1, dots: false, controls: true, loop: true, autoplay: true }"
                >
                  <div v-if="isPhone" v-for="(room, index) in this.rooms" :key="index">
                    <div class="card-room mb-4 ml-4" :class="{ 'padding_bottom': sliceValueByIndex(index) }">
                      <!--<img :src="image" alt="room photo" class="hub-img" />-->
                      <div class="capacity">
                        <p class="raleway-reg-p24 mb-0 mr-1">{{ room.capacity }}</p>
                        <img src="../../images/profil.svg" alt="" />
                      </div>
                      <img src="../../images/lit.svg" alt="lit" />
                      <div class="room-details">
                        <!--<p class="raleway-semi-p15 mb-0">Chambre {{ index + 1 }}</p>-->
                        <p class="raleway-semi-p15 mb-0">{{ room.name }}</p>
                        <p class="raleway-reg-p15">{{ room.bed_type }}</p>
                      </div>
                      <!--HERE MODIFY-->
                      <div v-if="room.service_rooms">
                            <p v-show="sliceValueByIndex(index)" class="raleway-semi-p15 mb-2">Équipements</p>
                            <p v-show="sliceValueByIndex(index)"
                               v-for="service in getUniqueServiceRooms(room.service_rooms)"
                               :key="service.id" class="raleway-reg-p13">

                                {{ service.name}}
                            </p>
                            <div v-show="sliceValueByIndex(index)" :style="{'float': isPhone ? 'right' : 'left'}">
                                <a @click="toggleService(index)" class="link-hortense" :style="{'text-decoration': isPhone ? 'none' : 'underline'}">
                                  voir moins <img src="../../images/Fleche haut.svg" :style="{'width': isPhone ? '3vw' : '1vw'}" alt="fleche bas"
                                /></a>
                            </div>
                            <div v-show="!sliceValueByIndex(index)" :style="{'float': isPhone ? 'right' : 'left'}">
                                <a @click="toggleService(index)" class="link-hortense" :style="{'text-decoration': isPhone ? 'none' : 'underline'}">
                                  voir plus
                                  <img src="../../images/flechebas-simple.svg" :style="{'width': isPhone ? '3vw' : '1vw'}" alt="fleche bas"
                                /></a>
                            </div>
                      </div>
                      <!--HERE END MODIFY-->
                    </div>
                  </div>
                  <template slot="previous">
                    <div class="previous">
                      <img src="../../images/fleche gauche.svg" alt="fleche gauche" />
                    </div>
                  </template>
                  <template slot="next">
                    <img src="../../images/fleche droite.svg" alt="fleche droite" />
                  </template>
              </slither-slider>
            </div>
            <!--DISPLAY ROOM FOR MOBILE-->
<!--            <div>-->
<!--                <div v-for="(room, index) in this.lodging.rooms" :key="index">-->
<!--                  <div class="card-room mb-4 ml-4" style="border: grey 1px solid">-->
<!--                    <div class="capacity pr-2 pt-2" style="float:right">-->
<!--                      <p class="raleway-reg-p15 mb-0 mr-1">{{ room.capacity }}</p>-->
<!--                      <img src="../../images/profil.svg" alt="" />-->
<!--                    </div>-->
<!--                    <img src="../../images/lit.svg" alt="lit" style="padding-left:15%" />-->
<!--                    <div class="room-details" style="padding-left:15%">-->
<!--                      <p class="raleway-up-semi-p13 mb-0">Chambre {{ index + 1 }}</p>-->
<!--                      <p class="raleway-reg-p15">{{ room.bed_type }}</p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--            </div>-->
          </div>
          <div class="col-lg-6 col-card-resa my-reser">
            <div class="card-resa-entire">
              <div class="form-inp-lodging">
                <div class="first-line-inp">
                  <HotelDatePicker
                      :startDate="minDate"
                      :endDate="maxDate"
                      :format="'DD/MM/YYYY'"
                      :gridStyle="false"
                      :hoveringTooltip="false"
                      :i18n="cFR"
                      ref="datePicker"
                      @periodSelected="selectDates"
                  ></HotelDatePicker>
                  <img v-if="!this.startDate && !this.endDate" src="../../images/3.png" alt="calendar icon" class="icon" />
                  <!-- <label for="checkin" class="raleway-reg-p13" :class="{ 'mr-4':!isPhone }"
                    >Arrivée
                    <input v-if="!isPhone" v-model="startDate" placeholder="Arrivée" type="date" data-date-inline-picker="true"/>
                    <input v-else v-model="startDate" placeholder="Arrivée" type="date" data-date-inline-picker="true" style="width: 38vw"/>
                  </label>
                  <label for="checkout" class="raleway-reg-p13 ml-2"
                    >Départ
                    <input v-if="!isPhone" v-model="endDate" placeholder="Départ" type="date" data-date-inline-picker="true"/>
                    <input v-else v-model="endDate" placeholder="Départ" type="date" data-date-inline-picker="true" style="width: 38vw"/>
                  </label> -->
                </div>
                <div class="input-wrapper">
                  <img src="../../images/profil.svg" alt="Icon" class="input-icon" />
                  <input 
                    v-if="!isPhone" 
                    type="number" 
                    v-model="guestNumber" 
                    placeholder="Nombre de voyageurs" 
                    class="raleway-reg-p13 person-pad" 
                    min="1"
                  />
                <input v-else type="number" v-model="guestNumber" placeholder="Nombre de voyageurs" class="raleway-reg-p13 person-pad" min="1" style="width: 90vw"/>
                </div>
                <button
                  class="hortense-btn-primary"
                  @click="checkIfAvailable(startDate, endDate, guestNumber)"
                  :disabled="isSearching"
                >
                  {{ availabilitySearchButtonCaption }}
                </button>
                <p class="raleway-reg-p13 mt-2">Veuillez saisir le nombre de voyageurs avant de faire votre recherche</p>

                <!-- <p class="raleway-reg-p16 mt-2" style="font-weight: 800;">Privilège membre</p> -->
                <!-- <p class="raleway-reg-p13 mt-1">Les membres du programme de fidélité Hortensia accèdent à des privilèges VIP et à des avantages exclusifs dans cet établissement, ainsi que dans des centaines d’autres établissements, en réservant sur notre site.</p> -->
                <!-- <p class="raleway-reg-p13 mt-1" @click="showSignupModal = true">Pas encore membre ? Créez votre compte sur Hortense ici.</p> -->
              </div>
              <!--<template v-if="isAvailable && !isSearching">-->
              <template v-if="show_resa_card && !isSearching && lodging.is_entire && !accommodation_code">
                <ReservationCard
                  :minNights="minNights"
                  :accomodationCode="accommodation_code"
                  :searched="searched"
                  :isSearching="isSearching"
                  :item="lodging"
                  :from="from"
                  :to="to"
                  :roomAvailability="roomOptions"
                  :roomAvailabilityNotKoedia="availabilitiesNotKoedia"
                  :tariffsNotKoedia="tariffsNotKoedia"
                  :isEntire="true"
                  :taxNotKoedia="lodgingTax"
                  :nights="nights"
                  :taxTotal="touristTax"
                  @sendInfo="displayUserInfo"
                  :lodging="this.lodging"
                ></ReservationCard>
                <p v-if="lodging.lodging_description.is_club" class="raleway-reg-p13 mt-2">Si vous souhaitez profiter du service de conciergerie du Club Hortense, veuillez nous en informer lors de votre réservation, et nous vous contacterons.</p>
              </template>
              <template  v-if="show_resa_card && !isSearching && lodging.is_entire && accommodation_code">
                <ReservationCard
                  :minNights="minNights"
                  :accomodationCode="accommodation_code"
                  :searched="searched"
                  :isSearching="isSearching"
                  :item="lodging"
                  :from="from"
                  :to="to"
                  :roomAvailability="roomOptions"
                  :roomAvailabilityNotKoedia="availabilitiesNotKoedia"
                  :tariffsNotKoedia="tariffsNotKoedia"
                  :isEntire="true"
                  :taxNotKoedia="lodgingTax"
                  :nights="nights"
                  :taxTotal="touristTax"
                  @sendInfo="displayUserInfoKoedia"
                  :lodging="this.lodging"
                  :koediaFrom="koediaFrom"
                  :koediaTo="koediaTo"
                  :koediaSessionId="koediaSessionId"
                ></ReservationCard>
                <p v-if="lodging.lodging_description.is_club" class="raleway-reg-p13 mt-2">Si vous souhaitez profiter du service de conciergerie du Club Hortense, veuillez nous en informer lors de votre réservation, et nous vous contacterons.</p>
              </template>
              <!--<div v-if="isAvailable === false">
                <div class="row d-flex">
                  <div class="col-12">
                    <p class="raleway-reg-p15">Établissement non disponible pour ces dates.</p>
                  </div>
                </div>
              </div>-->
            </div>
            <div v-if="!lodging.is_entire" class="raleway-reg-p13 message-container">
              <p v-if="!this.accommodation_code" class="text-center text-muted bottom-static-text">
              Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
            </div>
            <!--<div v-else-if="lodging.is_entire" class="raleway-reg-p13 message-container">
              <div v-if="!lodging.lodging_channel_manager">
                <p v-if="!lodging.rooms[0].koedia_room_name" class="text-center text-muted bottom-static-text">
                Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
              </div>
            </div>-->
          </div>
          <div class="col-lg-3 col-md-3 col-card-resa my-reser fixed-card" data-aos="fade-up" data-aos-duration="900" v-if="!isPhone" v-show="showCardAfterScroll">
            <div class="card-resa-entire" style="width: 90%; padding: 2rem 1rem; min-height: 343px;">
              <div class="form-inp-lodging">
                <p class="raleway-reg-p15">A partir de <strong>{{ this.lodging.lowest_price}}€/nuit</strong></p>
                <div class="first-line-inp">
                  <HotelDatePicker
                  :startingDateValue="disstartDate"
                  :endingDateValue="disendDate"
                    :startDate="minDate"
                    :endDate="maxDate"
                    :format="'DD/MM/YYYY'"
                    :gridStyle="false"
                    :hoveringTooltip="false"
                    :i18n="cFR"
                    ref="datePicker"
                    @periodSelected="selectDates"
                  ></HotelDatePicker>
                  <img v-if="!startDate && !endDate" src="../../images/3.png" alt="calendar icon" class="icon" />
                </div>
                <div class="input-wrapper">
                  <img src="../../images/profil.svg" alt="Icon" class="input-icon" />
                  <input 
                    v-if="!isPhone" 
                    type="number" 
                    v-model="guestNumber" 
                    placeholder="Nombre de voyageurs" 
                    class="raleway-reg-p13 person-pad" 
                    min="1"
                  />
                <input v-else type="number" v-model="guestNumber" placeholder="Nombre de voyageurs" class="raleway-reg-p13 person-pad" min="1" style="width: 90vw"/>
                </div>
                <button class="hortense-btn-primary" style="width: 100% !important; padding: 14px 32px !important;" @click="checkIfAvailableTwo(startDate, endDate, guestNumber)" :disabled="isSearching">
                  {{ availabilitySearchButtonCaption }}
                </button>
                <p v-if="isPhone" class="raleway-reg-p13 mt-2">Veuillez saisir le nombre de voyageurs avant de faire votre recherche</p>
                <p v-if="!isPhone" class="raleway-reg-p16 mt-2" style="font-weight: 800;">Privilège membre</p>
                <p v-if="!isPhone" class="raleway-reg-p13 mt-1">Les membres du programme de fidélité Hortensia accèdent à des privilèges VIP et à des avantages exclusifs dans cet établissement, ainsi que dans des centaines d’autres établissements, en réservant sur notre site.</p>
                <p v-if="!isPhone" class="raleway-reg-p13 mt-1" @click="showSignupModal = true" >Pas encore membre ? Créez votre compte sur Hortense ici.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!this.lodging.is_entire && !isPhone">
      <div id="resa-entire-koedia">
        <div class="row row-resa-entire center">
          <div class="col-lg-3 col-md-3 col-card-resa my-reser fixed-card" data-aos="fade-up" data-aos-duration="900" v-if="!isPhone" v-show="showCardAfterScroll">
            <div class="card-resa-entire" style="width: 90%; padding: 2rem 1rem; min-height: 343px;">
              <div class="form-inp-lodging">
                <p class="raleway-reg-p15">A partir de <strong>{{ this.lodging.lowest_price}}€/nuit</strong></p>
                <div class="first-line-inp">
                  <HotelDatePicker
                    :startDate="minDate"
                    :endDate="maxDate"
                    :format="'DD/MM/YYYY'"
                    :gridStyle="false"
                    :hoveringTooltip="false"
                    :i18n="cFR"
                    ref="datePicker"
                    @periodSelected="selectDates"
                  ></HotelDatePicker>
                  <img v-if="!startDate && !endDate" src="../../images/3.png" alt="calendar icon" class="icon" />
                </div>
                <div class="input-wrapper">
                  <img src="../../images/profil.svg" alt="Icon" class="input-icon" />
                  <input 
                    v-if="!isPhone" 
                    type="number" 
                    v-model="guestNumber" 
                    placeholder="Nombre de voyageurs" 
                    class="raleway-reg-p13 person-pad" 
                    min="1"
                  />
                <input v-else type="number" v-model="guestNumber" placeholder="Nombre de voyageurs" class="raleway-reg-p13 person-pad" min="1" style="width: 90vw"/>
                </div>
                <button class="hortense-btn-primary" style="width: 100% !important; padding: 14px 32px !important;" @click="checkIfAvailableTwo(startDate, endDate, guestNumber)" :disabled="isSearching">
                  {{ availabilitySearchButtonCaption }}
                </button>
                <p v-if="isPhone" class="raleway-reg-p13 mt-2">Veuillez saisir le nombre de voyageurs avant de faire votre recherche</p>
                <p v-if="!isPhone" class="raleway-reg-p16 mt-2" style="font-weight: 800;">Privilège membre</p>
                <p v-if="!isPhone" class="raleway-reg-p13 mt-1">Les membres du programme de fidélité Hortensia accèdent à des privilèges VIP et à des avantages exclusifs dans cet établissement, ainsi que dans des centaines d’autres établissements, en réservant sur notre site.</p>
                <p v-if="!isPhone" class="raleway-reg-p13 mt-1" @click="showSignupModal = true" >Pas encore membre ? Créez votre compte sur Hortense ici.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </template>

      <!-- END RESA ENTIRE -->
      <template v-if="!this.lodging.is_entire">
        <div
          class="room-availiable rooms-classy"
          v-if="this.lodging.photo_second_banner"
          :style="{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(' +
              this.lodging.photo_second_banner +
              ')',
          }"
        >
          <p class="raleway-reg-p24" data-aos="fade-up" data-aos-duration="900">Les chambres</p>
          <a
            :href="reservationUrl"
            class="xs-btn-primary"
            data-aos="fade-up"
            data-aos-duration="900"
            >Voir les disponibilités</a
          >
        </div>
        <div class="room-availiable" v-else>
          <p class="raleway-reg-p24" data-aos="fade-up" data-aos-duration="900">Les chambres</p>
          <a
            :href="reservationUrl"
            class="xs-btn-primary"
            data-aos="fade-up"
            data-aos-duration="900"
            >Voir les disponibilités</a
          >
        </div>
      </template>
      <div class="container-profil">
        <ModalEnvironmentalPromise
          :description="this.lodging.lodging_description"
          v-if="showModalPromise"
          @close="showModalPromise = false"
        ></ModalEnvironmentalPromise>
        <div class="row greennote-lodging" data-aos="fade-up" data-aos-duration="900">
          <div class="col-lg-7 col-xs-12">
            <p class="raleway-reg-p24">La Green Note</p>
            <div class="first d-flex">
              <img
                :src="greenNotePicto(this.lodging.score.toFixed())"
                :alt="this.lodging.score.toFixed()"
                v-if="this.lodging.score"
              />
              <div class="content">
                <div class="note d-flex" v-if="this.lodging.score">
                  <p class="raleway-reg-p15 mb-0">{{ this.lodging.score.toFixed() }}/</p>
                  <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                  <p class="raleway-bold-p16 mb-0">
                    {{ this.lodging.green_note_mention }}
                  </p>
                </div>
                <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
                  >Comprendre la Green Note</a
                >
              </div>
              <div class="d-flex">
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.green_host">
                  <img src="../../images/green_host.png" alt="Green Host" class="img-fluid" style=" width: auto; max-width:100%; height:auto;">
                </div>
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.green_globe">
                  <img src="../../images/green_globe.png" alt="Green Globa" class="img-fluid" style="width: auto; max-width:100%; height:auto;">
                </div>
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.green_key">
                  <img src="../../images/verte.png" alt="Verte" class="img-fluid" style="width: auto; max-width:100%; height:auto;">
                </div>
                <div class="w-30 ml-auto" v-if="lodging.lodging_description.ecolabel">
                  <img src="../../images/eco_label.png" alt="Eco Label" class="img-fluid" style=" width: auto; max-width:100%; height:92%;">
                </div>
              </div>
              <!-- <img src="../../images/Label.svg" class="label" alt=""> -->
            </div>
            <div class="row row-slidenote" v-if="!isPhone">
              <div class="col-lg-5 col-xs-8 col-md-8">
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Politique et gestion <br />environnementale</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.pol }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note1">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="pol"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#65B193' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Responsabilité sociale</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.soc }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note2">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="soc"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#386F5A' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Formation du personnel</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.per }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note3">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="per"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#65B193' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Sensibilisation des clients</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.cli }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note4">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="cli"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#4E8E75' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Politique d’achats responsables</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.ach }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note5">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="ach"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#E5E5B7' }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xs-8 col-md-8 mt-3">
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Gestion de l’eau</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.eau }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note6">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="eau"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#386F5A' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Gestion des déchets</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.dec }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note7">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="dec"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#A9D997' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Gestion de l’énergie</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.ene }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note8">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="ene"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#7DCEAF' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Eco-construction</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.con }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note9">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="con"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#358867' }"
                    />
                  </div>
                </div>
                <div class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">Environnement extérieur et intérieur</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ this.env }}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div class="slide-note10">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="env"
                      v-bind="options"
                      ref="slider"
                      disabled
                      :processStyle="{ backgroundColor: '#8ED8A2' }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row row-slidenote" v-if="isPhone">
              <div class="col-lg-5 col-xs-8 col-md-8">
                <slither-slider
                  ref="slither"
                  :options="{ numberOfSlides: 1, dots: false, controls: true, loop:true, autoplay:true }"
                >
                <div v-for="(slider, index) in laGreenNoteSliderData" :key="index" class="full-note">
                  <div class="title-note d-flex">
                    <p class="raleway-reg-p13">{{ slider.title }}</p>
                    <div class="note d-flex">
                      <p class="raleway-reg-p15 mb-0">{{ slider.model}}/</p>
                      <p class="raleway-reg-p13 mb-0 mr-2">10</p>
                    </div>
                  </div>
                  <div :class="`slide-note${index + 1}`">
                    <vue-slider
                      class="raleway-reg-p15"
                      v-model="slider.model"
                      v-bind="options"
                      disabled
                      :processStyle="{ backgroundColor: slider.color }"
                    />
                  </div>
                </div>
                  <template slot="previous">
                    <div class="previous">
                      <img src="../../images/fleche gauche.svg" alt="fleche gauche" />
                    </div>
                  </template>
                  <template slot="next">
                    <img src="../../images/fleche droite.svg" alt="fleche droite" />
                  </template>
                </slither-slider>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-xs-10 col-planet">
            <img src="../../images/illus green note.png" />
            <div class="promise" v-if="this.lodging.lodging_description.environmental_promise">
              <h2 class="raleway-reg-p24 mb-3">Leur promesse environnementale</h2>
              <p v-if="!isPhone" class="raleway-reg-p15 mb-3">
                «
                {{ this.lodging.lodging_description.environmental_promise }} »<br />
              </p>
              <a v-if="!isPhone" @click="showModalPromise = !showModalPromise" class="link-hortense"
                >En savoir plus sur leur promesse environnementale</a
              >
              <p v-if="isPhone" class="raleway-reg-p15 mb-3">
                <span v-if="!showMore">
                  {{ truncatedText(250,  this.lodging.lodging_description.environmental_promise) }}
                </span>
                <span v-else>
                  {{ this.lodging.lodging_description.environmental_promise }}
                </span>
              </p>
              <button v-if="isPhone" @click="toggleText" class="show-more-btn">
                {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isPhone" id="lodging-resto">
        <div class="hortense-fav" data-aos="fade" data-aos-duration="900">
          <p class="merriweather-italic24 text-center">
            « {{ this.lodging.lodging_description.hortense_crush }} »
          </p>
          <p class="raleway-bold-p16">Le coup de cœur de Hortense</p>
        </div>
      </div>
      <div v-if="isPhone" id="lodging-resto">
        <div class="hortense-fav" data-aos="fade" data-aos-duration="900">
          <p class="merriweather-italic text-center">
            « {{ this.lodging.lodging_description.hortense_crush }} »
          </p>
          <p class="raleway-bold-p16">Le coup de cœur de Hortense</p>
        </div>
      </div>
      <!-- resto -->
      <div id="main-lodging-resto">
        <div class="lodging-resto-side-section">
          <div class="resto-side-section-title" v-if="this.lodging.is_entire === false">
            A table
          </div>
          <div class="resto-side-section-title" v-else>
            Le Club Hortense
          </div>
          <div  class="resto-side-section-title-2-sec">
            <div class="resto-side-section-title-2">
              L'EXPÉRIENCE CULINAIRE
            </div>
          </div>
          <div class="resto-side-section-description" v-if="this.lodging.is_entire === false">
            Avec Hortense, la gastronomie est au coeur de votre expérience de voyage. Vous pourrez vivre des moments culinaires uniques tout au long de votre séjour en choisissant parmi plusieurs formules.
          </div>
          <div class="resto-side-section-description" v-else>
            Offrez-vous l'expérience exclusive d'un séjour dans un lieu d'exception avec un service tout inclus, accompagné d'un chief privé. Pour tout séjour de 3 nuits minimum, accédez aux services de conciergerie du club Hortense. Laissez-vous dorloter par Hortense, qui se charge de tous les détails pour que vous puissiez savourer pleinement chaque instant de votre escapade.
          </div>
          <!-- <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.breakfast_included">
            DÉLICE AU RÉVEIL - PETIT DÉJEUNER INCLUS
          </div> -->
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.half_board">
            ESCAPADE GOURMANDE - DEMI-PENSION
          </div>
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.full_board">
            ÉVASION CULINAIRE - PENSION COMPLÈTE
          </div>
          <div class="resto-side-section-tab" v-if="this.lodging.lodging_profile.all_inclusive">
            PLAISIRS ILLIMITÉS - TOUT INCLUS
          </div>
          <a href="/experience-club-hortense" class="resto-side-section-link" v-if="this.lodging.is_entire === true">Découvrir l'expérience Club Hortense</a>
        </div>
        <div class="lodging-resto-section-1" v-if="this.lodging.is_entire === false">
          <div class="chief-section-main">
            <div class="chief-section">
<!--              <div v-if="isPhone" class="chief-descriptionn">-->
<!--                <strong>La spécialité culinaire du Chef</strong>-->
<!--                <p>{{ this.lodging.lodging_description.chief_speciality }}</p>-->
                <ModalChiefIntro
                  :description="this.lodging.lodging_description"
                  v-if="showModalChief"
                  @close="showModalChief = false"
               ></ModalChiefIntro>
<!--                <a class="chief-disc-link" @click="showModalChief = !showModalChief">En savoir plus sur le Chef</a>-->
<!--              </div>-->
              <div class="resturant-sectionn">
                <strong>La spécialité culinaire du Chef</strong>
                <ul class="list-resto" v-if="this.lodging.lodging_description.chief_speciality">
                  <li
                      v-for="(li, index) in formatList(this.lodging.lodging_description.chief_speciality)"
                      :key="index"
                  >
                    {{ li }}
                  </li>
                </ul>
                <ModalChiefIntro
                  :description="this.lodging.lodging_description"
                  v-if="showModalChief"
                  @close="showModalChief = false"
               ></ModalChiefIntro>
                <a class="chief-disc-link" @click="showModalChief = !showModalChief">En savoir plus sur le Chef</a>
              </div>
<!--              <div v-if="!isPhone" class="chief-description">-->
<!--                <strong>La spécialité culinaire du Chef</strong>-->
<!--                <p>{{ this.lodging.lodging_description.chief_speciality }}</p>-->
<!--                <ModalChiefIntro-->
<!--                    :description="this.lodging.lodging_description"-->
<!--                    v-if="showModalChief"-->
<!--                    @close="showModalChief = false"-->
<!--                ></ModalChiefIntro>-->
<!--                <a class="chief-disc-link" @click="showModalChief = !showModalChief">En savoir plus sur le Chef</a>-->
<!--              </div>-->
<!--              <img v-if="this.lodging.lodging_description.chief_photo" class="chief-img" :src="this.lodging.lodging_description.chief_photo" alt="chief">-->
            </div>
            <div class="resturant-section">
              <strong>Les restaurants de l’établissement</strong>
              <ul class="list-resto" v-if="this.lodging.lodging_description.restaurant">
                <li
                  v-for="(li, index) in formatList(this.lodging.lodging_description.restaurant)"
                  :key="index"
                >
                  {{ li }}
                </li>
              </ul>
            </div>
          </div>
          <div class="culinary-section">
            <div class="culinary-img" v-if="foodMount && foodMount[0]">
              <img :src="foodMount[0].culinary_specialty_photo" alt="culinary photo" />
            </div>
            <div class="culinary-list-section">
              <p class="raleway-semi-p15">Top 5 des spécialités culinaires de la région</p>
              <div class="culinary-list">
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_one }}</li>
                  <li>{{ foodMount[0].name_two }}</li>
                  <li>{{ foodMount[0].name_three }}</li>
                </ul>
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_four }}</li>
                  <li>{{ foodMount[0].name_five }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="lodging-resto-section-2" v-else>
          <div class="culinary-section">
            <div class="culinary-list-section">
              <p class="culinary-list-title">Top 5 des spécialités culinaires de la région</p>
              <div class="culinary-list">
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_one }}</li>
                  <li>{{ foodMount[0].name_two }}</li>
                  <li>{{ foodMount[0].name_three }}</li>
                </ul>
                <ul  class="list-resto" v-if="foodMount && foodMount[0]">
                  <li>{{ foodMount[0].name_four }}</li>
                  <li>{{ foodMount[0].name_five }}</li>
                </ul>
              </div>
            </div>
            <div class="culinary-img" v-if="foodMount && foodMount[0]">
              <img :src="this.foodMount[0].culinary_specialty_photo" alt="culinary photo" />
            </div>
          </div>
          <div class="service-section">
            <div class="service-description">
              <span class="raleway-semi-p15">Les services sur mesure</span>
              <p>Hortense vous offre un service de conciergerie disponible sur demande avant votre séjour. Vous avez la possibilité de personnaliser votre expérience. Veuillez noter que certains services peuvent entrainer des frais supplémentaires.</p>
            </div>
            <div class="service-list-section">
              <div class="tailor-entire">
                <p v-if="this.lodging.lodging_profile.tailor_service" v-html="this.lodging.lodging_profile.tailor_service"></p>
              </div>
              <div class="service-img" v-if="!isPhone">
                <img :src="this.lodging.lodging_description.service_photo" alt="service photo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- exp -->
      <div data-aos="fade-up" data-aos-duration="900" class="ml-3 mt-5 lodging-header-container">
        <div class="lodging-header">
          <p class="raleway-reg-p24">Expériences authentiques, à proximité de cet établissement</p>
          <p class="raleway-reg-p15"></p>
          <div class="show-cart-btn">
            <img src="../../images/Localisation.svg" alt="Localisation" width="15" height="15" />
            <a class="link-hortense" @click="displayMapfind">Voir sur la carte</a>
          </div>
        </div>
      </div>
      <LodgingShowPhone
        :experiences="this.lodging.experiences"
        :user="user"
        v-if="this.targetShare"
      ></LodgingShowPhone>
      <div
        id="experiences-lodging"
        class="experiences-lodging"
        data-aos="fade-up"
        data-aos-duration="900"
        v-else
      >
        <div class="container-lodging phone">
          <div class="row d-flex justify-content-around">
            <div class="col-lg-3 col-md-10">
              <div class="lodging-header-desktop">
                <h2 class="raleway-reg-p24">
                  Expériences authentiques, à proximité de cet établissement
                </h2>
                <p class="raleway-reg-p15"></p>
                <div class="show-cart-btn">
                  <img
                    src="../../images/Localisation.svg"
                    alt="Localisation"
                    width="15"
                    height="15"
                  />
                  <a class="link-hortense" @click="displayMapfind">Voir sur la carte</a>
                </div>
              </div>
              <div class="exp-filter">
                <ul class="filter-exp">
                  <li>
                    <a class="exp-category active" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="culturelles">culturelles</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="au naturel">au naturel</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="au bord de l'eau">au bord de l'eau</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="sportives">sportives</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="immersives">immersives</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="culinaires">culinaires</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="diy">DIY</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                  <li>
                    <a class="exp-category" href="#" @click.prevent="selectOption"
                      ><p class="raleway-reg-p16" data-value="oenologiques">oenologiques</p>
                      <img src="../../images/flechedroite.png" alt="flèche droite"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-8 col-md-10 pt-2" v-if="experiencesFiltered && experiencesFiltered.length > 0">
              <slither-slider
                ref="slither"
                :options="{ numberOfSlides: 1, dots: false, controls: true }"
              >
                <div v-for="experience in experiencesFiltered" :key="experience.id">
                  <Cardexplodging
                    :experience="experience"
                    :user="isUserConnected()"
                    @connection="$emit('connection')"
                  ></Cardexplodging>
                  <!-- <div v-if='!selectedMarker'>
                    <Cardexplodging :experience="experience" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
                  </div>
                  <div v-else>
                    <Cardexplodging :experience="experiencesFiltered[experienceselected]" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
                  </div> -->
                </div>
                <template slot="previous">
                  <div class="previous">
                    <img src="../../images/fleche gauche.svg" alt="fleche gauche" />
                  </div>
                </template>
                <template slot="next">
                  <img src="../../images/fleche droite.svg" alt="fleche droite" />
                </template>
              </slither-slider>
            </div>
            <div class="col-8 pt-5" v-else>
              <p class="raleway-reg-p15">
                Hortense est à la recherche d'expériences à vous proposer près de cet hébergement
              </p>
            </div>
          </div>
        </div>
      </div>
<!--      <div-->
<!--        class="banner-view"-->
<!--        :style="{-->
<!--          backgroundImage: 'url(' + this.lodging.photo_region_banner + ')',-->
<!--        }"-->
<!--        v-if="this.lodging.photo_region_banner"-->
<!--      ></div>-->
<!--      <div-->
<!--        class="banner-view"-->
<!--        :style="{-->
<!--          backgroundImage:-->
<!--            'url(https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg)',-->
<!--        }"-->
<!--        v-else-->
<!--      ></div>-->
      <div class="container-region" data-aos="fade-up" data-aos-duration="900">
        <div class="dicsover-lodging-state">
          <div
            class="accordion-header"
            @click.prevent="isExpandDiscoverLodging = !isExpandDiscoverLodging"
            :class="this.isExpandDiscoverLodging ? 'active' : ''"
          >
            <h2 class="raleway-reg-p24 mb-4">Découvrir la région</h2>
          </div>
          <div class="accordion-body" :class="this.isExpandDiscoverLodging ? 'active' : ''">
            <p class="raleway-reg-p15 text-center">{{ this.region.content }}</p>
            <div class="df-jcc-aic" v-if="this.lodging.lodging_description.travel_guide_link">
            </div>
          </div>
          <a v-if="this.lodging.lodging_description.travel_guide_link && this.lodging.lodging_description.travel_guide_link != '' && this.lodging.lodging_description.travel_guide_link != null && this.lodging.lodging_description.travel_guide_link != 'null'" :href="this.lodging.lodging_description.travel_guide_link" target="_blank" class="hortense-btn-primary">DÉCOUVREZ NOTRE GUIDE DE VOYAGE</a>
          <br>
        </div>
      </div>
      <!-- services -->
      <div id="services-lodging" class="services-lodging">
        <div class="row row-services">
          <div class="col-lg-4 col-md-12">
            <div v-if="lodging.photo_equipment">
              <img :src="baseUrl + lodging.photo_equipment" alt="Service Image" class="img-service" />
            </div>
<!--            <div-->
<!--              class="img-service"-->
<!--              :style="{-->
<!--                backgroundImage: 'url(' + baseUrl + this.lodging.photo_equipment + ')',-->
<!--              }"-->
<!--              v-if="this.lodging.photo_equipment"-->
<!--            ></div>-->
            <div
              class="img-service"
              :style="{
                backgroundImage:
                  'url(https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg)',
              }"
              v-else
            ></div>
          </div>
          <div
            class="col-lg-7 col-md-6 col-xs-6 col-description-services"
            v-if="this.lodging.is_entire"
          >
            <div
              class="accordion-header"
              @click.prevent="isExpandDescriptionServices = !isExpandDescriptionServices"
              :class="this.isExpandDescriptionServices ? 'active' : ''"
            >
              <h2 class="raleway-reg-p24 col-margin mb-5">Equipements et services</h2>
            </div>
            <div class="accordion-body" :class="this.isExpandDescriptionServices ? 'active' : ''">
              <div class="row row-services-description">
                <template v-for="(services, category, index) in this.groupedServiceEntiers">
                  <div
                    class="col-12 col-lg-6 mb-4"
                    data-aos="fade-up"
                    data-aos-duration="900"
                    :key="index"
                  >
                    <h4 class="raleway-bold-p16 mb-2">{{ category }}</h4>
                    <div v-for="service in services" :key="service.id">
                      <div class="list-services top">
                        <div class="service-item">
                          <img :src="displayPicto(service)" :alt="service.name" />
                          <p class="raleway-reg-p15 mb-0">{{ service.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-6 col-xs-6 col-description-services" v-else>
            <div
              class="accordion-header"
              @click.prevent="isExpandDescriptionServices = !isExpandDescriptionServices"
              :class="this.isExpandDescriptionServices ? 'active' : ''"
            >
              <h2 class="raleway-reg-p24 col-margin mb-5">Equipements et services</h2>
            </div>
            <div class="accordion-body" :class="this.isExpandDescriptionServices ? 'active' : ''">
              <div class="row row-services-description">
                <template v-for="(services, category, index) in this.groupedServices">
                  <div
                    class="col-12 col-lg-6 mb-4"
                    data-aos="fade-up"
                    data-aos-duration="900"
                    :key="index"
                  >
                    <p class="raleway-bold-p16 mb-2">{{ category }}</p>
                    <div v-for="service in services" :key="service.id">
                      <div class="list-services top">
                        <div class="service-item">
                          <img
                            :src="displayPicto(service)"
                            :alt="service.name"
                            width="20px"
                            height="20px"
                          />
                          <p class="raleway-reg-p15 mb-0">
                            {{ `${service.name} ${formatDispo(service.disponibility)}` }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- <div class="left-align" style="padding-left: 50%;" v-if="!isPhone && this.lodging.lodging_profile.kids_corner">
                <h2 class="raleway-reg-p24 mt-3 mb-2 left-align">Le Kids Corner</h2>
              </div> -->
            <div v-if="!isPhone && this.lodging.lodging_profile.kids_corner" class="kids-corner">
              <div class="left-section mt-1">
              </div>
              <div class="content mt-3">
                <div class="description">
                  <h2 class="raleway-reg-p24 mt-1 mb-2">Le Kids Corner</h2>
                  <p>Découvrez les équipements et services offerts par cet établissement pour accueillir vos enfants.</p>
                  <div class="dynamic" v-html="this.lodging.lodging_profile.kids_corner"></div>
                  <p>
                    Certains équipements et services sont sous réserve de disponibilité et peuvent entraîner un coût
                    supplémentaire.
                  </p>
                </div>
              </div>
            </div>

        <div v-if="isPhone && this.lodging.lodging_profile.kids_corner" style="padding: 3rem;" class="row admin-discover">
          <div
            class="col-lg-5 col-xs-6"
            v-if="this.lodging"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            <div
              class="accordion-header"
              @click.prevent="isExpandAdminDiscover = !isExpandAdminDiscover"
              :class="this.isExpandAdminDiscover ? 'active' : ''"
            >
              <h2 class="raleway-reg-p24">Le Kids Corner</h2>
            </div>
            <div class="accordion-body" :class="this.isExpandAdminDiscover ? 'active' : ''">
              <div class="kids-mobile">
              <div class="image-section">
                <!-- <h1>Le Kids Corner</h1> -->
              </div>
              <div class="content">
                <p>Découvrez les équipements et services offerts par cet établissement pour accueillir vos enfants.</p>
                <div class="dynamic" v-html="this.lodging.lodging_profile.kids_corner"></div>
                <p>
                  Certains équipements et services sont sous réserve de disponibilité et peuvent entraîner un coût
                  supplémentaire.
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
          <div v-if="this.lodging.lodging_profile.good_to_know && this.lodging.lodging_profile.good_to_know != ''" class="col-lg-7 offset-lg-4">
            <div class="good-know" v-if="this.lodging.lodging_profile.good_to_know">
              <div class="head d-flex">
                <img src="../../images/info-blue.svg" alt="info" />
                <h4 class="raleway-bold-p16 ml-4 mb-0">Bon à savoir</h4>
              </div>
              <p class="raleway-reg-p15 content" v-html="this.lodging.lodging_profile.good_to_know"></p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="customer-avis"
        class="customer-avis"
        v-if="this.comments && this.comments.length"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <h3 class="raleway-reg-p24 mb-5 text-center">Avis des voyageurs</h3>
        <div class="sum-note mb-5 d-flex justify-content-center">
          <img src="../../images/Note.svg" alt="note" />
          <div class="note df-jsb-aic ml-4">
            <p class="mb-0 raleway-reg-p24">{{ this.average }}/</p>
            <p class="mb-0 raleway-reg-p15">10</p>
          </div>
        </div>
        <template v-for="resa in this.resaMount">
          <Cardcomment :resa="resa" :key="resa.id"></Cardcomment>
        </template>
        <div class="pagination-avis text-center mt-3">
          <a :href="'reservation-hotel/' + this.lodging.slug + '/avis'" class="link-hortense">
            voir plus
            <img src="../../images/flechebas-simple.svg" alt="fleche bas"
          /></a>
        </div>
      </div>
      <div v-if="isPhone" class="lodging-made-trip">
                  <img
                    src="../../images/bag.svg"
                    alt="bag"
                  />
                  <div class="content">
                    <p class="raleway-bold-p mb-0">Envie d'organiser un voyage sur mesure ?</p>
                    <p class="raleway-reg-p13 mb-0">Prenez contact avec nous <a class="link-hortense" :href="'/voyage-sur-mesure/'">ici</a></p>
                  </div>
            </div>
      <div class="selection-host-card">
        <h2 class="raleway-reg-p24 text-center mb-5">Voir les autres hébergements</h2>
        <div v-if="lodgingsByRegionMounted">
          <div v-for="lodging in this.lodgingsByRegionMounted.slice(0, 2)" :key="lodging.id">
            <SelectionHost
              :lodging="lodging"
              :user="isUserConnected()"
              :exclusive="exclusive"
              v-if="lodging"
              class="mt-5"
              data-aos="fade-up"
              data-aos-duration="900"
            ></SelectionHost>
          </div>
        </div>
        <div class="df-jcc-aic">
          <a :href="'/reservation-hotel'" class="hortense-btn-primary"
            >Découvrir nos hébergements</a
          >
        </div>
      </div>
    </template>
    <ReservationNewUserInfo
      v-else
      :item="lodging"
      :user="user"
      :lodging="lodging"
      :from="this.formatDateKoedia(koediaFrom)"
      :to="this.formatDateKoedia(koediaTo)"
      :tax="lodging.tax"
      :selectedOption="selectedRoomOption"
      :koediaFrom="koediaFrom"
      :koediaTo="koediaTo"
      :koediaRoomId="selectedRoomOption.room_id"
      :koediaSessionId="koediaSessionId"
      :pricePerNightHt="pricePerNightHt(chosenIdx)"
      :totalHt="totalHt(chosenIdx)"
      :tva="tva(chosenIdx)"
      :totalTtc="totalTtc(chosenIdx)"
      :adults="parseInt(guestNumber)"
      :childs="0"
      :numberOfNights="nights"
      :selectedPriceOptionFee="selectedPriceOptionFee"
      :accomodationCode="accommodation_code"
      :totalTaxNotKoedia="totalTaxNotKoedia"
      :totalHtNotKoedia="totalHtNotKoedia"
      :totalTvaNotKoedia="totalTvaNotKoedia"
      :totalTtcNotKoedia="totalTtcNotKoedia"
      :houseHold="houseHold"
      @closed="handleClose"
    ></ReservationNewUserInfo>
    <div v-if="!this.lodging.is_entire && isPhone" ref="datePickerContainer" style="display: none;">
      <HotelDatePicker
        :startDate="minDate"
        :endDate="maxDate"
        :format="'DD/MM/YYYY'"
        :gridStyle="false"
        :hoveringTooltip="false"
        :i18n="cFR"
        ref="datePicker"
        @periodSelected="selectDates"
      />
    </div>
    <div v-if="isPhone" id="fixed-banner" class="fixed-banner">
    <div class="banner-content">
      <p class="banner-price">
          À partir de <strong style="color: black;">{{ this.lodging.lowest_price }}€</strong> / nuit
      </p>
      <p class="banner-dates">{{ formattedBannerDates || 'Aucune date sélectionnée' }}</p>
    </div>
    <button class="banner-button" @click="openDatePicker">{{this.printStart ? 'RESERVER' : 'VOIR LES DISPONIBILITÉS' }}</button>
  </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import fetcher from "../../packs/shared/api.js";
import VueSlider from "vue-slider-component";
import ReservationNewUserInfo from "pages/reservations/ReservationNewUserInfo";
import LodgingShowPhone from "../../components/LodgingShowPhone.vue";
import SelectionHost from "../../components/cards/SelectionHost.vue";
import Cardexplodging from "../../components/cards/Cardexplodging.vue";
import ReservationCard from "../../components/cards/ReservationCard";
import Cardcomment from "../../components/cards/Cardcomment.vue";
import Modalshare from "../../components/modals/Modalshare.vue";
import ModalGallery from "../../components/modals/ModalGallery.vue";
import ModalRoomGalery from "../../components/modals/ModalRoomGalery.vue";
import Maplodgingshow from "../../pages/lodgings/Maplodgingshow.vue";
import ModalEnvironmentalPromise from "../../pages/lodgings/ModalEnvironmentalPromise.vue";
import ModalChiefIntro from "../../pages/lodgings/ModalChiefIntro.vue";
import AOS from "aos";
import "mapbox-gl/dist/mapbox-gl.css";
import {isNil} from "buefy";
import Modalsignup from "../../components/modals/Modalsignup.vue";

export default {
  name: "lodging-show",
  components: {
    VueSlider,
    ModalGallery,
    LodgingShowPhone,
    ModalRoomGalery,
    SelectionHost,
    Cardcomment,
    Cardexplodging,
    Modalshare,
    Maplodgingshow,
    ModalEnvironmentalPromise,
    ModalChiefIntro,
    ReservationCard,
    ReservationNewUserInfo,
    Modalsignup,
  },
  props: {
    minNights: Number,
    rooms: Array,
    lodging: {
      type: Object,
      required: true
    },
    exclusive: Object,
    user: Object,
    directionsApi: String,
    processStyle: {
      backgroundColor: String,
    },
  },
  data() {
    console.log('this.lodginhg', this.lodging)
    return {
      startX: 0,
      endX: 0,
      disstartDate: null,
      disendDate: null,
      showCardAfterScroll: false,
      printStart: null,
      printEnd:null,
      printStarttwo: null,
      printEndtwo:null,
      currentIndex: 0,
      baseUrl: 'https://www.hortense.green',
      show_resa_card: false,
      slice_value: [],
      houseHold: null,
      accommodation_code: null,
      totalTaxNotKoedia: String,
      totalHtNotKoedia: String,
      totalTvaNotKoedia: String,
      totalTtcNotKoedia: String,
      touristTax: null,
      availability: null,
      isExpand: true,
      isExpandAdminDiscover: true,
      isExpandEssentials: true,
      isExpandInfo: true,
      isExpandDiscoverLodging: true,
      isExpandDescriptionServices: true,
      experiencesList: null,
      experiencesFiltered: [],
      isSearching: false,
      searched: false,
      isChoosing: true,
      targetShare: null,
      distanceBetween: null,
      to: null,
      from: null,
      koediaFrom: null,
      koediaTo: null,
      // pricePerNight: null,
      // totalHt: 0,
      nights: null,
      roomOptions: null,
      koediaSessionId: null,
      showModalPromise: false,
      showRoomGalery: false,
      showModalChief: false,
      modalGallery: false,
      showMapfind: false,
      chosenIdx: null,
      groupedQuestions: null,
      markers: [],
      ratedLodgings: [],
      averageList: [],
      average: 0,
      showModal: false,
      showSignupModal: false,
      lodgingsByRegionMounted: null,
      source: "",
      categoryServices: [],
      categoryServiceEntiers: [],
      groupedServiceEntiers: null,
      groupedServices: null,
      wishlistLodgings: null,
      SelectedOption: "culturelles",
      selectedRoomOption: null,
      foodMount: null,
      experienceselected: 0,
      options: {
        min: 0,
        max: 10,
        dotSize: 60,
        fixed: true,
        clickable: false,
        tooltip: "none",
      },
      availabilitiesNotKoedia: [],
      selectedPriceOptionFee: null,
      isUserLoggedIn: false,
      showMore: false,
      isExpand: true,
      laGreenNoteSliderData: [],
      cFR: {
        night: "Nuit",
        nights: "Nuits",
        week: "semaine",
        weeks: "semaines",
        "day-names": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        "check-in": "Arrivée",
        "check-out": "Départ",
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
          ],
        tooltip: {
          halfDayCheckIn: "Available CheckIn",
          halfDayCheckOut: "Available CheckOut",
          saturdayToSaturday: "Only Saturday to Saturday",
          sundayToSunday: "Only Sunday to Sunday",
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum.",
        },
      },
    };
  },
  watch: {
    slice_value : {
      handler(newVal) {
        this.slice_value = newVal;
        //console.log('slice_value');
        //console.log(this.slice_value);
      },
      immediate: true,
      deep: true,
    },
    directionsApi: {
      immediate: true,
      handler(key) {
        const mapsLoader = new Loader({ apiKey: key });
        mapsLoader.load().then(() => this.getLocation());
      },
    },
    isChoosing: {
      handler(newVal) {
        this.isChoosing = newVal;
      },
      immediate: true,
    },
    /*experiencesFiltered: {
        immediate: true,
        handler() {
          this.$refs.slither.reload();
        },
      },*/
  },
  created() {
    if (this.user) {
      this.isUserLoggedIn = true;
    }
    this.laGreenNoteSliderData = [
        { title: "Politique et gestion environnementale", model: this.pol, color: '#65B193' },
        { title: "Responsabilité sociale", model: this.env, color: '#386F5A' },
        { title: "Formation du personnel", model: this.per, color: '#65B193' },
        { title: "Sensibilisation des clients", model: this.cli, color: '#4E8E75' },
        { title: "Politique d’achats responsables", model: this.ach, color: '#E5E5B7' },
        { title: "Gestion de l’eau", model: this.eau, color: '#386F5A' },
        { title: "Gestion des déchets", model: this.dec, color: '#A9D997' },
        { title: "Gestion de l’énergie", model: this.ene, color: '#7DCEAF' },
        { title: "Eco-construction", model: this.con, color: '#358867' },
        { title: "Environnement extérieur et intérieur", model: this.env, color: '#8ED8A2' },
      ]
  },
  methods: {
    prevSlidee() {
    this.$refs.slither.prev();
  },
  nextSlidee() {
    this.$refs.slither.next();
  },
    getPhotoUrl(index) {
      return this.lodging.photos_keys[index].url;
    },
    onTouchStart(event) {
    this.startX = event.touches[0].clientX;
  },
    onTouchMove(event) {
      this.endX = event.touches[0].clientX;
    },
  onTouchEnd() {
    const swipeDistance = this.startX - this.endX;

    if (swipeDistance > 50) {
      this.nextSlide();
    } else if (swipeDistance < -50) {
      this.prevSlide();
    }
  },
  nextSlide() {
    if (this.currentIndex < this.totalPhotos - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  },
  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.totalPhotos - 1;
    }
  },
    goBack() {
      window.location.href = "/reservation-hotel";
    },
    updatePhotoCounter(event) {
      this.currentPhotoIndex = event.detail.index;
    },
    openDatePicker() {
      if(this.printStart && this.printEnd) {
        if(this.lodging.is_entire) {
          this.guestNumber = 2;
          this.checkIfAvailable(this.printStart, this.printEnd, 2)
          this.scrollToElement('my-reser')
        } else {
        window.location.href = "/reservation-hotel/"+this.lodging.slug+'/reservation'
        }
      }else if (this.$refs.datePicker) {
        if( this.$refs.datePickerContainer){this.$refs.datePickerContainer.style.display = 'block'; }
        this.$refs.datePicker.showDatepicker();
      }
    },
    selectDates(event, start, end) {
      console.log('start', start)
      console.log('start', start)
      this.printEndtwo = end;
      this.printStarttwo = start;
        this.startDate = this.formatDate(start);
        this.endDate =  this.formatDate(end);
        this.printStart = this.formatDate(start);
        this.printEnd = this.formatDate(end);
        this.koediaFrom = this.formatDateKoedia(this.printStart);
        this.koediaTo = this.formatDateKoedia(this.printEnd);

        const state = {
        koediaFrom: this.koediaFrom,
        koediaTo: this.koediaTo,
        from: this.koediaFrom,
        to:  this.koediaTo,
      };
      console.log("state", state)
      localStorage.setItem('reservationState', JSON.stringify(state));
      if (this.$refs.datePickerContainer) {
        this.$refs.datePickerContainer.style.display = 'none';
      }
      },
      formatDate(date) {
        console.log('formatDate', date)
        const set = new Date(date);
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return set.toLocaleString("fr", options);
      },
      formatDateKoedia(date) {
        if (date.includes('-') || !date) {
          return date;
        }
        const dateBits = date.split('/');
        return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`; 
      },
    truncatedText(textLength, text_content) {
      const text = text_content;
      const maxLength = textLength;
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },

    debounce(func, delay) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), delay);
        }
      },

    async createSvg(index, backgroundColor) {
      if (index < 0 || index >= this.laGreenNoteSliderData.length) return; // Check bounds

      const response = await fetch(require(`../../images/${this.laGreenNoteSliderData[index].filePath}.svg`), {
        headers: {
          'Cache-Control': 'no-cache',
        }
      });
      const svg = await response.text();
      let updatedSvg = svg
        .replace(/\.b\s*{\s*fill:\s*none;\s*stroke:\s*#[0-9a-fA-F]{3,6};\s*stroke-miterlimit:\s*\d+;\s*}/g, `.b { fill: none; stroke: ${backgroundColor}; stroke-miterlimit: 10; }`)
        .replace(/\.c\s*{\s*fill:\s*#[0-9a-fA-F]{3,6};\s*}/g, `.c { fill: ${backgroundColor}; }`);
      index = this.isPhone ? 0 : index
      let doc = document.getElementsByClassName('vue-slider-dot-handle');
      if (doc[index]) {
        doc[index].style.backgroundImage = '';
        doc[index].style.backgroundImage = `url('data:image/svg+xml;utf8,${encodeURIComponent(updatedSvg)}')`;
      }
    },

    getProcessStyle(value) {
      const colors = ['#4682B4', '#E5E5B7', '#DBE8CC', '#C8E2AB', '#A9D997', '#8ED8A2', '#7DCEAF', '#65B193', '#4E8E75', '#3B6152'];
      const index = value - 1;

      if (index >= 0 && index < colors.length) {
        const color = colors[index];
        this.debounce(() => this.createSvg(index, color), 300)(); // Debounce the SVG creation
        return { backgroundColor: color };
      }
    },

    checkIndex(index){
      this.slitherIndex = index
    },

    toggleText() {
      this.showMore = !this.showMore;
    },
    isNil() {
      return isNil
    },
    getBackgroundStyle() {
      // if (!this.lodging.photo_banner) {
      //   return {};
      // }
      return {
        backgroundImage: `url("${this.baseUrl}${this.lodging.photo_banner}")`
      };
    },
    async ReqInventoryBook() {
      try {
        console.log('koediaFrom', this.from)
        console.log('koediaFrom', this.koediaFrom.replace(/^undefined-undefined-/, ''))
        console.log(' this.printStart',  this.printStart)

        const res = await fetcher.post(
          `/lodgings/${this.lodging.slug}/request_inventory_booking`,
          {
            start: this.koediaFrom.replace(/^undefined-undefined-/, ''),
            end: this.koediaTo.replace(/^undefined-undefined-/, ''),
          }
        );
        if(res.data){
           this.minNights = res.data.booking_request;
           console.log('this.minNights');
           console.log(this.minNights);
        }
      } catch (e) {
        console.error(e);
      }
    },
    getUniqueServiceRooms(services) {
      const uniqueNames = new Set();
      return services.filter(service => {
        if (!uniqueNames.has(service.name)) {
          uniqueNames.add(service.name);
          return true;
        }
        return false;
      });
    },
    isCardActive(index) {
      const points = this.user?.loyalty_points || 0;
      if (index === 0) return points >= 500 && points < 1000;
      if (index === 1) return points >= 1000 && points < 1500;
      if (index === 2) return points >= 1500 && points < 2500;
      if (index === 3) return points >= 2500;
      return false;
    },
    sliceValueByIndex(index) {
      //console.log('slice_value[index]');
      //console.log(this.slice_value[index]);
      return this.slice_value[index];
    },
    toggleService(idx) {
       this.$set(this.slice_value, idx, !this.slice_value[idx]);
      //this.slice_value[idx] = !this.slice_value[idx];

      console.log('idx');
      console.log(idx);

      console.log('idx value');
      console.log(this.slice_value[idx]);
    },
    displayUserInfo(room, pricePerNightTtc, totalTtc, koediaRoomAvailability, idx, tva, priceHt, houseHOld = null) {
      console.log('this.tva', Number(tva))
      console.log('this.tva', tva)

      this.nights = isNaN(this.nights) ? 1 : this.nights;
      // this.roomChoosed = room;
      this.selectedRoomOption = koediaRoomAvailability;
      // this.numberOfRoom = numberOfRoom;
      //this.chosendTotalTtc = totalTtc;
      this.chosentPricePerNightHt = pricePerNightTtc;
      this.chosenIdx = idx;
      this.isChoosing = false;
      this.selectedPriceOptionFee = Array.isArray(koediaRoomAvailability)
          ? koediaRoomAvailability.find((el) => el.tariff === idx).tariff_cancel_feed || null
          : null;

          console.log('this.lodging.tax.tourist_tax', this.lodging.tax)

      // TAX
      //this.totalTaxNotKoedia = (this.lodging.tax.tourist_tax * this.nights * parseInt(this.guestNumber)).toFixed(2);
      this.totalTaxNotKoedia = this.lodging.tax ? (this.lodging.tax.tourist_tax * this.nights).toFixed(2) : 0;

      // TOTAL HT
      this.totalHtNotKoedia = (priceHt * this.nights).toFixed(2);

      console.log('this.nights', this.nights)

      // TOTAL TVA
      this.totalTvaNotKoedia = (tva * this.nights).toFixed(2);

      // TOTAL TTC
      this.totalTtcNotKoedia = (
      (isNaN(parseFloat(this.totalHtNotKoedia)) ? 0 : parseFloat(this.totalHtNotKoedia)) +
      (isNaN(parseFloat(this.totalTvaNotKoedia)) ? 0 : parseFloat(this.totalTvaNotKoedia)) +
      (isNaN(parseFloat(this.totalTaxNotKoedia)) ? 0 : parseFloat(this.totalTaxNotKoedia))
    ).toFixed(2);
      this.houseHold = houseHOld;
    },
    koediaRoomId(roomName) {
      if (this.availabilities) {
        return this.availabilities[roomName].room_id;
      }
    },
    saveState() {
      localStorage.setItem('reservationState', JSON.stringify({}));
      console.log('savingggg  statee.......')
      const state = {
        exclusive: this.exclusive,
        roomChoosed: this.roomChoosed,
        selectedOption: this.selectedOption,
        koediaRoomId: this.koediaRoomId(this.roomChoosed.koedia_room_name),
        koediaSessionId: this.koediaSessionId,
        koediaFrom: this.koediaFrom,
        koediaTo: this.koediaTo,
        user: this.user,
        lodging: this.lodging,
        from: this.from,
        to: this.to,
        tax: this.tax,
        total: this.total,
        adults: this.adults,
        childs: this.childs,
        childDate: this.childDate,
        nights: this.nights,
        pricePerNightHt: this.pricePerNightHt,
        totalHt: this.totalHt,
        tva: this.tva,
        totalTtc: this.totalTtc,
        accommodation_code: this.accommodation_code,
        selectedPriceOptionFee: this.selectedPriceOptionFee,
        totalTaxNotKoedia: this.totalTaxNotKoedia,
        totalHtNotKoedia: this.totalHtNotKoedia,
        totalTvaNotKoedia: this.totalTvaNotKoedia,
        totalTtcNotKoedia: this.totalTtcNotKoedia,
        isChoosing: false,
        houseHold: this.houseHold
      };
      console.log("state", state)
      localStorage.setItem('reservationState', JSON.stringify(state));
    },
    displayUserInfoKoedia(room, pricePerNightTtc, totalTtc, koediaRoomAvailability, tariff, tva, priceHt, houseHOld = null) {
      this.nights = isNaN(this.nights) ? 1 : this.nights;
      console.log("ROOM");
      console.log(room);

      console.log("pricePerNightTtc");
      console.log(pricePerNightTtc);

      console.log("totalTtc");
      console.log(totalTtc);

      console.log("koediaRoomAvailability");
      console.log(koediaRoomAvailability);

      this.roomChoosed = room;
      this.selectedOption = koediaRoomAvailability;
      // this.numberOfRoom = numberOfRoom;
      this.totalTtc = totalTtc;
      this.pricePerNightHt = pricePerNightTtc;
      this.isChoosing = false;
      if (Array.isArray(koediaRoomAvailability)) {
        const selectedOption = koediaRoomAvailability.find((el) => el.tariff === tariff);
        if (selectedOption) {
          this.selectedPriceOptionFee = selectedOption.tariff_cancel_feed;
        } else {
          this.selectedPriceOptionFee = null;
        }
      } else {
        this.selectedPriceOptionFee = null;
      }
      // TAX
      /*if (this.childs === 0) {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights * this.adults).toFixed(2);
      } else if (this.adults === 0) {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights * this.childs).toFixed(2);
      } else {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights * (this.adults + this.childs)).toFixed(2);
      }*/
      if (this.tax && this.tax.tourist_tax) {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights).toFixed(2);
      } else {
        this.totalTaxNotKoedia = 0;
      }
      
      // TOTAL HT
      this.totalHtNotKoedia = (priceHt * this.nights).toFixed(2);

      // TOTAL TVA
      this.totalTvaNotKoedia = (tva * this.nights).toFixed(2);

      // TOTAL TTC
      this.totalTtcNotKoedia = (parseFloat(this.totalHtNotKoedia) + parseFloat(this.totalTvaNotKoedia) + parseFloat(this.totalTaxNotKoedia)).toFixed(2);
      this.houseHold = houseHOld;
      this.saveState();
    },
    pricePerNightTtc(idx) {
      return parseFloat(this.roomOptions.options[idx].room_price.per_day.rack);
    },
    totalTtc(idx) {
      if(this.accommodation_code){
       return (parseFloat(this.roomOptions.options[idx].room_price.total.rack)).toFixed(2);
      }else{
       return this.chosendTotalTtc;
      }
    },
    pricePerNightHt(idx) {
      if(this.accommodation_code){
       return (parseFloat(this.pricePerNightTtc(idx)) / (1 + (parseFloat(this.lodging.tax.tva_rate) / 100))).toFixed(2);
      }else{
        return this.chosentPricePerNightHt;
      }
    },
    totalHt(idx) {
      if(this.accommodation_code){
       return (parseFloat(this.totalTtc(idx)) / (1 + (parseFloat(this.lodging.tax.tva_rate) / 100))).toFixed(2);
      }else{
       return (parseFloat(this.chosendTotalTtc) / (1 + (parseFloat(this.lodging.tax.tva_rate) / 100))).toFixed(2);
      }
    },
    tva(idx) {
      return (parseFloat(this.totalTtc(idx)) - parseFloat(this.totalHt(idx))).toFixed(2);
    },
    handleClose() {
      this.isChoosing = true;
    },
    completeReservation() {
      this.isChoosing = false;
    },
    getTotalTTC() {
      if (this.total && this.tva) {
        return this.total + this.tva;
      }
      return 0;
    },
    async fetchFood() {
      try {
        const response = await fetcher.get(
          "/departments/" + this.department.id + "/food_specialties/list"
        );
        console.log("food", response.data)
        this.foodMount = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchExps(cat) {
      try {
        const response = await fetcher.get(
          `/categories/${encodeURI(cat)}/experiences/list?lodging_id=${this.lodging.id}`
        );
        this.experiencesFiltered = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    formatDispo(data) {
      if (data === "disponible") {
        return "";
      } else if (data === "extra") {
        return "(en supplément)";
      } else if (data === "on_demand") {
        return "(sur demande)";
      }
    },
    async fetchLodgingByRegion() {
      try {
        const response = await fetcher.get("/reservation-hotel?search=" + this.region.name);
        this.lodgingsByRegionMounted = response.data.filter((el) => {
          el.name !== this.lodging.name;
        });
      } catch (e) {
        console.error(e);
      }
    },
    shareDevices() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return (this.targetShare = true);
      } else {
        return (this.targetShare = false);
      }
    },
     parseDate(dateString) {
  const [day, month, year] = dateString.split('/');
  return new Date(year, month, day);
  },
    updateAvailabilityInfo() {
      //if (this.options) {
      //  this.from = new Date(this.startDate);
      //  this.to = new Date(this.endDate);
      //  this.countNights();
      //} else {
      //  this.from = new Date(this.startDate);
      //  this.to = new Date(this.endDate);
      //  this.countNights();
      //}

      console.log('this.startDate', this.startDate)
      if(this.startDate && this.endDate) {
        console.log('this.startDatethis.startDate', this.startDate)
        console.log('this.startDatethis.startDate', this.endDate)

        this.from = new Date(this.parseDate(this.startDate.replace(/^undefined-undefined-/, '')));
      this.to = new Date(this.parseDate(this.endDate.replace(/^undefined-undefined-/, '')));
      console.log('this.startDatethis.startDate', this.from)
        console.log('this.startDatethis.startDate', this.to)
      }
      this.countNights();
      this.isSearching = false;
      this.searched = true;
      this.touristTax =(parseFloat(this.lodging.tax.tourist_tax) *
                        parseInt(this.nights) *
                        parseInt(this.guestNumber)).toFixed(2);
    },
    countNights() {
      this.nights = (this.to - this.from) / 864e5;
    },
    checkIfAvailable(checkin, checkout, guests) {
      this.searched = false;
      this.isSearching = true;
      this.show_resa_card = false;
      if (!this.accommodation_code)
      //if(!this.lodging.lodging_channel_manager.accommodation_code)
      {
        console.log('NOT KOEDIA');
        this.availabilitiesNotKoedia = [];
        fetcher.post('/lodgings/' + this.lodging.id + '/availabilities').
         then((response) => {
             console.log("RESPONSE");
             console.log(response);

             console.log('START');

             console.log('END');
             let availabilityStartDate = this.formatDateKoedia(this.startDate)
             let availabilityEndtDate = this.formatDateKoedia(this.endDate)
             console.log('this.startDate', this.availabilityStartDate)
             console.log('this.startDate', this.availabilityEndtDate)

             let filtered_data = response.data
                 .filter((item) => (item.tariff && item.date && /*item.tariff_price &&*/
                                    new Date(item.date) >= new Date(availabilityStartDate) &&
                                    new Date(item.date) <= new Date(availabilityEndtDate))
             );
             let ff= new Date(availabilityStartDate)
             console.log('this.startDate ff', ff)

             console.log('filtered_data', filtered_data)
             this.availabilitiesNotKoedia = filtered_data.filter((item) => item.tariff_price !== null);

             console.log('LODGING ' + this.lodging.id);
             console.log(this.availabilitiesNotKoedia);
             this.show_resa_card = true;
             console.log('show_resa_card');
             console.log(this.show_resa_card);
             this.updateAvailabilityInfo();
        });

        setTimeout(() => {
            this.searched = true;
            this.isSearching = false;
        }, 6000);
      }
      else
      {
        this.koediaFrom = checkin;
        this.koediaTo = checkout;
        this.ReqInventoryBook();
        console.log('KOEDIA');
        console.log(this.accommodation_code);
        this.isAvailable = null;
        this.getAvailability(checkin, checkout, guests)
          .then((response) => {
            this.koediaSessionId = response.data.koedia_session_id;
            this.roomOptions = response.data.availabilities;
            console.log('this.roomOptions');
            console.log(this.roomOptions);
      this.show_resa_card = true;
            this.updateAvailabilityInfo();
          })
          .catch((error) => {
            this.isSearching = false;
            console.log(error);
          });
      }
    },
    checkIfAvailableTwo(checkin, checkout, guests) {
      this.searched = false;
      this.isSearching = true;
      this.show_resa_card = false;
      if(!this.lodging.is_entire){
        const state = {
        koediaFrom: checkin,
        koediaTo: checkout,
        from: checkin,
        to: checkout,
      };
      localStorage.setItem('reservationState', JSON.stringify(state));
        window.location.href = "/reservation-hotel/"+this.lodging.slug+'/reservation'
      }
      else if (!this.accommodation_code)
      //if(!this.lodging.lodging_channel_manager.accommodation_code)
      {
        console.log('NOT KOEDIA');
        this.availabilitiesNotKoedia = [];
        fetcher.post('/lodgings/' + this.lodging.id + '/availabilities').
         then((response) => {
             console.log("RESPONSE");
             console.log(response);

             console.log('START');

             console.log('END');

             let filtered_data = response.data
                 .filter((item) => (item.tariff && item.date && /*item.tariff_price &&*/
                                    new Date(item.date) >= new Date(this.startDate) &&
                                    new Date(item.date) <= new Date(this.endDate))
             );
             this.availabilitiesNotKoedia = filtered_data.filter((item) => item.tariff_price !== null);

             console.log('LODGING ' + this.lodging.id);
             console.log(this.availabilitiesNotKoedia);
             this.show_resa_card = true;
             console.log('show_resa_card');
             console.log(this.show_resa_card);
             this.updateAvailabilityInfo();
        });

        setTimeout(() => {
            this.searched = true;
            this.isSearching = false;
        }, 6000);
      }
      else
      {
        this.ReqInventoryBook();
        console.log('KOEDIA');
        console.log(this.accommodation_code);
        this.koediaFrom = checkin;
        this.koediaTo = checkout;
        this.isAvailable = null;
        this.getAvailability(checkin, checkout, guests)
          .then((response) => {
            this.koediaSessionId = response.data.koedia_session_id;
            this.roomOptions = response.data.availabilities;
            console.log('this.roomOptions');
            console.log(this.roomOptions);
      this.show_resa_card = true;
            this.updateAvailabilityInfo();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.scrollToElement('my-reser')
    },
    getAvailability(checkin, checkout, guests) {
      console.log("this.formatDateKoedia(checkin.replace(/^undefined-undefined-/, ''))", this.formatDateKoedia(checkin.replace(/^undefined-undefined-/, '')))
      return fetcher.post(`/lodgings/${this.lodging.slug}/get_availability`, {
        accommodation_code: this.accommodation_code,
        accommodation_name: this.lodging.lodging_channel_manager.accommodation_name,
        start: this.formatDateKoedia(checkin.replace(/^undefined-undefined-/, '')),
        end: this.formatDateKoedia(checkout.replace(/^undefined-undefined-/, '')),
        adults: guests,
      });
    },
    isBeetween(date, from, to) {
      return date >= from && date <= to;
    },
    placeAvailable(lodging, from, to) {
      let bool = true;
      this.lodging.reservations.every((reservation) => {
        let checkin = new Date(reservation.checkin);
        let check_out = new Date(reservation.check_out);
        if (
          this.lodging.code === reservation.room_code &&
          (this.isBeetween(checkin, from, to) || this.isBeetween(check_out, from, to))
        ) {
          bool = false;
        }
      });
      return (this.isAvailable = bool);
    },
    formatEl(el, type) {
      const arr = type === "languages" ? this.lodging.languages : this.lodging.payments;
      return el === arr[arr.length - 1].name ? `${el}.` : `${el}, `;
    },
    formatList(string) {
      return string
        .replaceAll("\n", "")
        .replaceAll("•", "")
        .split("\t")
        .filter((s) => s);
    },
    openModal() {
      this.$root.$emit("openModal");
    },
    showModalGallery() {
      this.modalGallery = !this.modalGallery;
    },
    showModalRoomGalery() {
      this.showRoomGalery = !this.showRoomGalery;
    },
    isUserConnected() {
      console.log("user", this.user)
      return this.user ? this.user : {};
    },
    groupByCat(key) {
      const serviceEntiers = this.service_unique();
      console.log('serviceEntiers', serviceEntiers);

      this.groupedServices = serviceEntiers.reduce((rv, x) => {
        if (x.category && x.category.name) {
          const categoryName = x.category.name;
          if (!rv[categoryName]) {
            rv[categoryName] = [];
          }
          rv[categoryName].push(x);
        } else {
          const undefinedCategory = 'Undefined Category';
          if (!rv[undefinedCategory]) {
            rv[undefinedCategory] = [];
          }
          rv[undefinedCategory].push(x);
        }
        return rv;
      }, {});

      console.log('this.groupedServices', this.groupedServices);
      this.categoryServices = Object.keys(this.groupedServices);
    },

    groupByCatEntire() {
      const serviceEntiers = this.service_entiers();
      this.groupedServiceEntiers = serviceEntiers.reduce((rv, x) => {
        if (x.category && x.category.name) {
          const categoryName = x.category.name;
          if (!rv[categoryName]) {
            rv[categoryName] = [];
          }
          rv[categoryName].push(x);
        } else {
          const undefinedCategory = 'Undefined Category';
          if (!rv[undefinedCategory]) {
            rv[undefinedCategory] = [];
          }
          rv[undefinedCategory].push(x);
        }
        return rv;
      }, {});
      this.categoryServiceEntiers = Object.keys(this.groupedServiceEntiers);
    },
    selectCard(attr) {
      this.experienceselected = attr - 1;
      this.selectedMarker = !this.selectedMarker;
    },
    greenNotePicto(score) {
      if (score === `${score}`) {
        return require(`../../images/greennote ${score}.svg`);
      } else {
        return require("../../images/blanc.png");
      }
    },
    displayPicto(service) {
      const pictoName = service.name.toLowerCase().replaceAll("/", "-");
      try {
        return require(`../../images/${pictoName}.svg`);
      } catch (e) {
        return require("../../images/blanc.png");
      }
    },
    markerMap() {
      const markers = [];
      markers.push([this.lodging.longitude, this.lodging.latitude]);
      this.experiencesFiltered.map((el) => {
        markers.push([el.longitude, el.latitude]);
      });
      return markers;
    },
    displayMapfind() {
      this.markers = JSON.parse(JSON.stringify(this.markerMap()));
      this.showMapfind = !this.showMapfind;
      if (event.currentTarget.className === "link-hortense main-link") {
        setTimeout(function () {
          document.querySelectorAll(".marker").forEach((marker) => {
            marker.style.display = "none";
            marker.style.pointerEvents = "none";
          });
          document.querySelectorAll(".marker")[0].style.display = "block";
        }, 1);
      }
    },
    formatStyle(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalizeWords(string){
  return string.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
},
    scrollToElement(div) {
      const el = document.getElementsByClassName(div)[0];

      if (el) {
        el.scrollIntoView();
      }
    },
    async selectOption(e) {
      const activeEl = document.querySelector(".active");
      activeEl.classList.remove("active");
      e.target.parentNode.classList.add("active");
      this.SelectedOption = e.target.dataset.value;
      if (this.SelectedOption) {
        console.log(this.SelectedOption);
        this.fetchExps(this.SelectedOption);
      } else {
        this.experiencesFiltered = [];
      }
    },
    toggleLike() {
      if (this.user) {
        fetcher.patch("/wishlists/" + 1, { lodging_id: this.lodging.id }).then((res) => {
          this.wishlistLodgings = res.data.lodgings;
        });
      } else {
        window.scrollTo({
          top: 0,
          behaviour: "smooth",
        });
        this.$root.$emit("openModal");
        this.showSignupModal = true
      }
    },
    ratedLodging() {
      if (!this.lodging.reservations) {
        this.ratedLodgings = [];
      } else {
        this.ratedLodgings = this.lodging.reservations.filter((el) => el.rated === true);
      }
      return this.ratedLodgings;
    },
    averageRate() {
      this.ratedLodgings.forEach((el) => {
        this.averageList.push(el.rate_average);
      });
      return this.averageList;
    },
    averageLodging() {
      if (this.averageList && this.averageList.length) {
        this.average = (
          this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length
        ).toFixed(1);
        return this.average;
      } else {
        return (this.average = "-");
      }
    },
    isIncludeInWishlist() {
      if (this.wishlistLodgings) {
        for (const lodging of this.wishlistLodgings) {
          if (lodging.id === this.lodging.id) {
            return true;
          }
        }
      }
      return false;
    },
    fetchLodging() {
      this.ratedLodging();
      this.averageRate();
      this.averageLodging();
      this.groupByCat("category");
      this.groupByCatEntire("category");
      this.fetchLodgingByRegion();
    },
    groupBy() {
      this.groupedQuestions = this.questionList.reduce((rv, x) => {
        (rv[x["category"]["name"]] = rv[x["category"]["name"]] || []).push(x);
        return rv;
      }, {});
    },
    async fetchWishlist() {
      try {
        if (this.user) {
          const response = await fetcher.get("/wishlists.json");
          this.wishlistLodgings = response.data.lodgings;
        }
      } catch (e) {}
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((pos) => {
        let currPos = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
        let desti = new google.maps.LatLng(this.lodging.latitude, this.lodging.longitude);
        new google.maps.DistanceMatrixService()
          .getDistanceMatrix({
            origins: [currPos],
            destinations: [desti],
            travelMode: "DRIVING",
          })
          .then((res) => {
            this.distanceBetween = (res.rows[0].elements[0].distance.value / 1000).toFixed();
          });
      });
    },
    handleScroll() {
      const navHeight = document.getElementById('container-profil')?.offsetTop || 0;
      const lodgingDataHeight = document.getElementById('ending-bar')?.offsetTop || Number.MAX_SAFE_INTEGER;
      const scrollPosition = window.scrollY;

      this.showCardAfterScroll = scrollPosition > navHeight && scrollPosition < 2500;
    },
    service_entiers() {
      const lodgingServiceEntiers = this.lodging.lodging_service_entiers;
      const uniqueServiceEntiers = Object.values(
          lodgingServiceEntiers.reduce((acc, service) => {
            acc[service.name] = service;
            return acc;
          }, {})
      );
      return uniqueServiceEntiers;
    },
    service_unique() {
      const lodgingServiceEntiers = this.lodging.lodging_services;
      const uniqueServiceEntiers = Object.values(
          lodgingServiceEntiers.reduce((acc, service) => {
            acc[service.name] = service;
            return acc;
          }, {})
      );
      return uniqueServiceEntiers;
    },
    // distance(lat1, lon1, lat2, lon2, unit) {
    //  const radlat1 = (Math.PI * lat1) / 180;
    //  const radlat2 = (Math.PI * lat2) / 180;
    //  const theta = lon1 - lon2;
    //  const radtheta = (Math.PI * theta) / 180;
    //  this.distanceBetween = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    //  this.distanceBetween = Math.acos(this.distanceBetween);
    //  this.distanceBetween = (this.distanceBetween * 180) / Math.PI;
    //  this.distanceBetween = this.distanceBetween * 60 * 1.1515;
    //  if (unit === 'K') {
    //    this.distanceBetween = this.distanceBetween * 1.609344;
    //  }
    //  if (unit === 'N') {
    //    this.distanceBetween = this.distanceBetween * 0.8684;
    //  }
    //  return this.distanceBetween;
    // },
  },
  mounted() {


    AOS.init();
    window.addEventListener('scroll', this.handleScroll);
    this.fetchLodging();
    this.shareDevices();
    this.fetchFood();
    this.fetchExps("culturelles");
    if(this.lodging.lodging_channel_manager){
       this.accommodation_code = this.lodging.lodging_channel_manager.accommodation_code;
    }
    if(this.rooms) {
      for (let cpt = 0; cpt < this.rooms.length; cpt++) {
      this.slice_value[cpt] = false;
      }
    }
    window.dataLayer = window.dataLayer || [];
    const lodgingTypeMapping = {
      Hotel: "hôtels",
      Villa: "maisons et villas",
      Guesthouse: "chambres d'hôtes",
      Lodge: "lodges et lieux insolites",
      Appartment: "appartements",
      Gite: "maisons et villas",
      Guestroom: "chambres d'hôtes",
      Other: "lodges et lieux insolites"
    };
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [{
          item_name: this.lodging.name,
          item_id: this.lodging.id,
          item_city: this.lodging.city ? this.lodging.city.name : 'Pruniers-en-Sologne',
          item_category: 'Nos hébergements',
          item_variant: lodgingTypeMapping[this.lodging.type] || "hôtels",
          item_region: this.lodging && this.lodging.region ? this.lodging.region.name : 'Occitanie'
        }]
      }
    });
    const state = JSON.parse(localStorage.getItem('reservationState'));
      if (state && state.from && state.to) {
        console.log('state.from', state.from)
        this.disstartDate = new Date(this.formatDateKoedia(state.from.replace(/^undefined-undefined-/, '')));
        this.disendDate = new Date(this.formatDateKoedia(state.to.replace(/^undefined-undefined-/, '')));
        this.koediaFrom = state.koediaFrom;
        this.koediaTo = state.koediaTo;
        this.from = state.from;
        this.to = state.to;
        this.guestNumber = 2;
      }

      if (this.lodging.is_entire && this.from && this.to) {
      this.koediaFrom = this.formatDateKoedia(this.from);
      this.koediaTo = this.formatDateKoedia(this.to);
      this.from = this.koediaFrom;
      this.to = this.koediaTo;

      // Call checkIfAvailable function after setting the dates
      // this.checkIfAvailable(this.from, this.to, 2)
    }
  },
  beforeMount() {
    // this.fetchWishlist();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    formattedBannerDates() {
    if (this.printStarttwo && this.printEndtwo) {
      console.log('this.printStarttwo', this.printStarttwo)
      const options = { day: 'numeric', month: 'short' };
      const start = new Date(this.printStarttwo).toLocaleDateString('en-GB', options);
      const end = new Date(this.printEndtwo).toLocaleDateString('en-GB', options);
      return `${start} - ${end}`;
    }
    return '';
  },
    totalPhotos() {
      return this.lodging.photos_keys ? this.lodging.photos_keys.length : 0;
    },
    chunkedRooms() {
      if(this.rooms){
        if (this.rooms.length <= 4) {
        return [this.rooms];
      } else {
        return [this.rooms.slice(0, 4), this.rooms.slice(4)];
      }
      }
    },
    backgroundClass() {
      return this.lodging.photo_second_banner ? 'dynamic-background' : 'default-background';
    },
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
    reservationUrl(){
      console.log('exclusive')
      console.log(this.exclusive)
      if(this.exclusive)
        return '/offres-exclusives/' + this.exclusive.slug + '/' + this.lodging.slug + '/reservation'
      else
        return '/reservation-hotel/' + this.lodging.slug + '/reservation';
    },
    tariffsNotKoedia(){
       if(this.availabilitiesNotKoedia && this.availabilitiesNotKoedia.length > 0){
          let collection = this.availabilitiesNotKoedia.filter((el) => el.tariff != null && el.tariff != "").map((el) => el.tariff);
          console.log('TARIFFS');
          console.log([...new Set(collection)]);
          return [...new Set(collection)];
       }else{
           console.log('HERE')
           return [];
       }
    },
    lodgingTax(){
       console.log("FROM lodgingTax")
       console.log(this.lodging.tax);
       return this.lodging.tax;
    },
    /*isAvailable() {
      if(this.accommodation_code)
      {
          return this.roomOptions !== null;
      }else{
        return this.availabilitiesNotKoedia.length > 0;
      }
    },*/
    availabilitySearchButtonCaption() {
      if (this.isSearching) {
        return "Recherche en cours...";
      } else {
        return "Vérifier les disponibilités";
      }
    },
    comments() {
      console.log('this.lodginhg', this.lodging)
      return this.lodging.comments;
    },
    country() {
      return this.lodging.country;
    },
    region() {
      return this.lodging.region;
    },
    department() {
      return this.lodging.department;
    },
    city() {
      return this.lodging.city;
    },
    styles() {
      return this.lodging.styles;
    },
    services() {
      return this.lodging.lodging_services;
    },
    service_rooms() {
      return this.lodging.lodging_service_rooms;
    },
    service_essentials() {
      return this.lodging.service_essentials;
    },
    photos() {
      return this.lodging.photos_keys;
    },
    pol() {
      return this.lodging.average_score_for_POL;
    },
    soc() {
      return this.lodging.average_score_for_SOC;
    },
    per() {
      return this.lodging.average_score_for_PER;
    },
    cli() {
      return this.lodging.average_score_for_CLI;
    },
    ach() {
      return this.lodging.average_score_for_ACH;
    },
    eau() {
      return this.lodging.average_score_for_EAU;
    },
    dec() {
      return this.lodging.average_score_for_DEC;
    },
    ene() {
      return this.lodging.average_score_for_ENE;
    },
    con() {
      return this.lodging.average_score_for_CON;
    },
    env() {
      return this.lodging.average_score_for_ENV;
    },
    resaMount() {
      return this.lodging.reservations;
    },
    standing() {
      return this.lodging.standing;
    },
    questionList() {
      return this.lodging.questions;
    },
    calculateTva() {
      if (this.total) {
        return (this.tva = parseInt(((this.total * this.lodging.tax.tva_rate) / 100).toFixed(2)));
      }
      return 0;
    },
    total() {
      if (this.startDate && this.endDate) {
        const datestartDate = this.lodging.availabilities.find((el) => el.date === this.startDate);
        const indexFrom = this.lodging.availabilities.indexOf(datestartDate);
        const dateendDate = this.lodging.availabilities.find((el) => el.date === this.endDate);
        const indexTo = this.lodging.availabilities.indexOf(dateendDate);

        return this.lodging.availabilities
          .slice(indexFrom, indexTo + 1)
          .reduce((acc, current) => acc + current.pricing, 0);
      }
      return 0;
      return (this.tva = ((this.totalHt * this.lodging.tax.tva_rate) / 100).toFixed(2));
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-show">
#ending-bar {
  height: 1px;
  background-color: transparent;
}

.kids-mobile {
  display: flex;
  flex-direction: column;

  .image-section {
    width: 100%;
    height: 200px;
    background-image: url("../../images/kids-corner.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-top: 1rem;
    h1 {
      font-size: 2rem;
      font-weight: bold;
      color: white;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    }
  }

  .content {
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;

    p {
      margin-top: 5px;
    }
  }
}
.kids-corner {
  display: flex;
  flex-direction: row;
  width: 92%;
  padding-left: 3rem;

  .left-section {
    width: 48%;
    background-image: url("../../images/kids-corner.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    color: white;
    text-align: center;
    // height: 48%;
    padding-left: 1rem;
    padding-top: 1rem;
    height: 44vh;

    h1 {
      font-size: 1.8rem;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
       font-family: "Raleway";
    }
  }

  .content {
    width: 54%;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;

    .description {
      p {
        margin-top: 10px;
        font-family: "Raleway";
      }

      .dynamic {
        list-style-type: disc;
        padding-left: 20px;
        font-family: "Raleway";
      }
    }

    // .features {
    //   width: 48%;

    //   ul {
    //     list-style-type: disc;
    //     padding-left: 20px;
    //   }
    // }
  }
}
  .vhd__datepicker__clear-button svg{
    left:-27% !important
  }
.fixed-card {
  position: fixed;
  right: 20px;
  top: 100px;
  width: 350px;
  max-width: 100%;
}
.fixed-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.banner-content {
  display: flex;
  flex-direction: column;
}

.banner-price {
  font-family: $Raleway;
  font-weight: 400;
  font-size: 14px;
  color: black;
}

.banner-dates {
  font-family: "Raleway";
  color: #bfb7b7;
  font-size: 14px;
}

.banner-button {
  background-color: #3B6152;
  color: white;
  border: none;
  padding: 15px 11px;
  cursor: pointer;
  font-family: $Raleway;
  font-weight: 700;
  font-size: 13px;
}

.slider-arrow {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.slider-arrow img {
  width: 18px;
  height: 18px;
}

.previousss {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.nexttt {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.arroww {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.previous-arrow {
  left: -20px;
}

.next-arrow {
  right: -20px;
}

.arroww img {
  width: 20px;
  height: 20px;
}

.slider-container {
  position: relative;
  width: 100%;
  margin-top: 4.2vh;
  max-width: 800px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slider {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: 47vh;
  object-fit: cover;
  border-radius: 1px;
}

.image-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.9rem;
  z-index: 10;
}

.back-arrow {
  top: 10%;
  left: 10px;
  z-index: 2000;
  position: absolute;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.back-arrow-image {
  width: 24px;
  height: 24px;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  z-index: 10;
}


.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 3.1rem;
  height: 3.1rem;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}


.arrow:hover {
  opacity: 1;
}

.arrow-image {
  width: 0.7rem;
  height: auto;
}

.left-arrow {
  left: 2px;
}

.right-arrow {
  right: 2px;
}

.show-more-btn {
  color: #3B6152;
}
.title-textt {
  text-align: center;
  font: normal normal bold 19px/24px Raleway;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
.padding_bottom {
    padding-bottom: 10vw;
}

.link-hortense {
  cursor: pointer;
}
.capacity {
   display: flex;
   align-items: center;
   margin-left: 1rem;
   margin-left: auto;
}
.center{
  background-position: center;
  background-size: cover;
}

@media only screen and (max-device-width: 480px) {
  .lodging-header-desktop {
    display: none !important;
  }
  .vhd__datepicker__wrapper {
    background: none !important;
  }
}
.vhd__datepicker__wrapper {
    background: none !important;
  }
@media only screen and (min-device-width: 481px) {
  .container-lodging {
    margin-top: 15vh !important;
  }
  .lodging-header-container {
    display: none;
  }
}
.lodging-made-trip {
  text-align: center;
  border-color: #cddfd3;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
  height: fit-content;
  width: 200px;
  margin-top: 250px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  //left: 71%;
  max-width: 400px;
  width: 100%;

  @media (max-width: 480px) {
    left: 4%;
    margin-top: 1em;
    position: relative;
    max-width: 370px;
    width: 92%;
  }
  .image-container {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .image-container img {
    flex: 1;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
  }

  #navbar-main .nav-phone {
    background-color: transparent !important;
  }

  .head {
    display: flex;

    img {
      width: 32px;
      height: 32px;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.lodging-show-raleway-reg-p26-b{
  font-family: $Merriweather;
  font-weight: 400;
  font-size: 23px;
}
.__card__data {
  margin: 40px 0;
}

.__leave {
  border: 1px solid white;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
}

.__leave_image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.__border_item {
  border: 1px solid #435f53;
}

._title {
  font-weight: bold;
  font-size: 22px;
}

._sub_title {
  background: #435f53;
  color: white;
  display: inline-block;
  font: normal normal bold 16px/32px Raleway;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}

._sub_title_right {
  background: black;
  color: white;
  display: inline-block;
  font: normal normal bold 16px/32px Raleway;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}

.raleway-reg-p24-two {
  text-align: left;
  font: normal normal bold 16px/32px Raleway;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}

.check {
  gap: 10px;
}

.__bg {
  background: #DFEDE0;
}

.__btn {
  outline: none;
  border: 1px solid #435f53;
  color: #435f53;
  background: transparent;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
}

.__btn___secondary {
  background: #435f53;
  outline: none;
  border: none;
  padding: 10px 24px;
  text-transform: uppercase;
  text-align: center;
  font: normal normal bold 22px/32px Raleway;
  color: #FFFFFF;
  opacity: 1;
}

.__btn_text {
  color: #435f53;
}

.____link {
  display: block;
  color: black;
  text-decoration: underline;
}

.__card_flower {
  position: relative;
  border: 2px solid white;
  height: 100%;
}

.__card_flower:nth-child(2), .__card_flower:nth-child(3) {
  border-right: none;
}

@media (max-width: 767.98px) {
  .__card_flower .text-center {
    padding: 10px 0;
  }

  .check img {
    width: 50px;
  }
  .check {
    padding-left: 0 !important;
  }

  .__card_flower {
    height: auto;
    margin-bottom: 20px;
  }

  .__leave {
    height: 60px;
    width: 60px;
  }

  .__leave_image {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .__card_flower .text-center {
    padding: 15px 0;
  }

  .check img {
    width: 50px;
  }

  .__card_flower {
    height: auto;
  }

  .__leave {
    height: 70px;
    width: 70px;
  }

  .__leave_image {
    width: 70px;
    height: 70px;
  }
}

._raleway-reg-p13{
  font-family: $Raleway;
  font-weight: 400;
  font-size: 15px;
}
._raleway-reg-text{
  font-family: $Raleway;
  font-size: 12px;
}
.raleway-reg-p24-two {
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
}
.card_border {
  border-right: none;
}

/* Icon positioning to make sure it stays at the top */
.__leave {
  border: 1px solid white;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40px; /* Adjust this to make sure the icon stays at the correct height */
}

.mobile-main {
  padding-left: 1rem !important;
}

.__leave_image {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.mobile-bullett {
  padding-top: 10px !important;

}

/* Make the container scrollable horizontally */
@media (max-width: 480px) {
  .card-scroll-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding-top: 40px;
  }
  .card-item {
    flex: 0 0 auto;
    width: 90%; /* Set the card width as per requirement, change as necessary */
    max-width: 250px; /* Maximum width for larger screens */
    scroll-snap-align: start;
    margin-right: 15px; /* Adjust for spacing between cards */
  }

  .card_border {
    border-right: 1px solid white;
  }

  .__btn {
    outline: none;
    border: 1px solid #435f53;
    color: #435f53;
    background: transparent;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
  }
  .card-scroll-container > div {
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin-right: 15px; /* Adjust for spacing between cards */
  }
  .__card__data .row {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .__card_flower {
    flex: 0 0 auto;
    min-width: 80%;
    margin-right: 10px;
    scroll-snap-align: center;
  }

  .__leave {
    height: 60px;
    width: 60px;
  }

  .__leave_image {
    width: 60px;
    height: 60px;
  }

  .__btn {
    font-size: 13px;
    padding: 5px 10px;
  }
  .mobile-img {
    margin-top: 10px;
  }
  .mobile-drink {
    width: 100%;
  }
  .mobile-bullett {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;

  }
  .mobile-bul {
    width: 50px;
  }
  .mobile-ujd {
    margin-right: 10px;
    padding-top: 20px;
  }

  /* Ensure the description stays at the bottom and does not scroll */
  ._raleway-reg-text {
    position: static; /* Ensure it stays in place */
    padding-top: 20px;
  }
}
.input-wrapper {
  position: relative;
  display: inline-block;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.person-pad {
  padding-left: 40px;
}

</style>
