<template>
    <div v-if="!isPhone" style="margin-top: 11%; margin-left: 11%;" id="gift-card-form" class="col-md-6 col-info-form raleway-reg-p15">
      <div class="form-user-info p-5">
        <button
        class="btn-back"
        @click="goBack"
      >
      <img src="../../images/fleche gauche.svg" alt="Back Arrow" class="back-arrow-image" />
        Retour
      </button>
        <img
          class="img-fluid"
          id="o-hortense-carte-cadeau"
          src="../../images/CARTE_CADEAU.svg"
          alt=""
        />
        <p class="raleway-bold-p">À l'attention de :</p>
        <div class="line-form form-group">
          <input
            id="input-firstname-o"
            placeholder="Nom"
            class="form-control w-100 border-input"
            v-model="recieverInfo.first_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-lastname-o"
            placeholder="Prénom"
            class="form-control w-100 border-input"
            v-model="recieverInfo.last_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-email-o"
            placeholder="Email"
            class="form-control w-100 border-input"
            v-model="recieverInfo.email"
            type="text"
          />
        </div>
  
        <p class="raleway-bold-p">De la part de :</p>
        <div class="line-form form-group">
          <input
            id="input-firstname-s"
            placeholder="Nom"
            class="form-control w-100 border-input"
            v-model="senderInfo.first_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-lastname-s"
            placeholder="Prénom"
            class="form-control w-100 border-input"
            v-model="senderInfo.last_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-email-s"
            placeholder="Email"
            class="form-control w-100 border-input"
            v-model="senderInfo.email"
            type="text"
          />
        </div>
  
        <div class="line-form form-group">
          <p class="raleway-bold-p">Votre message :</p>
          <textarea
            class="w-100"
            cols="45"
            rows="6"
            v-model="messageText"
          ></textarea>
        </div>
  
        <div class="line-form form-group">
          <label for="price" class="raleway-bold-p">Montant :</label>
          <v-select
            id="price"
            class="select-item w-100-border-input"
            placeholder=""
            v-model="giftCardPrice"
            :options="priceList"
          ></v-select>
        </div>
  
        <!-- <p class="raleway-reg-p18">
          Disponible à partir de 50€, saisir un multiple de 50.
        </p> -->
  
        <div class="line-form form-group">
          <div class="term-and-condition">
            <input
              class="form-control"
              type="checkbox"
              id="term"
              value="term"
              v-model="term"
            />
            <label for="term">
              J'accepte
              <a href="/conditions-generales-utilisation" class="no-deco">
                les conditions générales d'utilisation
              </a>
            </label>
          </div>
        </div>
  
        <button class="hortense-btn-primary border-deb" @click="createGiftCard">
          OFFREZ CETTE CARTE CADEAU
        </button>
        <div class="mt-4">
            <img
          class="img-fluid"
          src="../../images/carte-cadeau-noel.png"
          alt=""
        />
        </div>
      </div>
    </div>
    <div v-else style="margin-top: 15%;" id="gift-card-form" class="raleway-reg-p15">
      <div class="form-user-info p-5">
        <button
        class="btn-back"
        @click="goBack"
      >
        Retour
      </button>
        <img
          class="img-fluid"
          id="o-hortense-carte-cadeauuu"
          src="../../images/CARTE_CADEAU.svg"
          alt=""
        />
        <p class="raleway-bold-p">À l'attention de :</p>
        <div class="line-form form-group">
          <input
            id="input-firstname-o"
            placeholder="Nom"
            class="form-control w-100 border-input"
            v-model="recieverInfo.first_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-lastname-o"
            placeholder="Prénom"
            class="form-control w-100 border-input"
            v-model="recieverInfo.last_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-email-o"
            placeholder="Email"
            class="form-control w-100 border-input"
            v-model="recieverInfo.email"
            type="text"
          />
        </div>
  
        <p class="raleway-bold-p">De la part de :</p>
        <div class="line-form form-group">
          <input
            id="input-firstname-s"
            placeholder="Nom"
            class="form-control w-100 border-input"
            v-model="senderInfo.first_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-lastname-s"
            placeholder="Prénom"
            class="form-control w-100 border-input"
            v-model="senderInfo.last_name"
            type="text"
          />
        </div>
        <div class="line-form form-group">
          <input
            id="input-email-s"
            placeholder="Email"
            class="form-control w-100 border-input"
            v-model="senderInfo.email"
            type="text"
          />
        </div>
  
        <div class="line-form form-group">
          <p class="raleway-bold-p">Votre message :</p>
          <textarea
            class="w-100"
            cols="45"
            rows="6"
            v-model="messageText"
          ></textarea>
        </div>
  
        <div class="line-form form-group">
          <label for="price" class="raleway-bold-p">Montant :</label>
          <v-select
            id="price"
            class="select-item w-100-border-input"
            placeholder=""
            v-model="giftCardPrice"
            :options="priceList"
          ></v-select>
        </div>
  
        <!-- <p class="raleway-reg-p18">
          Disponible à partir de 50€, saisir un multiple de 50.
        </p> -->
  
        <div class="line-form form-group">
          <div class="term-and-condition">
            <input
              class="form-control"
              type="checkbox"
              id="term"
              value="term"
              v-model="term"
            />
            <label for="term">
              J'accepte
              <a href="/conditions-generales-utilisation" class="no-deco">
                les conditions générales d'utilisation
              </a>
            </label>
          </div>
        </div>
  
        <button class="hortense-btn-primary border-deb" @click="createGiftCard">
          OFFREZ CETTE CARTE CADEAU
        </button>
        <div class="mt-4">
            <img
          class="img-fluid"
          src="../../images/carte-cadeau-noel.png"
          alt=""
        />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import fetcher from "packs/shared/api.js";
  
  export default {
    name: "achat",
    data() {
      return {
        senderInfo: {
          first_name: null,
          last_name: null,
          email: null,
        },
        recieverInfo: {
          first_name: null,
          last_name: null,
          email: null,
        },
        messageText: null,
        giftCardPrice: 100,
        priceList: [],
        term: false,
      };
    },
    methods: {
      async createGiftCard() {
        try {
          const response = await fetcher.post("/carte-cadeau/create", {
            giftCard: {
              price: this.giftCardPrice,
              senderFirstName: this.senderInfo.first_name,
              senderLastName: this.senderInfo.last_name,
              senderEmail: this.senderInfo.email,
              recieverFirstName: this.recieverInfo.first_name,
              recieverLastName: this.recieverInfo.last_name,
              recieverEmail: this.recieverInfo.email,
            },
            message: this.messageText,
          });
          Turbolinks.visit("/carte-cadeau/paiement/"+ response.data.sender_user_json_response.id +"/"+ response.data.receiver_user_json_response.id +"/"+ response.data.gift_card_json_response.id);
        } catch (error) {
          console.error("Erreur lors de la création de la carte cadeau:", error);
        }
      },
      goBack() {
        window.history.back();
    },
    },
    mounted() {
      for (let i = 100; i <= 500; i += 50) {
        this.priceList.push(i);
      }
    },
    computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
    }
  };
  </script>
  
  <style scoped>
  #o-hortense-carte-cadeau {
    display: flex;
    position: absolute;
    float: right;
    right: 0;
    margin-top: -14%;
    margin-right: -7%;
    z-index: 100;
    width: 150px;
  }

  #o-hortense-carte-cadeauuu {
    display: flex;
    position: absolute;
    float: right;
    right: 0;
    margin-top: -14%;
    margin-right: 3%;
    z-index: 100;
    width: 150px;
  }
  
  .form-user-info {
    border: 2px solid grey;
    padding: 10px;
  }
  
  .border-input {
    border: none;
    border-bottom: 1px solid #ccc;
  }
  
  .hortense-btn-primary {
    max-height: 25px;
    font-size: 11px;
  }
  .btn-back {
  background-color: transparent;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
}

.back-arrow-image {
  width: 24px;
  height: 24px;
}

.btn-back:hover {
  background-color: #ffff;
}
  </style>
  