<template>
  <div id="b-phone">
    <video
      playsinline
      autoplay
      loop
      muted
      poster="../images/hortense-green.jpg"
      class="home_video"
      @canplay="handleVideoPlay"
    >
      <source src="https://hortense-vue.s3.eu-west-3.amazonaws.com/COVER+VERTICALE.mov">
    </video>
    <div class="b-phone-container">
      <div class="b-phone-header">
        <h3 class="b-title merriweather-reg-30">{{ $t("Banner.header") }}</h3>
        <p class="b-content raleway-reg-p15">{{ $t("Banner.subtitle") }}</p>
      </div>
      <div>
        <MainSearchMobile></MainSearchMobile>
      </div>
    </div>
  </div>
</template>



<script>
import MainSearchMobile from "./btns/MainSearchMobile.vue";

export default {
  name: "b-phone",
  data() {
    return {
      isVideoPlaying: false, // Tracks if the video has started playing
    };
  },
  methods: {
    handleVideoPlay() {
      const video = this.$el.querySelector(".home_video");
      video.style.opacity = "1"; // Show video once it's ready
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        window.open(
          `/reservation-hotel?geo=${position.coords.latitude},${position.coords.longitude}`,
          "_blank"
        );
      });
    },
    animLogo(e) {
      e.currentTarget.animate(
        [
          { transform: "translateY(0px)" },
          { transform: "translateY(-10px)" },
          { transform: "translateY(0px)" },
        ],
        {
          duration: 800,
          easing: "ease-in-out",
          iterations: 2,
        }
      );
    },
  },
  components: { MainSearchMobile },
};
</script>

<style lang="scss" scope='app/assets/stylesheets/components/banner-phone'>
</style>
