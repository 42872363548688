<template>
	<div id="multiselectbudget">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h3 class="raleway-semi-p13">Budget</h3>
				<img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
				<img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
			<Sliderbudget @changedBudget="changedBudget"></Sliderbudget>
		</div>
	</div>
</template>

<script>
	import Sliderbudget from 'pages/destinations/Sliderbudget';

	export default {
		name: 'multiselectbudget',
		components: { Sliderbudget },
		props: ['options'],
		data() {
			return {
				show: false,
				selected: [],
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
			changedBudget(value) {
				this.$emit('changedBudget', value);
			},
		},
		// watch: {
		//   selected(val) {
		//     this.$emit('checked', val)
		//   }
		// }
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
