<template>
  <div id="fidHome">
    <div class="container-label">
      <div class="row row-fid">
        <div class="col-5 fid-content">
          <div class="header-fid">
            <h3 class="merriweather-reg-26"> {{ $t("Fid.head") }} </h3>
            <h3 class="merriweather-reg-40 mb-5" >{{ $t("Fid.title") }}</h3>
          </div>
            <p class="raleway-reg-p15 mb-5">{{ $t("Fid.content") }}</p>
            <button v-if="this.privilegeRegistration" @click="createPrivilege" class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
            <p v-else-if="this.privilegeRegistration === false" class="raleway-reg-p15 mb-4">Vous êtes inscrit</p>
            <button v-else @click='$emit("connection")'class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
            <a :href="'/programme-fidélité-hortensia/'" class="link-hortense">{{ $t("Fid.link") }}</a>
        </div>
        <div class="col-7 fid-badges" data-aos="fade-up" data-aos-duration="1000">
          <img src="../images/badge-guest.png" class="guest" alt="badge guest">
          <img src="../images/badge-nomad@2x.png" alt="badge nomad" class="nomad">
          <img src="../images/badge-trotter.png" alt="badge trotter" class="trotter">
        </div>
      </div>

      <!-- div class=" row-comments">
        <p class="raleway-reg-p24">{{ $t("Testimony.title") }}</p>
      </div>
      <CardTestimony></CardTestimony>
      <CardTestimony></CardTestimony>
      <div class="d-flex justify-content-center mt-5">
        <a href="#" class="link-hortense"> voir plus <img src="../images/flechebas-simple.svg" alt="fleche bas"></a>
      </div> -->
      <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
    </div>
  </div>
</template>


<script>

  import fetcher from 'packs/shared/api.js';
  import CardTestimony from 'components/cards/CardTestimony.vue';
  import Flash from 'alert/Flash.vue';
  import AOS from 'aos';

  export default {
    name: 'fidHome',
    props: [ 'user' ],
    components : { Flash, CardTestimony },
    data() {
      return {
        status: null,
        text: null,
        privilegeRegistration: null,
      }
    },
    methods: {
      async createPrivilege(){
        try {
          const response = await fetcher.post('/privileges');
          this.text = "Inscription au prpgramme de fidélité";
          this.toggleStatus('success');
        }catch(e){
          this.toggleStatus('alert');
          this.text = 'Veuillez réessayer';
        }
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
      privilegeCondition(){
        return this.privilegeRegistration = this.user && this.user.privilege_id === null
      },
    },
    mounted() {
      this.privilegeCondition();
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/fid'>

</style>
