import { render, staticRenderFns } from "./Arte.vue?vue&type=template&id=68cb8928&scoped=true&"
import script from "./Arte.vue?vue&type=script&lang=js&"
export * from "./Arte.vue?vue&type=script&lang=js&"
import style0 from "./Arte.vue?vue&type=style&index=0&id=68cb8928&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cb8928",
  null
  
)

export default component.exports