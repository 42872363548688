<template>
  <div id="map-lodging-show">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <mapbox
                access-token="pk.eyJ1IjoiaG9ydGVuc2VncmVlbiIsImEiOiJja3N1Nm9iNGEwNjUyMm9xY2RlMDlwYWsxIn0.AMGi9L_80RYWk4sB5JYU5w"
                :map-options="{
                style: 'mapbox://styles/mapbox/streets-v10',
                zoom: 15,
                center: this.markers[0],
              }"
                @map-init="displayMarkers"
            />
          </div>
        </div>
        <div class="closing">
          <button class="close-btn-modal" @click="$emit('close')"></button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  name: 'map-lodging-show',
  props: ['markers'],
  components: {Mapbox},
  data() {
    return {}
  },
  methods: {
    displayMarkers(map) {
      for (let i = 0; i < this.markers.length; i++) {
        let el = document.createElement('div');
        el.id = i
        el.addEventListener('click', (e) => {
          this.$emit('markerSelected', el.id);
          document.querySelectorAll('.marker').forEach(marker => {
            if (marker.id != el.id) {
              marker.style.display === 'none' ? marker.style.display = 'block' : marker.style.display = 'none';
            }
          })
        })
        el.className = 'marker';
        new mapboxgl.Marker(el).setLngLat(this.markers[i]).addTo(map);
      }
      map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
          })
      );
    },
  },
}
</script>
<style lang="scss" scope='app/assets/stylesheets/components/map'>
.modal-container > #map > .mapboxgl-canvas-container > div {
  filter: invert(66%) sepia(71%) saturate(257%) hue-rotate(1deg) brightness(97%) contrast(83%);
}

.modal-container > #map > .mapboxgl-canvas-container :nth-child(2) {
  filter: none;
  z-index: 100;
}

@media (max-width: 480px) {
#map-lodging-show #map {
  height: 80vh;
}
}

</style>