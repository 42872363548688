<template>
  <div id="lodginghome-phone">
    <div class="phone-container">
      <div class="lodging-header">
        <h1 class="title-first merriweather-reg-24">{{ $t("Propositions.title1") }}</h1>
        <h1 class="title-second merriweather-reg-40">{{ $t("Propositions.title2") }}</h1>
        <p class="raleway-reg-p16">
          Hortense a déniché pour vous les plus beaux hébergements éco-responsables.<br>
          Des lieux authentiques et pleins de charme soigneusement sélectionnés pour leur engagement environnemental.<br>
          Une Green Note minimale de 5/10 est nécessaire pour intégrer notre collection.<br>
          Séjourner avec Hortense, c’est l’assurance d’un lieu d'exception respectueux de l’environnement.<br>
          Chaque réservation contribue à promouvoir le tourisme durable.
        </p>
      </div>
      <!--<slither-slider :options="{ numberOfSlides: 1, dots: false, controls: false, autoplay: false}">-->
      <div id="myscroller" class="card-lodging-container">
            <div class="card-hub">
              <div class="position-relative">
                <img src="../images/proposition/proposition-1.jpeg" class="prop-img" alt="1">
              </div>
              <div class="card-hub-info">
                <p class="element raleway-bold-p">{{ $t("Propositions.content.first") }}</p>
                <a href="/reservation-hotel?search=" class="anchor">{{ $t("Propositions.anchor.first") }}</a>
              </div>
            </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="../images/proposition/proposition-2.png" class="prop-img" alt="2">
              </div>
              <div class="card-hub-info">
                <p class="element raleway-bold-p">{{ $t("Propositions.content.second") }}</p>
                <a href="/programme-fidélité-hortensia" class="anchor">{{ $t("Propositions.anchor.second") }}</a>
              </div>
            </div>
        <!-- <div class="card-hub">
          <div class="position-relative">
            <img src="../images/proposition/proposition-7.png" class="prop-img" alt="3">
          </div>
          <div class="card-hub-info">
            <p class="element raleway-bold-p">{{ $t("Propositions.content.seven") }}</p>
            <a href="/club-hortense" class="anchor">{{ $t("Propositions.anchor.seven") }}</a>
          </div>
        </div> -->
<!--        <div class="card-hub">-->
<!--          <div class="position-relative">-->
<!--            <img src="../images/proposition/sixith.jpeg" class="prop-img" alt="3">-->
<!--          </div>-->
<!--          <div class="card-hub-info">-->
<!--            <p class="element raleway-bold-p">Des journées et séjours bien-être</p>-->
<!--            <a href="/experiences-bien-etre" class="anchor">Découvrez nos expériences bien-être</a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="card-hub">
          <div class="position-relative">
            <img src="../images/proposition/nos-img.jpg" class="prop-img" alt="3">
          </div>
          <div class="card-hub-info">
            <p class="element raleway-bold-p">Des nouveautés tous les mois dans notre sélection</p>
            <a href="/les-nouveautes-hortense" class="anchor">Réservez votre prochain séjour</a>
          </div>
        </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="../images/proposition/proposition-5.jpg" class="prop-img" alt="4">
              </div>
              <div class="card-hub-info">
                <p class="element raleway-bold-p">{{ $t("Propositions.content.fifth") }}</p>
                <a href="/voyage-sur-mesure" class="anchor">{{ $t("Propositions.anchor.fifth") }}</a>
              </div>
            </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="../images/proposition/proposition-4.jpg" class="prop-img" alt="4">
              </div>
              <div class="card-hub-info">
                <!--<p class="element raleway-bold-p">{{ $t("Propositions.content.fourth") }}</p>-->
                <p class="element raleway-bold-p">DONS AU PROFIT DE <br>L’ENVIRONNEMENT</p>
                <a href="/programme-evergreen-demarche-environnementale" class="anchor">{{ $t("Propositions.anchor.fourth") }}</a>
              </div>
            </div>
         </div>
      <!--</slither-slider>-->
    </div>
  </div>
</template>

<script>

// import { Splide, SplideSlide } from '@splidejs/vue-splide';


  export default {
    name: 'p-phone',
    // components:{ Splide, SplideSlide },
    data() {
      return {
        options: {
          arrows: 0,
          rewind : true,
          width  : '100%',
          height: 304,
          padding: {
            right: '7rem',
          },
          perMove:1,
          gap    : '1rem',
          classes:{
            pagination: 'splide__pagination  pagi-phone', // container
            page : 'splide__pagination__page dot-phone', // each button
          }
        },
      };
    },
    methods: {
      autoScroll() {
        const scroller =document.getElementById('myscroller'); // Use a ref to get the container element
        let maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;

        const step = 1; // Adjust step for speed
        const frame = () => {
          if (scroller.scrollLeft >= maxScrollLeft) {
            scroller.scrollLeft = 0; // Reset scroll to start when it reaches the end
          } else {
            scroller.scrollLeft += step;
          }
          requestAnimationFrame(frame);
        };
        frame();
      }
    },
    mounted() {
      this.autoScroll(); // Start automatic scrolling when the component is mounted
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/tips-phone'>
.anchor{
        text-decoration: underline;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
}
.prop-img{
  height:182px;
  //width:120px;
  width: 260px;
}
.element{
    //width: 120px;
    width: 100%;
}
</style>

