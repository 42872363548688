var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"evergreenHome"}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"row row-hubs",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_c('div',{staticClass:"col-6 evergreen-col"},[_c('div',{staticClass:"evergreen-header"},[_c('h3',{staticClass:"merriweather-reg-26"},[_vm._v(" "+_vm._s(_vm.$t("Evergreen.program"))+" ")]),_vm._v(" "),_c('p',{staticClass:"raleway-reg-p16"},[_vm._v(_vm._s(_vm.$t("Evergreen.content")))])]),_vm._v(" "),_c('a',{staticClass:"hortense-btn-primary btn-evergreen raleway-bold-p mt-5",attrs:{"href":'/programme-evergreen-demarche-environnementale/'}},[_vm._v(_vm._s(_vm.$t("Evergreen.viewbtn")))])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"cards"},[_c('slither-slider',{staticClass:"slither-slider",attrs:{"options":{ numberOfSlides: 3,
                                                          autoplay: true,
                                                          controls: true,
                                                          extras: 3,
                                                          loop: true,
                                                          endless: true,
                                                          touch:true,
                                                          secondsOnSlide: 2}}},[_vm._l((this.projectsMounted),function(project){return [_c('div',{attrs:{"id":"cardEvergreen"}},[_c('div',{staticClass:"card-ever"},[_c('img',{attrs:{"src":project.photo_key,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"sticker-program d-flex mt-4 pl-2"},[_c('div',{staticClass:"sticker-program-info d-flex"},[_c('p',{staticClass:"raleway-bold-h3 mb-0 pl-1 pr-2"},[_vm._v(_vm._s(project.title))]),_vm._v(" "),_c('p',{staticClass:"raleway-reg-p13 mb-0 pl-1 pr-2"},[_vm._v(_vm._s(project.goal))])])])])])]}),_vm._v(" "),_c('template',{slot:"previous"},[_c('div',{staticClass:"previous"},[_c('img',{attrs:{"src":require("../images/fleche gauche.svg"),"alt":"fleche gauche"}}),_vm._v(" "),_c('p',{staticClass:"raleway-reg-p13 m-0"},[_vm._v("PLUS")])])]),_vm._v(" "),_c('template',{slot:"next"},[_c('img',{attrs:{"src":require("../images/fleche droite.svg"),"alt":"fleche droite"}})])],2)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-4 offset-6 planete-col"},[_c('img',{attrs:{"src":"https://hortense-vue.s3.eu-west-3.amazonaws.com/static/planete2.png","width":"800px","height":"800px","alt":"planète"}})])}]

export { render, staticRenderFns }