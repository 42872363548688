// This file is automatically compiled by Webpa

// Import I18n
// ck, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import NewsLetterPage from "../pages/dashboard/NewsLetterPage.vue";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
import vSelect from "vue-select";
import VueI18n from "vue-i18n";
import SlitherSlider from "slither-slider";
import VueTreeNavigation from "vue-tree-navigation";
import VueSlideoutPanel from "vue2-slideout-panel";
import VueSlider from "vue-slider-component";
import shareIt from "vue-share-it";
import VueGallerySlideshow from "vue-gallery-slideshow";
import HotelDatePicker from "vue-hotel-datepicker";
import VueLazyload from "vue-lazyload";

// Internal imports
// import preLoader from './shared/preloader.js'
import Home from "../pages/Home.vue";
import Homephone from "../pages/Homephone.vue";
import Preloader from "../pages/Preloader.vue";
import HomeTablet from "../pages/HomeTablet.vue";
import ReaPhone from "../components/ReaPhone.vue";
import Registrationuser from "../pages/dashboard/Registrationuser.vue";
import Comments from "../pages/dashboard/Comments.vue";
import BecomeHost from "../pages/dashboard/CardBecomeHost.vue";
import MyPrivilege from "../pages/dashboard/MyPrivilege.vue";
import Myreservation from "../pages/dashboard/Myreservation.vue";
import Wishlistuser from "../pages/dashboard/Wishlistuser.vue";
import NewsLetter from "../pages/dashboard/NewsLetter.vue";
import DesireList from "../pages/dashboard/DesireList.vue";
import Collections from "../pages/collections/Collections.vue";
import Exclusives from "../pages/exclusives/Exclusives.vue";
import ExclusiveHomePhone from "../components/exclusive/ExclusiveHomePhone.vue";
import ResetPassword from "../pages/users/ResetPassword";
import OtpVerification from "../pages/users/OtpVerification.vue";
import newsLetterPage from "../pages/dashboard/NewsLetterPage.vue";
// import Showcollection from '../pages/collections/Showcollection.vue';
import Galeries from "../pages/galeries/Galeries.vue";
import Destinations from "../components/destinations/Destinations.vue";
import Caraibes from "../components/destinations/Caraibes.vue";
import Indians from "../components/destinations/Indians.vue";
import Africas from "../components/destinations/Africas.vue";
import Polynesias from "../components/destinations/Polynesias.vue";
import Europe from "../components/destinations/Europe.vue";
import Americas from "../components/destinations/Americas.vue";
import Asia from "../components/destinations/Asia.vue";
import Hortense from "../components/Hortense.vue";
import Reviewlist from "../components/Reviewlist.vue";
import Green from "../components/green-note/Green.vue";
import Greenhost from "../components/green-note/Greenhost.vue";
import Evergreen from "../components/green-note/Evergreen.vue";
import Hortensia from "../components/Hortensia.vue";
import Hortensebuisness from "../components/Hortensebuisness.vue";
import Wedding from "../components/Wedding.vue";
import Inspirations from "../pages/inspirations/Inspirations.vue";
import InspirationsShow from "../pages/inspirations/InspirationsShow.vue";
import Experiences from "../components/experiences/Experiences.vue";
// import Lodgingsbydestination from '../pages/destinations/LodgingsByDestination.vue';
// import Lodgingsbycountry from '../pages/destinations/Lodgingsbycountry.vue';
import Lodgings from "../pages/lodgings/Lodgings.vue";
import Lodgingshiver from "../pages/lodgings/Lodgingshiverr.vue"
import Exclusivelodgings from "../pages/lodgings/Exclusivelodgings.vue";
import Lodgingshow from "../pages/lodgings/Lodgingshow.vue";
import Weekendshow from "../components/weekends/Weekendshow.vue";
import Weekendall from "../components/weekends/Weekendall.vue";
import Navbar from "../components/shared/Navbar.vue";
import NavbarWhite from "../components/shared/NavbarWhite.vue";
import Navphone from "../components/shared/Navphone.vue";
import NavphoneBlack from "../components/shared/NavphoneBlack.vue";
import InboxUser from "../pages/inbox/InboxUser.vue";
import MessagesListUser from "../pages/inbox/MessagesListUser.vue";
import MessagesShowUser from "../pages/inbox/MessagesShowUser.vue";
import NewReservation from "../pages/reservations/NewReservation";
import NewReservationConfirm from "../pages/reservations/NewReservationConfirm.vue";
import ReservationConfirmation from "../pages/reservations/ReservationConfirmation";
import LoginForm from "../pages/auth/LoginForm";
import SignUpForm from "../pages/auth/SignUpForm";
import Faq from "../components/faqs/Faq.vue";
import Ethic from "../components/static_pages/Ethic.vue";
import Privacypolicy from "../components/static_pages/Privacypolicy.vue";
import Cgv from "../components/static_pages/Cgv.vue";
import Cgu from "../components/static_pages/Cgu.vue";
import Legals from "../components/static_pages/Legals.vue";
import Madetrip from "../components/static_pages/Madetrip.vue";
import Contactform from "../components/Contactform.vue";
import Reassurance from "../components/Reassurance.vue";
import VFooter from "../components/shared/Footer.vue";
import Footerphone from "../components/shared/Footerphone.vue";
import ChatRoom from "../components/chatroom/ChatRoom.vue";
import ExperienceShow from "../pages/experiences/ExperienceShow";
import GiftCard from "../pages/giftcards/Giftcard.vue";
import Achat from "../pages/giftcards/Achat.vue";
import Arte from "../pages/giftcards/Arte.vue"
import GiftCardPaymentProcess from "../pages/giftcards/GiftCardPaymentProcess.vue";
import Yogas from "../pages/yogas/Yogas.vue";
import Yogashow from "../pages/yogas/Yogashow.vue";
import PaymentSuccess from "../pages/yogas/PaymentSuccess.vue";
import Workation from "../pages/Workation.vue";
// Import i18n
import messages from "./locales/shared/navbar.js";
import Clubs from "../pages/clubs/Clubs.vue";
import Clubshow from "../pages/clubs/Clubshow.vue";
import Experience from "../pages/clubs/Experience.vue";
// Import scss and css external librairies
import "vue-select/dist/vue-select.css";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";
import "aos/dist/aos.css";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";

// Vue Utils
Vue.use(VueI18n);
Vue.use(TurbolinksAdapter);
Vue.use(SlitherSlider);
Vue.use(VueTreeNavigation);
Vue.use(VueSlideoutPanel);
Vue.use(shareIt);
Vue.use(VueGallerySlideshow);
Vue.use(VueLazyload);

// Vue Components
Vue.component("Africas", Africas);
Vue.component("Caraibes", Caraibes);
Vue.component("ReaPhone", ReaPhone);
Vue.component("Cgv", Cgv);
Vue.component("Cgu", Cgu);
Vue.component("ChatRoom", ChatRoom);
Vue.component("Collections", Collections);
Vue.component("Exclusives", Exclusives);
//Vue.component("ExclusiveAllPhone", ExclusiveAllPhone);
Vue.component("ExclusiveHomePhone", ExclusiveHomePhone);
Vue.component("Comments", Comments);
Vue.component("Contactform", Contactform);
Vue.component("Destinations", Destinations);
Vue.component("Ethic", Ethic);
Vue.component("Evergreen", Evergreen);
Vue.component("Experiences", Experiences);
Vue.component("ExperienceShow", ExperienceShow);
Vue.component("Faq", Faq);
Vue.component("Footerphone", Footerphone);
Vue.component("Galeries", Galeries);
Vue.component("Green", Green);
Vue.component("Greenhost", Greenhost);
Vue.component("Home", Home);
Vue.component("Homephone", Homephone);
Vue.component("Preloader", Preloader);
Vue.component("HomeTablet", HomeTablet);
Vue.component("Hortense", Hortense);
Vue.component("Hortensebuisness", Hortensebuisness);
Vue.component("Wedding", Wedding);
Vue.component("Hortensia", Hortensia);
Vue.component("Indians", Indians);
Vue.component("Inspirations", Inspirations);
Vue.component("inspirationsshow", InspirationsShow);
Vue.component("Legals", Legals);
Vue.component("Madetrip", Madetrip);
// Vue.component('Lodgingsbydestination', Lodgingsbydestination);
// Vue.component('Lodgingsbycountry', Lodgingsbycountry);
Vue.component("Lodgings", Lodgings);
Vue.component("Lodgingshiver", Lodgingshiver)
Vue.component("Exclusivelodgings", Exclusivelodgings);
Vue.component("Lodgingshow", Lodgingshow);
Vue.component("MyPrivilege", MyPrivilege);
Vue.component("Myreservation", Myreservation);
Vue.component("NewReservation", NewReservation);
Vue.component("NewReservationConfirm", NewReservationConfirm);
Vue.component("ReservationConfirmation", ReservationConfirmation);
//Vue.component('GiftCard', GiftCard)
Vue.component('giftcardpaymentprocess', GiftCardPaymentProcess)
Vue.component('LoginForm', LoginForm)
Vue.component('SignUpForm', SignUpForm)
Vue.component("Polynesias", Polynesias);
Vue.component("Europe", Europe);
Vue.component("Americas", Americas);
Vue.component("Asia", Asia);
Vue.component("Privacypolicy", Privacypolicy);
Vue.component("Reassurance", Reassurance);
Vue.component("Registrationuser", Registrationuser);
// Vue.component('Showcollection', Showcollection);
Vue.component("Reviewlist", Reviewlist);
Vue.component("Navphone", Navphone);
Vue.component("NavphoneBlack", NavphoneBlack);
Vue.component("NavbarWhite", NavbarWhite);
Vue.component("InboxUser", InboxUser);
Vue.component("MessagesListUser", MessagesListUser);
Vue.component("MessagesShowUser", MessagesShowUser);
Vue.component("VueSlider", VueSlider);
Vue.component("Weekendall", Weekendall);
Vue.component("Weekendshow", Weekendshow);
Vue.component("Wishlistuser", Wishlistuser);
Vue.component("NewsLetter", NewsLetter);
Vue.component("newsletterPage", NewsLetterPage);
Vue.component("DesireList", DesireList);
Vue.component("ResetPassword", ResetPassword);
Vue.component("OtpVerification", OtpVerification)
Vue.component("giftcard", GiftCard);
Vue.component("achat", Achat);
Vue.component("arte", Arte);
Vue.component("become-host", BecomeHost);
Vue.component("Yogas", Yogas);
Vue.component("Yogashow", Yogashow);
Vue.component("Clubs", Clubs);
Vue.component("PaymentSuccess", PaymentSuccess);
Vue.component("Workation", Workation)
// Vue External Components
Vue.component("v-select", vSelect);
Vue.component("HotelDatePicker", HotelDatePicker);
Vue.component("Clubshow", Clubshow)
Vue.component("Experience", Experience)
// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
document.addEventListener('turbolinks:click', (event) => {
	const anchor = event.target.closest('a');
	if (anchor && anchor.getAttribute('href')) {
	  // Get the target URL
	  const targetUrl = anchor.getAttribute('href');
  
	  // Update the address bar
	  history.pushState(null, '', targetUrl);
  
	  // Continue with Turbolinks navigation
	  Turbolinks.visit(targetUrl);
	 
	  // Prevent the default anchor tag navigation
	  event.preventDefault();
	}
});

document.addEventListener("turbolinks:load", () => {
	const i18n = new VueI18n({
		locale: "fr",
		messages: messages,
	});
	const app = new Vue({
		i18n,
		el: '[data-behavior="vue"]',
		components: {
			Home,
			Homephone,
			HomeTablet,
			ReaPhone,
			// Lodgingsbydestination,
			// Lodgingsbycountry,
			Lodgings,
			Lodgingshiver,
			Exclusivelodgings,
			Lodgingshow,
			Weekendshow,
			Weekendall,
			Registrationuser,
			Comments,
			MyPrivilege,
			Wishlistuser,
			NewsLetter,
			DesireList,
			NewsLetterPage,
			Myreservation,
			Collections,
			Exclusives,
			ExclusiveHomePhone,
			// Showcollection,
			Destinations,
			Caraibes,
			Indians,
			Africas,
			Polynesias,
			Europe,
			Asia,
			Americas,
			Galeries,
			Hortense,
			Reviewlist,
			Green,
			Greenhost,
			Evergreen,
			Hortensia,
			Hortensebuisness,
			Wedding,
			Inspirations,
			Experiences,
			InboxUser,
			MessagesShowUser,
			MessagesListUser,
			vSelect,
			Legals,
			Privacypolicy,
			Ethic,
			Cgv,
			Cgu,
			Madetrip,
			Navbar,
			NavbarWhite,
			Navphone,
			NavphoneBlack,
			VFooter,
			Footerphone,
			Faq,
			ChatRoom,
			Contactform,
			Reassurance,
			GiftCard,
			Achat,
			Arte,
			GiftCardPaymentProcess,
			BecomeHost,
			Yogas,
			Yogashow,
			PaymentSuccess,
			Workation,
			Clubs
		},
	});
});
