<template>
  <div id="multiselecthobbies" class="mt2">
    <div class="dropdown dropdown-filter" @click="showDropdown">
      <div class="overselect"></div>
      <div class="c-form-input">
        <h3 class="raleway-semi-p13">Loisirs</h3>
        <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show">
        <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else >
      </div>
    </div>
    <div class="multiselect" v-if="show">
      <ul>
        <div class="row row-selectoptions mt-3">
          <div class="col-6">
            <li v-for="(option, index) in options" :key="index">
              <input type="checkbox" :id="index" :value="option.value" v-model="selected">
              <label class="raleway-reg-p13 ml-2 " :for="index">Accès ski à pied</label>
            </li>
            <li v-for="(option, index) in options" :key="index">
              <input type="checkbox" :id="index" :value="option.value" v-model="selected">
              <label class="raleway-reg-p13 ml-2" :for="index">Golf</label>
            </li>
            <li v-for="(option, index) in options" :key="index">
              <input type="checkbox" :id="index" :value="option.value" v-model="selected">
              <label class="raleway-reg-p13 ml-2" :for="index">Tennis</label>
            </li>
            <li v-for="(option, index) in options" :key="index">
              <input type="checkbox" :id="index" :value="option.value" v-model="selected">
              <label class="raleway-reg-p13 ml-2" :for="index">Location de vélo</label>
            </li>
            <li v-for="(option, index) in options" :key="index">
              <input type="checkbox" :id="index" :value="option.value" v-model="selected">
              <label class="raleway-reg-p13 ml-2" :for="index">Casino</label>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>


  export default{
   name: 'multiselecthobbies',
   props: ['options'],
    data() {
      return {
        show: false,
        selected: []
      }
    },
    methods: {
      showDropdown() {
        this.show = !this.show
      }
    },
    // watch: {
    //   selected(val) {
    //     this.$emit('checked', val)
    //   }
    // }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/lodging-filter'>


</style>
