<template>
  <div id="collections">
    <Searchbtn
      @show="showBanner = !showBanner"
      @top="redirectToTop"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <div class="container-index"  >
      <div class="index-text">
        <h1 class="merriweather-reg-40 mb-5">Nos collections</h1>
        <p class="raleway-reg-p15 text-center">
          Découvrez en exclusivité nos collections : une sélection thématique
          d’hôtels de charme et d’hébergements exceptionnels que vous ne
          trouverez nulle part ailleurs. City Break, bord de mer, montagne, spa,
          escapade romantique … Dénichez parmi notre sélection inédite les
          meilleurs solutions de tourisme écoresponsable et haut de gamme.
        </p>
      </div>
      <!--  <div class="gallery gallery-index">
        <figure v-for='(collection, index) in this.collectionsMounted' :class="'gallery__item gallery__item--' + (collection.id)" :key='index' data-aos="fade-up" data-aos-duration="900">
          <img v-if="collection.photo_key" :src="collection.photo_key" class="gallery__img" :alt="collection.name">
          <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="gallery__img" :alt="collection.name">
          <div class="card-content">
            <h3 class="mt-4 mb-4">{{ collection.name }}</h3>
            <h2 class="mb-2">{{ collection.title }}</h2>
            <p class="mb-2">{{ collection.description_collection_page }}</p>
            <a :href="'/collections/' + collection.slug" class="mb-3">Découvrir la collection</a>
          </div>
        </figure>
      </div> -->
      <div class="row row-weekend">
        <div
          v-for="(collection, index) in this.collectionsMounted"
          :key="index"
          class="col-lg-6 col-xs-10 col-weekend"
        >
          <CardColl
            :collection="collection"
          ></CardColl>
        </div>
      </div>
      <ScrollUp></ScrollUp>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import ScrollUp from "components/btns/ScrollUp.vue";
import CardColl from "pages/collections/CardColl.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "components/btns/Searchbtn.vue";
import Searchbanner from "components/btns/Searchbanner.vue";

export default {
  name: "collections",
  props: ["collections"],
  components: { ScrollUp, CardColl, Searchbtn, Searchbanner },
  data() {
    return {
      showBanner: false,
      collectionsMounted: null,
    };
  },
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    async fetchAllCollections() {
      try {
        const response = await fetcher.get("/collections.json");
        this.collectionsMounted = response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    AOS.init({
      duration: 1200,
      once: true,
    });
    this.fetchAllCollections();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'view_item_list',
      item_list_name: 'collection_lodgings_list',
      item_list_id: 'collection_lodgings'
    });
  },
};
</script>

<style
  lang="scss"
  scope="app/assets/stylesheets/components/collection-gallery"
></style>
