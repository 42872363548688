<template>
  <div id="new-reservation">
    <Modalsignup v-if="showModal" :showModalLogin="showModalLogin" @close="setUser"></Modalsignup>
    <ModalEvergreen v-if="showModalEvergreen" @close="showModalEvergreen = false"></ModalEvergreen>
    <template v-if="isChoosing">
      <Searchbanner
        v-if="showBanner"
        ref="searchBanner"
        :lodging="lodging"
        :searched="searched"
        :expandCalendar="expandCalendar"
        @updateExpandCalendar="updateExpandCalendar"
        @childsNum="childsNum"
        @childsAge="childsAge"
        @numberOfChild="updateChilds"
        @adultsNum="adultsNum"
        @numberOfAdult="updateAdults"
        @selectDates="updateDates"
        @formSubmit="checkIfAvailable"
        @numberOfRoom="updateRooms"
        @close="showBanner = false"
      ></Searchbanner>
      <div v-if="this.lodging" class="lodging-info">
        <!--<a href="javascript:history.go(-1)" class="no-deco mb-5 ml-4" style="margin-right:auto"> < Retour </a>-->
        <a :href="'/reservation-hotel/'+this.lodging.slug" class="no-deco mb-5 ml-4" style="margin-right:auto"> < Retour </a>
        <h1 class="merriweather-reg-26">{{ this.lodging.name }}</h1>
        <div class="standing mb-3">
          <img
            src="../../images/Etoiles.svg"
            alt="Etoile remplie"
            v-for="i in parseInt(lodging.standing)"
            :key="i"
          />
        </div>

        <p class="raleway-reg-p13">
          Cet établissement propose {{ lodging.number_of_room }} chambres
        </p>

        <div class="df-jcc-aic reservation-sorter" @click="sortByPrice" v-if="!isPhone">
          <p class="raleway-reg-p13 mb-0 mr-3">PRIX</p>
          <img src="../../images/fleches dropdown.svg" alt="fleches" />
        </div>

        <div v-if="!lodging.is_entire" class="raleway-reg-p13 message-container">
          <p v-if="!roomsMount[0].koedia_room_name" class="text-center text-muted bottom-static-text">
          Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
        </div>
        <div v-else-if="lodging.is_entire" class="raleway-reg-p13 message-container">
          <div v-if="!lodging.lodging_channel_manager">
            <p v-if="!roomsMount[0].koedia_room_name" class="text-center text-muted bottom-static-text">
            Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
          </div>
        </div>
        
        <div class="card-evergreen" v-if="!isPhone">
          <div class="head">
            <img
              src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/planete2.png"
              alt="planete"
            />
            <div class="content">
              <p class="raleway-bold-p mb-0">Programme evergreen</p>
            </div>
            <div>
              <button class="modal-arrow" @click="showModalEvergreen = !showModalEvergreen">
                <img class="arrow" src="../../images/Info icone.svg" alt="fermer" />
              </button>
            </div>
          </div>
        </div>
        <div class="made-trip" v-if="!isPhone">
            <img
              src="../../images/bag.svg"
              alt="bag"
            />
            <div class="content">
              <p class="raleway-bold-p mb-0">Envie d'organiser un voyage sur mesure ?</p>
              <p class="raleway-reg-p13 mb-0">Prenez contact avec nous <a class="link-hortense" :href="'/voyage-sur-mesure/'">ici</a></p>
            </div>
        </div>
        <br/>
      </div>
      <Searchbtn
        class="search-resa"
        @show="showBanner = !showBanner"
        @top="redirectToTop"
      ></Searchbtn>
      <div v-if="!isPhone" class="row">
        <div class="left-black-bar col-md-3">
          <h2>POURQUOI NE PAS DEVENIR MEMBRE ?</h2>
          <p>
            Avec le programme de fidélité Hortensia, accédez à des services exclusifs
            pendant votre séjour. Avec Hortense, transformez votre nuit d'hôtel en une
            expérience unique et voyagez en tant qu'invité privilégié.
          </p>
          <ul class="custom-tick-list" v-if="this.lodging.green_trotters && this.lodging.green_trotters.length">
            <template v-for="gift in this.lodging.green_trotters[3].gifts">
              <li>{{ gift.name }}</li>
            </template>
          </ul>
          <button class="btn-member" @click="showModal = true;">DEVENIR MEMBRE</button>
          <p class="already-member">
            Déjà membre ? <a href="javascript:void(0)" @click.prevent="openLoginModal">Connectez-vous</a>
          </p>
          <p class="note">
            Les services exclusifs auxquels vous pouvez accéder varient en fonction de
            vos points de fidélité et de votre statut au sein de notre programme de
            fidélité. Cette information est disponible dans votre espace client.
          </p>
        </div>

        <div class="container-profil col-md-9" v-if="roomsMount">
          <template v-if="roomsMount">
            <ReservationCard
              v-for="room in roomsMount"
              :minNights="minNights"
              :lodging="lodging"
              :searched="searched"
              :isSearching="isSearching"
              :item="room"
              :key="room.id"
              :from="from"
              :to="to"
              :roomAvailability="roomAvailability(room.koedia_room_name)"
              :roomAvailabilityNotKoedia="roomAvailabilityNotKoedia(room.id)"
              :tariffsNotKoedia="tariffsNotKoedia(room.id)"
              :accomodationCode="accommodation_code"
              :numberOfRoom="numberOfRoom"
              :taxNotKoedia="tax"
              @sendInfo="displayUserInfo"
              :koediaSessionId="koediaSessionId"
              @expandCalendar="expandCalendar = true"
              :nights="nights"
              :koediaFrom="koediaFrom"
              :koediaTo="koediaTo"
            ></ReservationCard>
          </template>
        </div>
      </div>
      <div v-if="isPhone && roomsMount" class="container-profil col-md-9" style="margin-right: 1.5%;">
          <template v-if="roomsMount">
            <ReservationCard
              v-for="room in roomsMount"
              :minNights="minNights"
              :lodging="lodging"
              :searched="searched"
              :isSearching="isSearching"
              :item="room"
              :key="room.id"
              :from="from"
              :to="to"
              :roomAvailability="roomAvailability(room.koedia_room_name)"
              :roomAvailabilityNotKoedia="roomAvailabilityNotKoedia(room.id)"
              :tariffsNotKoedia="tariffsNotKoedia(room.id)"
              :accomodationCode="accommodation_code"
              :numberOfRoom="numberOfRoom"
              :taxNotKoedia="tax"
              @sendInfo="displayUserInfo"
              :koediaSessionId="koediaSessionId"
              @expandCalendar="expandCalendar = true"
              :nights="nights"
              :koediaFrom="koediaFrom"
              :koediaTo="koediaTo"
            ></ReservationCard>
          </template>
        </div>
    </template>
    <ReservationNewUserInfo
      v-else
      :exclusive="exclusive"
      :item="roomChoosed"
      :selectedOption="selectedOption"
      :koediaRoomId="koediaRoomId(roomChoosed.koedia_room_name)"
      :koediaSessionId="koediaSessionId"
      :koediaFrom="koediaFrom"
      :koediaTo="koediaTo"
      :user="user"
      :lodging="lodging"
      :from="from"
      :to="to"
      :tax="tax"
      :total="total"
      :adults="adults"
      :childs="childs"
      :childrenAges="childDate"
      :numberOfNights="nights"
      :pricePerNightHt="pricePerNightHt"
      :totalHt="totalHt"
      :tva="tva"
      :totalTtc="totalTtc"
      :accomodationCode="accommodation_code"
      :selectedPriceOptionFee="selectedPriceOptionFee"
      :totalTaxNotKoedia="totalTaxNotKoedia"
      :totalHtNotKoedia="totalHtNotKoedia"
      :totalTvaNotKoedia="totalTvaNotKoedia"
      :totalTtcNotKoedia="totalTtcNotKoedia"
      @closed="handleClose"
    ></ReservationNewUserInfo>

    <div v-if="isPhone">
      <div class="card-evergreen">
        <div class="head">
          <img
            src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/planete2.png"
            alt="planete"
          />
          <div class="content">
            <p class="raleway-bold-p mb-0">Programme evergreen</p>
          </div>
          <div>
            <button class="modal-arrow" @click="showModalEvergreen = !showModalEvergreen">
              <img class="arrow" src="../../images/Info icone.svg" alt="fermer" />
            </button>
          </div>
        </div>
      </div>
      <div class="made-trip">
          <img
            src="../../images/bag.svg"
            alt="bag"
          />
          <div class="content">
            <p class="raleway-bold-p mb-0">Envie d'organiser un voyage sur mesure ?</p>
            <p class="raleway-reg-p13 mb-0">Prenez contact avec nous <a class="link-hortense" :href="'/voyage-sur-mesure/'">ici</a></p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Searchbanner from "../../components/btns/Searchbanner";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import ReservationCard from "../../components/cards/ReservationCard";
import ReservationNewUserInfo from "../reservations/ReservationNewUserInfo";
import Modalsignup from "components/modals/Modalsignup.vue";
import ModalEvergreen from "./ModalEvergreen.vue";
import fetcher from "packs/shared/api.js";

export default {
  name: "NewReservation",
  components: {
    Searchbanner,
    Searchbtn,
    ReservationCard,
    ReservationNewUserInfo,
    Modalsignup,
    ModalEvergreen,
  },
  props: {
    lodging: Object,
    accommodation_code: { type: Number, required: false, default: null },
    rooms: Array,
    tax: Object,
    user: Object,
    exclusive: Object
  },
  data() {
    return {
      showRooms: true,
      minNights: Number,
      totalTaxNotKoedia: String,
      totalHtNotKoedia: String,
      totalTvaNotKoedia: String,
      totalTtcNotKoedia: String,
      isSearching: false,
      searched: false,
      showBanner: true,
      showModal: false,
      showModalLogin: false,
      showModalEvergreen: false,
      itemSelected: null,
      adults: null,
      childs: null,
      childDate: [],
      roomsMount: this.rooms,
      numberOfRoom: 0,
      selectedOption: null,
      isChoosing: true,
      roomChoosed: null,
      houseHold: null,
      availabilities: null,
      availabilitiesNotKoedia: [],
      koediaSessionId: null,
      koediaFrom: null,
      koediaTo: null,
      from: null,
      to: null,
      total: 0,
      totalTtc: null,
      pricePerNightHt: null,
      expandCalendar: false,
      taxNotKoedia: null,
      selectedPriceOptionFee: null,
      pricingFeeHash: {
        pn: 'breakfast_notinclude_pricing_fee',
        pd: 'breakfast_include_pricing_fee',
        cf: 'cancellation_free_pricing_fee',
        nr: 'no_refundable_pricing_fee',
        ai: 'all_inclusive_pricing_fee',
        hp: 'half_pension_pricing_feee',
        fp: 'full_pension_pricing_fee',
        cc: 'cancel_condition_pricing_fee'
      },
    };
  },
  computed: {
    totalHt() {
      return (parseFloat(this.totalTtc) / (1 + parseFloat((this.tax ? this.tax.tva_rate : 0) ) / 100)).toFixed(2);
    },
    tva() {
      return (parseFloat(this.totalTtc) - parseFloat(this.totalHt)).toFixed(2);
    },
    guests() {
      return this.adults + this.childs;
    },
    nights() {
      return Math.ceil((new Date(this.to) - new Date(this.from)) / (1000 * 60 * 60 * 24));
    },
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
  },
  methods: {
    openLoginModal() {
    this.showModalLogin = true;
    this.showModal = true;
  },
    async ReqInventoryBook() {
      try {
        const res = await fetcher.post(
          `/lodgings/${this.lodging.slug}/request_inventory_booking`,
          {
            start: this.koediaFrom/*this.from.toString().substring(0,10)*/,
            end: this.koediaTo/*this.to.toString().substring(0,10)*/,
          }
        );
        if(res.data){
           this.minNights = res.data.booking_request;
           console.log('minNights');
           console.log(this.minNights);
        }
      } catch (e) {
        this.isSearching = false;
        console.error(e);
      }
    },
    updateExpandCalendar() {
      this.expandCalendar = false;
    },
    roomAvailability(roomName) {
      if (this.availabilities) {
        return this.availabilities[roomName];
      }
    },
    koediaRoomId(roomName) {
      if (this.availabilities) {
        return this.availabilities[roomName].room_id;
      }
    },
    async checkIfAvailable() {
  this.searched = false;
  this.isSearching = true;
  console.log('this.accommodation_code', this.accommodation_code);

  if (!this.accommodation_code) {
    console.log('nonnnnnnnnn');
    this.availabilitiesNotKoedia = {};
    
    for (let roomData of this.roomsMount) {
      try {
        const response = await fetcher.post('/rooms/' + roomData.id + '/availabilities');
        console.log('response.data, ', response.data);
        console.log('new Date(this.from), ', new Date(this.from));
        console.log('new Date(this.to), ', new Date(this.to));
        console.log('new Date("2024-12-20"), ', new Date("2024-12-20"));

        let filtered_data = response.data.filter((item) => 
          item.tariff && 
          item.tariff_price && 
          item.date &&
          new Date(item.date) >= new Date(this.from) &&
          new Date(item.date) <= new Date(this.to)
        );

        this.availabilitiesNotKoedia[roomData.id] = filtered_data.filter((item) => item.tariff_price !== null);


        console.log('ROOM ', this.availabilitiesNotKoedia);
        console.log('filtered_data ', filtered_data);
        console.log('filtered_data ', filtered_data.filter((item) => item.tariff_price !== null));
        console.log('ROOM ' + roomData.id);
        console.log(this.roomAvailabilityNotKoedia(roomData.id));
      } catch (error) {
        console.error('Error fetching availabilities for room:', roomData.id, error);
      }
    }

    console.log('this.availabilitiesNotKoedia', this.availabilitiesNotKoedia);
    this.searched = true;
    this.isSearching = false;
  }
      else
      {
        this.ReqInventoryBook();
        this.getAvailability()
          .then((response) => {
            this.searched = true;
            this.isSearching = false;
            this.koediaSessionId = response.data.koedia_session_id;
            this.availabilities = {};
            console.log('response.data.availabilities', response.data.availabilities)
            for (const roomData of response.data.availabilities) {
              console.log( "hello", roomData)
              this.availabilities[roomData.room_name] = roomData;
            }
          })
          .catch((error) => {
            this.isSearching = false;
            console.log(error);
          });
      }

      console.log("taxTotal");
      console.log(this.taxTotal);
    },
    updateDates(dates) {
      console.log('dates[0]');
      console.log(dates[0]);

      console.log('dates[1]');
      console.log(dates[1]);
      this.koediaFrom = this.formatDate(dates[0]);
      this.koediaTo = this.formatDate(dates[1]);
      this.from = this.koediaFrom;//new Date(this.formatDateNotKoedia(dates[0]));
      this.to = this.koediaTo;//new Date(this.formatDateNotKoedia(dates[1]));

      console.log('from');
      console.log(this.from);

      console.log('to');
      console.log(this.to);
    },
    formatDateNotKoedia(date){
      const dateBits = date.split("/");
      if(navigator.languages[0].includes("en") || navigator.language.includes("en")){
        return `${dateBits[1]}/${dateBits[0]}/${dateBits[2]}`;
      
      }
      return date;
    },
    formatDate(date) {
      if (date.includes('-')) {
          return date;
        }
        const dateBits = date.split('/');
        return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`; 
    },
    /*formatDate(date) {
      const dateBits = date.split("/");
      if(navigator.languages[0].includes("en") || navigator.language.includes("en")){
        let month = dateBits[0];
        let day = dateBits[1];
        if(dateBits[0].length === 1){
          month = "0" + dateBits[0];
        }
        if(dateBits[1].length === 1){
          day = "0" + dateBits[1];
        }
        return `${dateBits[2]}-${month}-${day}`;
      }
      else{
        return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`; 
      }
    },*/
    getAvailability() {
      return fetcher.post(`/lodgings/${this.lodging.slug}/get_availability`, {
        accommodation_code: this.accommodation_code,
        start: this.formatDate(this.koediaFrom),
        //start: dates[0],
        end: this.formatDate(this.koediaTo),
        //end: dates[1],
        adults: this.adults || this.adults != 0 ? this.adults : 2,
        children: this.childs,
        children_ages: this.childDate,
      });
    },
    roomAvailabilityNotKoedia(idx){
      console.log('innnnn')
      console.log('innnnn', this.availabilitiesNotKoedia)

      if(this.availabilitiesNotKoedia){
        console.log('innnnn', idx)

        console.log('innnnn', this.availabilitiesNotKoedia[idx])

        return this.availabilitiesNotKoedia[idx];
      }
    },
    tariffsNotKoedia(idx){
       if(this.availabilitiesNotKoedia[idx]){
        let collection = this.availabilitiesNotKoedia[idx].filter((el) => el.tariff != null && el.tariff != "").map((el) => el.tariff);
        console.log('collectioncollectioncollection', collection)
        let aa=[...new Set(collection)]
        console.log('SetSetSetSet', aa)
        return [...new Set(collection)];
      }
    },
    handleClose() {
      this.isChoosing = true;
      this.saveState();
    },
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    setUser(user) {
      this.user = user;
      this.showModal = false;
    },
    adultsNum(e) {
      this.adults = e;
    },
    childsAge(e) {
      this.childDate = e;
    },
    childsNum(e) {
      this.childs = e;
    },
    async fetchLodging() {
      try {
        const response = await fetcher.get("/lodgings/" + this.lodging.slug);
        this.lodgingMount = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchRooms() {
      try {
        const response = await fetcher.get("/lodgings/" + this.lodging.slug + "/rooms");
        this.roomsMount = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    updateRooms(room) {
      this.numberOfRoom = room;
    },
    updateChilds(childs) {
      this.childDate = childs;
    },
    saveState() {
      const state = {
        exclusive: this.exclusive,
        roomChoosed: this.roomChoosed,
        selectedOption: this.selectedOption,
        koediaRoomId: this.koediaRoomId(this.roomChoosed.koedia_room_name),
        koediaSessionId: this.koediaSessionId,
        koediaFrom: this.koediaFrom,
        koediaTo: this.koediaTo,
        user: this.user,
        lodging: this.lodging,
        from: this.from,
        to: this.to,
        tax: this.tax,
        total: this.total,
        adults: this.adults,
        childs: this.childs,
        childDate: this.childDate,
        nights: this.nights,
        pricePerNightHt: this.pricePerNightHt,
        totalHt: this.totalHt,
        tva: this.tva,
        totalTtc: this.totalTtc,
        accommodation_code: this.accommodation_code,
        selectedPriceOptionFee: this.selectedPriceOptionFee,
        totalTaxNotKoedia: this.totalTaxNotKoedia,
        totalHtNotKoedia: this.totalHtNotKoedia,
        totalTvaNotKoedia: this.totalTvaNotKoedia,
        totalTtcNotKoedia: this.totalTtcNotKoedia,
        isChoosing: false,
        houseHold: this.houseHold
      };
      console.log("state", state)
      localStorage.setItem('reservationState', JSON.stringify(state));
    },
    loadState() {
      const state = JSON.parse(localStorage.getItem('reservationState'));
      if (state) {
        this.koediaFrom = state.koediaFrom;
        this.koediaTo = state.koediaTo;
        this.from = state.from;
        this.to = state.to;
        this.adults = state.adults;
        this.childs = state.childs;
        this.childDate = state.childDate;
      }
    },
    updateChildsNum(childsNum) {
      this.childs = childsNum;
    },
    updateAdults(adults) {
      this.adults = adults;
    },
    // displayUserInfo(room, numberOfRoom, total) {
    //   this.roomChoosed = room;
    //   this.numberOfRoom = numberOfRoom;
    //   this.total = total;
    //   this.isChoosing = false;
    // },
    displayUserInfo(room, pricePerNightTtc, totalTtc, koediaRoomAvailability, tariff, tva, priceHt, houseHOld = null) {
      console.log("ROOM");
      console.log(room);

      console.log("pricePerNightTtc");
      console.log(pricePerNightTtc);

      console.log("totalTtc");
      console.log(totalTtc);

      console.log("koediaRoomAvailability");
      console.log(koediaRoomAvailability);

      this.roomChoosed = room;
      this.selectedOption = koediaRoomAvailability;
      // this.numberOfRoom = numberOfRoom;
      this.totalTtc = totalTtc;
      this.pricePerNightHt = pricePerNightTtc;
      this.isChoosing = false;
      if (Array.isArray(koediaRoomAvailability)) {
        const selectedOption = koediaRoomAvailability.find((el) => el.tariff === tariff);
        if (selectedOption) {
          this.selectedPriceOptionFee = selectedOption.tariff_cancel_feed;
        } else {
          this.selectedPriceOptionFee = null;
        }
      } else {
        this.selectedPriceOptionFee = null;
      }
      // TAX
      /*if (this.childs === 0) {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights * this.adults).toFixed(2);
      } else if (this.adults === 0) {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights * this.childs).toFixed(2);
      } else {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights * (this.adults + this.childs)).toFixed(2);
      }*/
      if (this.tax && this.tax.tourist_tax) {
        this.totalTaxNotKoedia = (this.tax.tourist_tax * this.nights).toFixed(2);
      } else {
        this.totalTaxNotKoedia = 0;
      }
      
      // TOTAL HT
      this.totalHtNotKoedia = (priceHt * this.nights).toFixed(2);

      // TOTAL TVA
      this.totalTvaNotKoedia = (tva * this.nights).toFixed(2);

      // TOTAL TTC
      this.totalTtcNotKoedia = (
      (isNaN(parseFloat(this.totalHtNotKoedia)) ? 0 : parseFloat(this.totalHtNotKoedia)) +
      (isNaN(parseFloat(this.totalTvaNotKoedia)) ? 0 : parseFloat(this.totalTvaNotKoedia)) +
      (isNaN(parseFloat(this.totalTaxNotKoedia)) ? 0 : parseFloat(this.totalTaxNotKoedia))
    ).toFixed(2);
      this.houseHold = houseHOld;
      console.log('this.totalTtcNotKoediathis.totalTtcNotKoedia', this.totalTtcNotKoedia)
      console.log('this.totalTtcNotKoediathis.totalTtcNotKoedia', houseHOld)
      this.saveState();
    },
    // checkIfAvailable(array) {
    //   let start = array[0].split("/");
    //   let day_start = start[0];
    //   let month_start = start[1] - 1;
    //   let year_start = start[2];
    //   let end = array[1].split("/");
    //   let day_end = end[0];
    //   let month_end = parseInt(end[1]) - 1;
    //   let year_end = end[2];
    //   let from = new Date(year_start, month_start, day_start);
    //   let to = new Date(year_end, month_end, day_end);
    //   this.from = from;
    //   this.to = to;
    //   this.nights = Math.ceil((this.to - this.from) / (1000 * 60 * 60 * 24));
    //   // this.roomsMount = this.roomsMount.filter((room) => {
    //   //   console.log(room);
    //   //   return this.roomAvailable(room, from, to);
    //   // });
    // },
    isBeetween(date, from, to) {
      return date >= from && date <= to;
    },
    roomAvailable(room, from, to) {
      let bool = true;
      this.lodging.reservations.every((reservation) => {
        let checkin = new Date(reservation.checkin);
        let check_out = new Date(reservation.check_out);
        if (
          room.code === reservation.room_code &&
          (this.isBeetween(checkin, from, to) || this.isBeetween(check_out, from, to))
        ) {
          bool = false;
        }
      });

      return bool;
    },
    sortByPrice() {},
    async fetchAvailability(id) {
      try {
        const response = await fetcher.get('/rooms/' + id + '/availabilities').then();
        return response.data;
      } catch(e) {
        console.error(e);
      }
    },
    handlePopState(event) {
      if (this.isChoosing) {
        this.handleClose();
        history.pushState(null, null, location.href);
      } else {
        history.back();
      }
    },
  },
  //created(){
  //    for (let roomData of this.rooms) {
  //        let response = fetcher.get('/rooms/' + roomData.id + '/availabilities').
  //        then((response) => {
  //            this.availabilitiesNotKoedia[roomData.id] = response.data;
  //        })
  //    }
  //},
  mounted() {
    // this.fetchLodging();
    // this.fetchRooms();
    this.loadState();
    console.log('ROOMS FOR TEST');
    console.log(this.rooms);
    this.$root.$on("openModalLogin", (_) => {
      this.showModalLogin = true;
      this.showModal = true;
    });

    this.$root.$on("openModalSignup", (_) => {
      this.showModalLogin = false;
      this.showModal = true;
    });

    if (window.location.search === "?signin" && !this.user) {
      this.showModal = true;
    }

    if (window.location.search === "?admin_login" && !this.user) {
      this.showAdminModal = true;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const from = urlParams.get('from');
    const to = urlParams.get('to');

    if (from && to) {
      this.koediaFrom = this.formatDate(from);
      this.koediaTo = this.formatDate(to);
      this.from = this.koediaFrom;
      this.to = this.koediaTo;
      this.adults = 2;
      this.childs = 0;

      // Call checkIfAvailable function after setting the dates
      this.checkIfAvailable();
    }
    if (this.koediaFrom && this.koediaTo) {
      this.checkIfAvailable();
    }
    window.addEventListener('popstate', this.handlePopState);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChoosing) {
      this.handleClose();
      next(false);
    } else {
      next();
    }
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  },
};
</script>

<style lang="scss" scoped>
.made-trip {
  text-align: center;
  border-color: #cddfd3;
	border-style: solid;
	border-width: 1px;
  border-top-right-radius: 20px 20px;
	border-bottom-right-radius: 20px 20px;
	border-bottom-left-radius: 20px 20px;
  height: fit-content;
  width: 200px;
  margin-top: 190px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  left: 7.5%;

  @media (max-width: 480px) {
    left: inherit;
    margin: 1em 0;
    position: relative;
  }
  
  .head {
    display: flex;
    
    img {
      width: 32px;
      height: 32px;
    }
    
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
}

#new-reservation {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lodging-info {
    margin-top: 154px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .search-resa {
    align-self: flex-start;
    margin-left: 3rem;
    z-index: 1;
  }
}

.card-evergreen {
  height: fit-content;
  width: 200px;
  margin-top: 100px;
  background-color: #f7f7f7;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  left: 7.5%;

  @media (max-width: 480px) {
    left: inherit;
    margin-top: 1em;
    position: relative;
  }
  .head {
    display: flex;
    img {
      width: 64px;
      height: 64px;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
  .cards {
    .previous {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slither-slider-slide {
      position: relative;
      height: auto;
      display: grid;
      grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
      gap: 30px;
    }

    .slither-slider-controls {
      display: flex;
      justify-content: center;
      padding-top: 2rem;
      z-index: 9999;
      position: relative;

      img {
        width: 10px;
        height: 10px;
        margin: 9px;
      }
    }

    .slither-slider-slides {
      position: relative;
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: top;
      transform-origin: top;
    }
    .slider-direction,
    .slither-slider-controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .slider-direction {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border: 1px solid #9da6b4;
      background-color: transparent;
      border-radius: 9999px;
      height: 50px;
      width: 50px;
      font-size: 1.2em;
      opacity: 0.5;
      margin: 4px;
      -webkit-transition: opacity 0.25s;
      transition: opacity 0.25s;
      cursor: pointer;
    }
    .slider-direction:hover {
      opacity: 1;
    }
    .slider-direction:focus {
      outline: none;
    }
    .limit-dot-width[data-v-686e2b33] {
      width: 50px;
      overflow: hidden;
    }
    .slither-slider-dots[data-v-686e2b33] {
      position: absolute;
      right: 0;
      bottom: -1.8em;
      left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0;
      list-style: none;
      padding: 0;
    }
    .slither-slider-dot[data-v-686e2b33] {
      width: 88px;
      height: 88px;
      font-size: 0.1em;
      background-color: $light-green;
      color: #9b9b9b;
      border-radius: 50px;
      overflow: hidden;
      // margin-right:.75rem;
      -webkit-transition: all 1s;
      transition: all 1s;
      cursor: pointer;
    }
    .slither-slider-dot.active-slide[data-v-686e2b33] {
      background-color: #4a4a4a;
      color: #4a4a4a;
      display: none;
    }
    .slither-slider-dot .large-dot[data-v-686e2b33] {
      -webkit-transform: scale(1.7);
      transform: scale(1.7);
    }
    .slither-slider-dot.medium-dot[data-v-686e2b33] {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    .slither-slider-dot.small-dot[data-v-686e2b33] {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    .slither-slider-dot .hidden-dot[data-v-686e2b33] {
      display: none;
    }
    .slither-slider {
      position: relative;
    }

    #cardEvergreen {
      display: flex;
      .card-ever {
        padding-right: 15px;

        img {
          width: 303px;
          height: 353px;
          object-fit: cover;
        }
        .sticker-program {
          background-color: #fff;
          border-top-right-radius: 60px;
          border-bottom-right-radius: 60px;
          padding-top: 5px;
          padding-bottom: 5px;
          align-items: center;
          position: absolute;
          top: 200px;
        }

        .sticker-program-info {
          flex-direction: column;
          width: 282px;
        }
      }
    }
  }
}
.reservation-sorter {
  border: 1px solid black;
  border-radius: 26px;
  padding: 10px 16px;
}

.left-black-bar {
  background-color: black;
  color: white;
  padding: 2rem;
  height: 60%;
  font-family: Arial, sans-serif;
  margin-bottom: 2rem;
  left: 2%;
  margin-top: 2%;
}

.left-black-bar h2 {
  font: normal normal bold 20px/24px Raleway;
  color: #FFFFFF;

}

.left-black-bar p {
  font: normal normal normal 14px/26px Raleway;
  letter-spacing: -0.08px;
  color: #FFFFFF;

}

.left-black-bar ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  font: normal normal bold 14px/16px Raleway;
  letter-spacing: -0.16px;
  color: #FFFFFF;
  }

.left-black-bar ul li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font: normal normal bold 14px/16px Raleway;
  letter-spacing: -0.16px;
  color: #FFFFFF;
}

.btn-member {
  display: block;
  width: 100%;
  border: 1px solid #FFFFFF;
  padding: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;
  color: #FFFFFF;
}

.already-member {
  font: normal normal normal 14px/26px Raleway;
letter-spacing: -0.08px;
color: #FFFFFF;
}

.already-member a {
  color: white;
  text-decoration: underline;
}

.already-member a:hover {
  text-decoration: none;
}

.note {
  font: normal normal normal 14px/26px Raleway;
  letter-spacing: -0.08px;
  color: #FFFFFF;
  line-height: 1.4;
  margin-top: 1rem;
}

#modal-login .modal-mask {
  top: 20% !important;
}

#modal-signup .modal-mask {
  top: 20% !important;
}
.custom-tick-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-tick-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Space between items */
  font-size: 1rem;
  color: white;
}

.custom-tick-list li::before {
  content: "✔";
  font-size: 0.8rem;
  color: #3b6152;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
  opacity: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


</style>