<template>
	<div id="filter-lodging">
		<h3 class="raleway-bold-p">Appliquer des filtres</h3>
    <roomspace :options="options" @changedAdults="changedAdults" @changedChilds="changedChilds"></roomspace>
		<multiselectnote @checked="onCheck"></multiselectnote>
		<multiselectbudget :options="options" @changedBudget="changedBudget"></multiselectbudget>
		<multiselectstanding :options="options" @checked="onCheck"></multiselectstanding>
		<multiselectlodgingtype :options="options" @checked="onCheck"></multiselectlodgingtype>
		<multiselectlodgingstyle :stylesMount="stylesMount" :options="options" @checked="onCheck"></multiselectlodgingstyle>
		<multiselectservices :servicesMount="servicesMount" :options="options" @checked="onCheck"></multiselectservices>
    <multiselectexperience :options="options" @checked="onCheck"></multiselectexperience>
		<multiselectdesires :desiresMount="desiresMount" :options="options" @checked="onCheck"></multiselectdesires>
		<!--<multiselectbedding :options="options" @checked="onCheck"></multiselectbedding>-->
		<!--<multiselectmeals :options="options" @checked="onCheck"></multiselectmeals>-->
		<SwitchAnimal @checked="switched"></SwitchAnimal>
		<SwitchTypeOfTrip @checked="switched"></SwitchTypeOfTrip>
    <SwitchKids @checked="onCheck"></SwitchKids>
    <SwitchStation @checked="onCheck"></SwitchStation>
<!--		<SwitchFreeCancel @checked="switched"></SwitchFreeCancel>-->
	</div>
</template>

<script>
  import RoomSpace from "pages/destinations/RoomSpace.vue";
	import Multiselectnote from 'pages/destinations/Multiselectnote.vue';
	import Multiselectbudget from 'pages/destinations/Multiselectbudget.vue';
	import Multiselectstanding from 'pages/destinations/Multiselectstanding.vue';
	import Multiselectlodgingtype from 'pages/destinations/Multiselectlodgingtype.vue';
	import Multiselectlodgingstyle from 'pages/destinations/Multiselectlodgingstyle.vue';
	import Multiselectlocations from 'pages/destinations/Multiselectlocations.vue';
	import Multiselectservices from 'pages/destinations/Multiselectservices.vue';
	import Multiselecthobbies from 'pages/destinations/Multiselecthobbies.vue';
	import Multiselectdesires from 'pages/destinations/Multiselectdesires.vue';
	import Multiselectbedding from 'pages/destinations/Multiselectbedding.vue';
	import Multiselectmeals from 'pages/destinations/Multiselectmeals.vue';
	import Multiselectacces from 'pages/destinations/Multiselectacces.vue';
	import SwitchTypeOfTrip from 'pages/destinations/SwitchTypeOfTrip.vue';
	import SwitchFreeCancel from 'pages/destinations/SwitchFreeCancel.vue';
	import SwitchAnimal from 'pages/destinations/SwitchAnimal.vue';
  import Roomspace from "./RoomSpace.vue";
  import SwitchKids from "./SwitchKids.vue";
  import SwitchStation from "./SwitchStation.vue";
  import Multiselectexperience from "./Multiselectexperience.vue";

	export default {
		name: 'filter-lodging',
		props: ['stylesMount', 'servicesMount', 'desiresMount'],
		components: {
      Multiselectexperience,
      SwitchStation,
      SwitchKids,
      Roomspace,
			Multiselectnote,
			Multiselectbudget,
			Multiselectstanding,
			Multiselectlodgingtype,
			Multiselectlodgingstyle,
			Multiselectlocations,
			Multiselectservices,
			Multiselecthobbies,
			Multiselectdesires,
			Multiselectbedding,
			Multiselectmeals,
			Multiselectacces,
			SwitchTypeOfTrip,
			SwitchFreeCancel,
			SwitchAnimal,
		},
		data() {
			return {
				changePet: false,
				changeBusiness: false,
				changeFreeCancel: false,
				changeSmocking: false,
				selectedTrip: [],
				options: [
					{
						text: 'Uno',
						value: 1,
					},
				],
			};
		},
		methods: {
			onCheck(val, name) {
				this.$emit('checked', val, name);
			},
			switched(val, name) {
				this.$emit('switch', val, name);
			},
			switchOffPet() {
				this.changePet = !this.changePet;
			},
			changedBudget(value) {
				this.$emit('changedBudget', value, 'budget');
			},
      changedAdults(value) {
        this.$emit('changedAdults', value, 'adults');
      },
      changedChilds(value) {
        this.$emit('changedChilds', value, 'childs');
      },
			// switchOffBusiness() {
			//   this.changeBusiness = !this.changeBusiness
			//   const val = "voyage d'affaires"
			//   if (this.changeBusiness) {
			//     this.$emit('switched', val , 'type_of_trips')
			//   }
			// },
			switchOffFreeCancel() {
				this.changeFreeCancel = !this.changeFreeCancel;
			},
			switchOffSmocking() {
				this.changeSmocking = !this.changeSmocking;
			},
		},
	};
</script>
<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter">
</style>
